
import React from 'react';
import {onSnapshot, types} from "mobx-state-tree";

// sub models in root store
import {UserProfile} from "models/profile/userprofile";
import {Conversation} from "models/chat/conversation";
import {NutrientOrg} from "models/intake/nutrientOrg";

// Create context for accessing store through a provider
export const GlobalStoreContext = React.createContext(null)

// default variable services
export const RootStoreName = 'rootStoreSimpa1'


// Root global store for access to all data
export const RootStore = types
    .model({
        tmp: types.optional(types.boolean, false),
        profile: UserProfile,
        lastLoginNumber: types.optional(types.string, ''),
        mobileScreenSize: types.map(types.number, {height: null, width: null}),
        conversation: Conversation,
        nutOrg: NutrientOrg,
    })
    .actions(self => ({
        doNothing(nothing) {},
        setLastLoginNum(num) {
            self.lastLoginNumber = num
        },
        initScreenSize(height, width) {
            self.mobileScreenSize = {height: height, width: width}
        },
        updateScreenSize(height, width) {
            if (!self.mobileScreenSize.height || self.mobileScreenSize.width > width) {
                self.initScreenSize(height, width)
            }
        },
        loadConversation() {
            self.conversation.loadMessages()
        },
        loadNutOrg() {
            self.nutOrg.load()
        },

    }))
    .views(self => ({
        // todo could also add a generic 'isConnected' to see if there is an internet connection and only load then
        getNothing(nothing) {},
        getConversation() {
            if (!self.conversation.numMessages || self.conversation.isExpired(20)) {
                self.loadConversation()
            }
            return self.conversation
        },
        getNutrientOrg() {
            self.loadNutOrg()
            return self.nutOrg
        },
    }));


// Set up initial state and storing to local storage
let initialState = {
    profile: UserProfile.create({
        'restrictions': {}
    }),
    conversation: Conversation.create({}),
    nutOrg: NutrientOrg.create({type: 'group'}),
};

if (localStorage.getItem(RootStoreName)) {
    // Load temporary copy to check if shape has changed
    const json = JSON.parse(localStorage.getItem(RootStoreName))
    if (RootStore.is(json)) {
        // todo Could perform checks here to load expired or modified stores
        initialState = json
    }
    else {console.log('root store not loading')}
}

export let globalStore = RootStore.create(initialState);


// store a local copy on every snapshot change
onSnapshot(globalStore, snapshot => {
    localStorage.setItem(RootStoreName, JSON.stringify(snapshot))
});
