import React, {useState} from "react";
import {navigate} from "@reach/router";

import {Wpa, Hpa} from "utils/util";
import {SModal} from "components/library/basic/SModal";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Menu} from "grommet-icons";


function Link(props) {

    const text = props.text
    const close = props.close
    const children = props.children
    const level = props.level ? props.level : 0
    const nav = props.nav
    var width = props.width
    const [open, setOpen] = useState(props.open)

    var height = Hpa(40)
    if (window.innerWidth > window.innerHeight) {
        height = Hpa(60)
    }

    var font = 'h3r'
    if (level > 0) {
        font = 'h4l'
    }

    function onClick(e) {
        if (nav) {navigate(nav)}
        else if (children) {
            setOpen(!open)
        }
        if (close) {close()}
    }

    return (
        <SDiv>
            <SDiv
                direction={'row'} width={width-level*0.2*width} height={height}
                halign={'start'} onClick={(e) => onClick(e)}
                //borderColor={'b500'} borderStyle={'none none solid none'} boderWidth={1}
            >
                <SDiv width={Wpa(10)}/>
                <SDiv width={Wpa(24)} halign={'start'}/>
                <SDiv font={font} textColor={'g600'} halign={'start'}>
                    {text}
                </SDiv>
            </SDiv>
            {(children && open) &&
            <SDiv direction={'row'} width={width}>
                <SDiv width={level*0.2*width}/>
                <SDiv>
                    {children}
                </SDiv>
            </SDiv>
            }
        </SDiv>
    )

}


function OpenMenu(props) {

    const closeModal = props.close
    const height = Hpa(0)
    var width = Wpa(0.5)
    if (window.innerWidth > window.innerHeight) {
        width = Wpa(0.2)
    }

    const abouts = [
        <Link key={0} text={'Simpa'} width={width} level={1} nav={'/about/simpa'}/>,
        <Link key={1} text={'YOUR data'} width={width} level={1} nav={'/about/yourdata'}/>,
        //<Link key={1} text={'Covid-19'} width={width} level={1} nav={'/about/covid'}/>,
        <Link key={2} text={'#BLM'} width={width} level={1} nav={'/about/blm'}/>,
    ]

    return (
        <SModal
            modalWidth={width} modalHeight={height} halign={'start'}
            onClick={closeModal} onClose={closeModal}
            transition={'slideRight'}
        >
            <Link text={'Home'} width={width} nav={'/'}/>
            <Link text={'About'} width={width} open={true}>
                {abouts}
            </Link>
        </SModal>
    )
}

export function LandingMenu(props) {

    const [open, setOpen] = useState(false)

    function close() {setOpen(false)}

    return (
        <SDiv>
            <Menu onClick={()=> setOpen(true)}/>
            {open && <OpenMenu open={open} close={close}/>}
        </SDiv>
    )
}