import React, {useState, useEffect} from "react";
import {SDiv} from "components/library/styledhtml";
import {ListMeasures} from "models/labs/listMeasures";
import {SummaryMeasures} from "models/labs/summaryMeasures";
import {observer} from "mobx-react-lite";
import {LabBox} from "components/pages/mydata/labs/labBox";


export const SummaryLabsDisplay = observer((props) => {
    // use as an observer for the sumMeasure

    // SummaryMeasures model
    const summaryMeasure = props.summaryMeasure
    const boxes = summaryMeasure.summaryLabs.map((x, i) =>
            <LabBox key={i} labPoint={x}/>
            )
    return (
        <SDiv>
            {boxes}
        </SDiv>
    )
})


export function LabDataSection(props) {

    //const listMeas = ListMeasures.create()
    const [summaryMeasure, setSummaryMeasure] = useState(SummaryMeasures.create())

    useEffect(() => {
        //listMeas.load()
        summaryMeasure.load()
    }, [])

    return (
        <SDiv halign={'start'}>
            <SummaryLabsDisplay summaryMeasure={summaryMeasure}/>
        </SDiv>
    )
}