
import React, {useState} from "react";
import {Router, navigate} from "@reach/router";
import {SDiv, SButton} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";

import {IntakeQuestTest} from "components/pages/development/intakeQuestTest";

import {HandSize} from "components/pages/personalization/HandSize";
import {HandShade} from "components/pages/personalization/HandShade";
import {GenderSelect} from "components/pages/personalization/GenderSelect";
import {AvatarGen} from "components/pages/profile/AvatarGen";
import {ChatBotButton} from "components/library/common/chat/chatbot";
import {BuildIntake} from "components/library/intake/createIntake/BuildIntake";
import {SearchCheck} from "components/pages/development/searchCheck";

import {MainHeader} from "components/library/nav/MainHeader";


function Space(props) {
    const sz = props.sz ? props.sz : 20
    return(
        <SDiv height={Hp(sz)} width={Wp(0)}/>
    )
}


function ButtonPage(props) {

    return (
        <SDiv width={Wp(0)} valign={'start'}>
            <MainHeader showBack={true} showProfile={true}/>

            <SDiv font={'h3'} width={Wp(0)} height={Hp(40)}
                  textAlign={'center'}
            >
                Testing
            </SDiv>
            <SDiv boxShadow={'normal'} borderRadius='10px' width={Wp(0.9)}>
                <Space/>
                <SDiv>Intake</SDiv>
                <Space/>
                {/*
                <SButton width={Wp(0.7)} onClick={() => props.navigate(`handshade`)}>Personalization</SButton>
                <Space/>
                */}
                <BuildIntake/>
                <Space/>
                <SButton width={Wp(0.7)} onClick={() => props.navigate(`intakequest`)}>2 Week Intake (v1)</SButton>
                <Space/>
            </SDiv>
            <SDiv boxShadow={'normal'} borderRadius='10px' width={Wp(0.9)}>
                <Space/>
                <SDiv>Profile</SDiv>
                <Space/>
                <SButton width={Wp(0.7)} onClick={() => props.navigate(`avatargen`)}>Avatar Gen</SButton>
                <Space/>
            </SDiv>
            <SDiv boxShadow={'normal'} borderRadius='10px' width={Wp(0.9)}>
                <ChatBotButton/>
            </SDiv>
            <Space sz={30}/>
            <SDiv boxShadow={'normal'} borderRadius='10px' width={Wp(0.9)}>
                <SearchCheck/>
            </SDiv>
            {/*
            <SDiv boxShadow={'normal'} borderRadius='10px' width={Wp(0.9)}>
                <Space/>
                <SDiv>Components</SDiv>
                <Space/>
                <SButton width={Wp(0.7)} onClick={() => navigate(`${APP}/${TEST}/keyboard`)}>Keyboard vs Native</SButton>
                <Space/>
            </SDiv>
            */}
        </SDiv>
    )
}


export function Development(props) {

    const [height, setHeight] = useState(Hp(0))
    const [width, setWidth] = useState(Wp(0))

    return (
        <Router>
            <ButtonPage path={'/*'}/>
            <IntakeQuestTest path={`/intakequest`}/>
            <HandShade path='/handshade'/>
            <HandSize path='/handsize'/>
            <GenderSelect path={'/genderselect'}/>
            <AvatarGen path={'/avatargen'}/>
        </Router>
    )
}