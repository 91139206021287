import React from "react";
import {animated} from "react-spring";
import styled from "styled-components";

import {stheme, getColor} from "themes/theme";
import {getAlignment, getDim} from "components/library/utility/utilityFunctions";
import {hexToRgbA} from "utils/util";


function getFixedOffset(value) {
    if (value === 0 || value === 1) return value
    return value ? getDim(value) : null
}


const StyleDiv = styled.div(props => ({

    // sizing
    height: props.height ? getDim(props.height) : null,
    width: props.width ? getDim(props.width) : null,

    // background and shadowing
    background: props.color,
    opacity: props.opacity,
    'background-image': props.backgroundImage ? `url(${props.backgroundImage})` : null,
    'background-size': props.backgroundSize ? props.backgroundSize : 'cover',
    'background-repeat': props.backgroundRepeat ? props.backgroundRepeat : 'no-repeat',
    'background-position': props.backgroundPosition,
    'padding-bottom': props.paddingBottom,
    'padding-top': props.paddingTop,
    'padding-left': props.paddingLeft,
    'padding-right': props.paddingRight,
    'z-index': props.zIndex ?? 1,
    'box-shadow': props.boxShadow ? props.boxShadow : null,

    tabIndex: props.tabIndex ? props.tabIndex : null,
    'outline': 'none',  // to remove outline on tabIndex
    // positionint
    display: props.display ? props.display : 'flex',
    flex: props.flex ? props.flex : null,
    'flex-direction': props.direction ? props.direction : 'column',
    'align-items': props.alignItems ? props.alignItems : 'center',
    'justify-content': props.justifyContent ? props.justifyContent : 'center',

    position: props.position ? props.position : 'static',
    '-webkit-sticky': props.position === 'sticky' ? true : null,
    top: props.top,
    bottom: props.bottom,
    left: props.left,
    right: props.right,
    'margin-bottom': props.marginBottom ? props.marginBottom : null,
    'margin-top': props.marginTop ? props.marginTop : null,
    'margin-left': props.marginLeft ? props.marginLeft : null,
    'margin-right': props.marginRight ? props.marginRight : null,

    // borders
    border: props.border,
    'border-radius': props.borderRadius ? props.borderRadius : null,
    'border-style': props.borderStyle ? props.borderStyle : 'none none none none',
    'border-color': props.borderColor ? props.borderColor : stheme.color.a1,
    'border-width': props.borderWidth ? props.borderWidth : '2px',
    'border-left': props.borderLeft,
    'border-right': props.borderRight,
    'border-top': props.borderTop,
    'border-bottom': props.borderBottom,

    // text and fonts
    color: props.textColor,
    'text-align': props.textAlign,
    'font-size': props.fontSize,
    'font-weight': props.fontWeight,
    'font-family': props.fontFamily,
    'line-height': props.lineHeight,
    'text-overflow': props.textOverflow ? props.textOverflow : 'ellipsis',
    'word-wrap': props.wordWrap ? props.wordWrap : 'break-word',
    'white-space': props.whiteSpace,

    'min-height': props.minHeight,
    'max-height': props.maxHeight,
    'min-width': props.minWidth,
    'max-width': props.maxWidth,
    'flex-shrink': props.flexShrink,
    overflow: props.overflow ? props.overflow : null,
    'overflow-x': props.overflowX,
    'overflow-y': props.overflowY,
    '-webkit-overflow-scrolling' : 'touch',
    //'-webkit-scrollbar' : {'display': 'none'},
    'scrollbar-width': props.scrollBarWidth ? props.scrollBarWidth : 'none',
    'overscroll-behavior-x': props.overscrollBehaviorX,
    'overscroll-behavior-y': props.overscrollBehaviorY,
    'overflow-wrap': props.overflowWrap,

}));


export const SDiv = React.forwardRef((props, ref) => {

    const [direction, alignItems, justifyContent] = getAlignment(props.direction, props.halign, props.valign)

    var font = props.font
    if (!font) {font = stheme.font.b1}
    else if (stheme.font.hasOwnProperty(font)) {font = stheme.font[font]}
    // setup font defaults
    const fontSize = props.fontSize ? props.fontSize : font.size
    const fontFamily = props.fontFamily ? props.fontFamily : font.family
    const lineHeight = props.lineHeight ? props.lineHeight : font.sp
    const fontWeight = props.fontWeight ? props.fontWeight : font.weight

    var color = getColor(props.color, false)
    var txtColor = getColor(props.textColor, false)
    var borderColor = getColor(props.borderColor, false)

    var borderStyle = props.borderStyle
    if ((borderColor && !borderStyle) || borderStyle === 'solid') {
        borderStyle = 'solid solid solid solid'
    }

    if (props.op) {
        if (!txtColor) {txtColor = stheme.color.ogrey}
        //if (txtColor.match('\#')) {txtColor = hexToRgbA(txtColor, props.op)}
        if (txtColor.match('#')) {txtColor = hexToRgbA(txtColor, props.op)}
    }
    else if (!txtColor) {txtColor = stheme.color.greys.d1}

    var position = props.position
    if (props.sticky && !props.position) {
        position = 'sticky'
    }

    var boxShadow = props.boxShadow
    if (boxShadow === 'normal') {boxShadow = '0px 8px 24px rgba(0, 0, 0, 0.12)'}
    else if (props.elevation && !props.boxShadow) {
        boxShadow = props.elevation
    }

    var padLeft = props.paddingLeft ? props.paddingLeft : props.padLeft
    var padRight = props.paddingRight ? props.paddingRight : props.padRight
    var padTop = props.paddingTop ? props.paddingTop : props.padTop
    var padBottom = props.paddingBottom ? props.paddingBottom : props.padBottom

    var whiteSpace = props.whiteSpace
    if (!whiteSpace && props.manualWrap) {whiteSpace = 'pre-wrap'}

    var top = getFixedOffset(props.top)
    var bottom = getFixedOffset(props.bottom)
    var left = getFixedOffset(props.left)
    var right = getFixedOffset(props.right)


    return (
        <StyleDiv
            ref={ref}
            {...props}
            position={position}
            color={color}
            borderColor={borderColor}
            borderStyle={borderStyle}
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}
            boxShadow={boxShadow}
            whiteSpace={whiteSpace}

            paddingLeft={padLeft}
            paddingRight={padRight}
            paddingTop={padTop}
            paddingBottom={padBottom}

            top={top}
            bottom={bottom}
            left={left}
            right={right}

            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight={fontWeight}
            lineHeight={lineHeight}
            textColor={txtColor}
        >
            {props.children}
        </StyleDiv>
    )
})


export const SDivAnim = animated(SDiv)