import React, {useEffect, useState} from "react";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {SButton, SDiv, SInput} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";

import {Trash} from "grommet-icons";

export function LabUploadSection(props) {

    const [selectedFile, setSelectedFile] = useState();
    const [pdfList, setPdfList] = useState([]);
    const [listPdfs, callListPdfs] = useApi('LabsPdfService', 'list_lab_pdf', true, true);
    const [uploadPdf, callUploadPdf] = useApi('LabsPdfService', 'upload_lab_pdf', true, false);
    const [deleteSourceId, callDeleteSourceID] = useApi('LabsPdfService', 'delete_source_id', true, false);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    useEffect(() => {
        callListPdfs({token: rawToken()})
    }, [])

    function deleteid(sourceId) {
        callDeleteSourceID({'token': rawToken(), 'source_id': sourceId})
    }

    useEffect(() => {
        callListPdfs({token: rawToken()})
    }, [deleteSourceId.counter])

    useEffect(() => {
        if (listPdfs.hasData) {
            var tmpList = [];
            for (let j = 0; j < listPdfs.data.name.length; j++) {
                var date = new Date(listPdfs.data.write_time[j] * 1000);
                const sourceId = listPdfs.data.id[j]
                const uploadTime = date.toDateString()//listPdfs.data.write_time[j]

                tmpList.push(
                    <SDiv key={j} topMargin={Hp(4)} bottomMargin={Hp(4)}>
                        <SDiv
                            width={Wp(0.9)} height={Hp(50)}
                            halign={'start'} direction={'row'}
                            boxShadow={'normal'}

                        >
                            <SDiv width={Wp(0.7)}>
                                <SDiv font={'b2'}>{listPdfs.data.name[j]}</SDiv>
                                <SDiv font={'b3'}>uploaded on: {uploadTime}</SDiv>
                            </SDiv>
                            <SDiv width={Wp(0.2)}>
                                <Trash color={'red'} onClick={() => deleteid(sourceId)}/>
                            </SDiv>
                        </SDiv>
                    </SDiv>
                )
            }
            setPdfList(tmpList)//listPdfs.data.name)
        }
    }, [listPdfs.counter])

    useEffect(() => {
        if (uploadPdf.hasData) {
            callListPdfs({token: rawToken()})
            setSelectedFile('')
        }
    }, [uploadPdf.counter])

    function handleSubmit() {
        const reader = new FileReader()

        reader.onload = function (e) {
            const pdfBytes = Buffer(e.target.result)
            const pdfName = selectedFile.name
            callUploadPdf({'token': rawToken(), pdf_bytes: pdfBytes, pdf_name: pdfName})
        };
        reader.readAsArrayBuffer(selectedFile);
    }

    return (
        <SDiv>
            <SDiv
                textColor={'b900'}
            >
                Select LabCorp or Quest Diagnostics pdf
            </SDiv>
            <SDiv height={Hp(10)}/>
            <SDiv>
                <SInput
                    type="file" name="file"
                    accept="application/pdf"
                    onChange={changeHandler}
                />
                <SDiv height={Hp(10)}/>
                {(false && selectedFile) &&
                <SDiv>
                    <SDiv>Filename: {selectedFile.name}</SDiv>
                    <SDiv>Size in bytes: {selectedFile.size}</SDiv>
                </SDiv>
                }
                <SDiv height={Hp(10)}/>
                <SButton
                    height={Hp(30)} width={Wp(80)}
                    disabled={!selectedFile}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </SButton>
                <SDiv>Uploaded pdfs:</SDiv>
                <SDiv>
                    {pdfList}
                </SDiv>
            </SDiv>
        </SDiv>
    )
}