import React, {useEffect, useState} from "react";
import {useAuthentication} from "api/api";
import {rawToken} from "models/security/token";
import {SButton, SDiv, SInput, SSpan} from "components/library/styledhtml";
import {Checkbox, CheckboxSelected, StatusUnknown} from "grommet-icons/es6";
import {useIsMountedRef} from "utils/custom_hooks";
import {Hp, Wp} from "utils/util";
import {SModal} from "components/library/basic/SModal";

function NewPasswords(props) {

    const height = props.height
    const width = props.width
    const title = props.title
    const onSuccess = props.onSuccess

    const [pass1, setPass1] = useState([])
    const [show1, setShow1] = useState('')
    const [pass2, setPass2] = useState([])
    const [show2, setShow2] = useState('')
    const [failMessage, setFailMessage] = useState('')
    const [dispPass, setDispPass] = useState(false)
    const [passEqual, setPassEqual] = useState(false)
    const [validPass, setValidPass] = useState(false)
    const [showHint, setShowHint] = useState(false)
    const [changePassword, callChangePassword] = useAuthentication('change_password', true);

    const textMsg = 'One idea: Create a long sentence and use the first character in each word for the password. Example:'
    const passEx1 = 'My favorite animal is the cheetah since they are fast !'
    const passEx2 = 'Mfaitcstaf!'

    useEffect(() => {

        if (changePassword && changePassword.data) {
            if ((changePassword.data.success !== true) || !changePassword.data.jwt) {
                setFailMessage(`${title} failed`)
            } else if (onSuccess) onSuccess()
        }
    }, [changePassword.counter])

    function changepassword() {
        const params = {'token': rawToken(), 'password': pass1.join('')}
        if (validPass) callChangePassword(params)
    }

    function updatePass1(val) {
        const lastChar = val[val.length - 1]
        if (val.length > pass1.length) {
            pass1.push(lastChar)
        } else {
            pass1.pop()
        }
        if (pass1.length > 8 && pass1.join('') !== passEx2) {
            setValidPass(true)
        } else {
            setValidPass(false)
        }
        if (pass1.join('') === passEx2) {
            setFailMessage('Can not use password example!')
        } else {
            setFailMessage('')
        }

        const s = new Array(pass1.length + 1).join('.')
        setShow1(s)
    }

    function updatePass2(val) {
        const lastChar = val[val.length - 1]
        if (val.length > pass2.length) {
            pass2.push(lastChar)
        } else {
            pass2.pop()
        }
        const s = new Array(pass2.length + 1).join('.')
        setShow2(s)
        const p1 = pass1.join('')
        const p2 = pass2.join('')
        if ((p1 === p2) && validPass) {
            setPassEqual(true)
        } else {
            setPassEqual(false)
        }
    }

    function buildColorEx(text) {

        const spans = []
        const words = text.split(' ')
        for (let i = 0; i < words.length; i++) {
            spans.push(
                <SSpan key={i} font={'b4'} textColor={'danger'}>
                    {words[i].slice(0, 1)}
                </SSpan>
            )
            spans.push(
                <SSpan key={i + 1000} font={'b4'} textColor={'g800'}>
                    {words[i].slice(1, words[i].length)}
                </SSpan>
            )
            spans.push(<SSpan key={i + 10000} font={'b4'} textColor={'g800'}>{' '}</SSpan>)
        }

        return (
            <SDiv direction={'row'} display={'block'}>
                {spans}
            </SDiv>
        )
    }

    return (
        <SDiv halign={'start'} width={width}>
            <SDiv>Password Criteria</SDiv>
            <SDiv height={8} width={width}/>
            <SDiv direction={'row'}>
                {validPass && <CheckboxSelected color={'green'}/>}
                {!validPass && <Checkbox color={'red'}/>}
                <SDiv width={8}/>
                <SDiv font='b3' textColor={'b800'}>Longer than 8 characters</SDiv>
            </SDiv>
            <SDiv height={8} width={width}/>
            <SDiv direction={'row'}>
                <StatusUnknown onClick={() => setShowHint(!showHint)}/>
                <SDiv width={8}/>
                <SDiv font='b3' textColor={'b800'}>Easy to remember randomized characters</SDiv>
            </SDiv>
            {showHint &&
            <SDiv>
                <SDiv height={6} width={width}/>
                <SDiv manualWrap={true} halign={'start'} direction={'row'} font={'b4'} textColor={'g800'}>
                    <SDiv width={8}/>
                    {textMsg}
                    <SDiv width={8}/>
                </SDiv>
                {buildColorEx(passEx1)}
                <SDiv font={'b4'} textColor={'danger'} halign={'start'}>{passEx2}</SDiv>
            </SDiv>
            }
            <SDiv height={16} width={width}/>
            <SDiv>New Password</SDiv>
            <SDiv height={8} width={width}/>
            <SDiv direction={'row'} halign={'start'}>
                <SInput
                    font={dispPass ? 'b2' : 'b1'}
                    width={0.8 * width} boxShadow={'normal'} borderStyle={'solid'} fillOff={true}
                    value={dispPass ? pass1.join('') : show1} onChange={(e) => updatePass1(e.target.value)}
                />
                <SDiv width={8}/>
                <SDiv font={'b3'} textColor={'b900'}
                      onClick={() => setDispPass(!dispPass)}>{dispPass ? 'hide' : 'show'}</SDiv>
            </SDiv>
            <SDiv height={8} width={width}/>
            <SDiv direction={'row'}>
                {'Re-Enter Password'}
                {(passEqual && show2) &&
                <SDiv direction={'row'}><SDiv width={8}/><CheckboxSelected color={'green'}/></SDiv>}
            </SDiv>
            <SDiv height={8} width={width}/>
            <SInput
                width={0.8 * width} boxShadow={'normal'} borderStyle={'solid'} fillOff={true}
                value={show2} onChange={(e) => updatePass2(e.target.value)}
            />
            {(!passEqual && show2) &&
            <SDiv font='b4' textColor={'danger'}>Passwords not valid or do not match yet</SDiv>
            }
            {!(!passEqual && show2) && <SDiv height={20}/>}
            <SDiv height={8} width={width}/>
            <SDiv width={width} direction={'row'}>
                <SButton
                    width={0.6 * width} disabled={!(passEqual && show2)}
                    onClick={() => changepassword()}
                >
                    {title}
                </SButton>
            </SDiv>
            <SDiv height={8} width={width}/>
            {failMessage && <SDiv textColor={'danger'}>{failMessage}</SDiv>}
        </SDiv>
    )
}

export function SetPassword(props) {

    const title = 'Set new password'//props.title
    const isOpen = props.isOpen
    const setOpen = props.setOpen

    const baseH = Hp(10)
    const baseW = Wp(20)

    const topGap = Hp(0.05)
    const bottomGap = topGap
    const leftGap = Wp(0.04)
    const rightGap = leftGap
    const modalWidth = Wp(0) - leftGap - rightGap
    const modalHeight = Hp(0) - topGap - bottomGap

    function close() {
        setOpen(false)
    }

    if (!isOpen) {
        return null
    }
    return (
        <SModal
            topGap={topGap} bottomGap={bottomGap} leftGap={leftGap} rightGap={rightGap}
            modalWidth={modalWidth} modalHeight={modalHeight}
            onClose={close} title={title} titleColor={'b800'}
        >
            <SDiv>
                <SDiv height={Hp(10)}/>
                <NewPasswords
                    title={title}
                    width={14 * baseW} height={3 * baseH}
                    onSuccess={close}
                />
                {/*
                {errorMsg && <SDiv font={'b2'} textColor={'danger'}>
                    {errorMsg}
                </SDiv>}
                */}
            </SDiv>
        </SModal>
    )
}