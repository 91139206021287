// Create profile from scratch first time

import React, {useEffect, useState} from "react";
import {SButton, SDiv} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {Spinner} from "components/library/common/Spinner";
import {Profile} from "models/profile/userprofile";
//import {observer} from "mobx-react-lite";
import {MainHeader} from "components/library/nav/MainHeader";


function RestrictButton(props) {

    const name = props.name
    const restrictions = props.restrictions
    const onClick = props.onClick

    return (
        <SDiv height={Hp(50)}>
            <SButton
                height={Hp(40)} width={Wp(0.2)} secondary={!restrictions[name]} onClick={() => onClick(name)}
                color={'a800'}
            >
                {name}
            </SButton>
        </SDiv>
    )
}


export function SetRestrictions(props) {

    const [loadProfile, callLoadProfile] = useApi('SimpaService', 'load_user_profile', true, true);
    const [storeProfile, callStoreProfile] = useApi('SimpaService', 'store_user_profile', false, true);
    const [profile, setProfile] = useState(null)

    const [restrictions, setRestrictions] = useState({})
    const [origrestrictions, setOrigRestrictions] = useState({})
    const [saving, setSaving] = useState(false)


    useEffect(() => {
        callLoadProfile({token: rawToken()})
    }, [])

    useEffect(() => {
        setSaving(false)
        callLoadProfile({token: rawToken()})
    }, [storeProfile.counter])

    useEffect(() => {
        const profile = loadProfile.data
        if (!profile) {return}
        setProfile(Profile.create(profile))
        setRestrictions(profile.dietary_restrict)
        setOrigRestrictions(profile.dietary_restrict)
    }, [loadProfile.counter])

    function toggleRestrict(name) {
        var tmpR = {...restrictions}
        tmpR[name] = !tmpR[name]
        setRestrictions(tmpR)
    }

    function isOriginal() {
        for (const p in restrictions) {
            if (restrictions[p] !== origrestrictions[p]) {
                return false
            }
        }
        return true
    }

    function saveRestrictions() {
        profile.setRestrictions(restrictions)
        callStoreProfile({token: rawToken(), user_profile: profile.json})
    }

    function dietaryButtons() {
        // Hard code for now, can turn into function of restrictions
        return (
            <SDiv direction={'row'} width={Wp(0.7)} valign={'top'}>
                <SDiv width={Wp(0.3)} valign={'top'}>
                    <SDiv height={Hp(50)}/>
                    <RestrictButton name={'dairy'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'gluten'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'nuts'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'peanuts'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'soy'} restrictions={restrictions} onClick={toggleRestrict}/>
                </SDiv>
                <SDiv width={Wp(0.1)}/>
                <SDiv width={Wp(0.3)} valig={'top'}>
                    <SDiv height={Hp(50)}/>
                    <RestrictButton name={'eggs'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'poultry'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'meat'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'fish'} restrictions={restrictions} onClick={toggleRestrict}/>
                    <RestrictButton name={'seafood'} restrictions={restrictions} onClick={toggleRestrict}/>
                </SDiv>
            </SDiv>
        )
    }

    return(
        <SDiv>
            <MainHeader position={'relative'} showProfile={false}/>
            <SDiv height={Hp(10)}/>
            <SDiv>
                Dietary Restrictions
            </SDiv>
            {!profile && <Spinner/>}
            {profile &&
            <SDiv>
                {dietaryButtons()}
                <SButton
                    position={'absolute'} bottom={Wp(0.1)} disabled={isOriginal()}
                    onClick={() => saveRestrictions()}
                >
                    Save
                </SButton>
            </SDiv>
            }

        </SDiv>
    )
}
