// pixels from left to center finger fraction
// pixels from left to center hand fraction
/*
Notes on grey hand:
Img is 1216x895
Box from tip of finger to second knuckle is 140x70, so hypotenuse is Hyp=156 pixels
Image distance in pixels is then finger_length/Hyp  if user finger is 56mm then distance ~0.36mm/pix
*/

import React, {useEffect, useState, useRef, useContext} from "react";
import {GlobalStoreContext} from "models/rootStore";

import useImage from "use-image";
import {Circle, Image, Layer, Rect, Stage} from "react-konva";

import {imgs} from "utils/images";
import {stheme} from "themes/theme";
import {Hp, Wp} from "utils/util";
import {computeVolumeUnit, computeMl, SM, MD, LG} from "components/library/volume/units";
import {ShapeSelect} from "components/library/volume/ShapeSelect";

// background color
const BCOLOR = stheme.color.greys.g0

// constraints
const maxSphereVol = 10000  // in mL
// design specs
const minImgH = Hp(50)
const maxImgH = Hp(140)
// hand image constraints/positioning
const fullImgWidth = 1280
const fullImgHeight = 400
// used for centering image correctly
const left2FingerFrac = 50 / fullImgWidth // distance to finger
const top2FingerFrac = 60 / fullImgHeight  // pixels from top of image to top of finger
const left2CenterFrac = 420 / fullImgWidth  // distance to center of hand
const top2CenterFrac = 60 / fullImgHeight  // same as finger height for consistency
// for fade mask
const right2midarmFrac = 315 / fullImgWidth

// used to compute distance per pixel, need to deal with bend of hand using pythagorean theorm
// measure from x pos at tip of finger to past bottom knuckle, y pos below crease between thumb and hand
const tip2centerH = 130;
const tip2centerW = 385;
// measure from x pos past bottom knuckle to end palm, y pos below crease between thumb and hand to same point as tip2palmStraight
const center2endpalmH = 90;
const center2endpalmW = 260;
const tip2centerPix = (tip2centerW**2 + tip2centerH**2)**0.5   // number pixels from tip of finger to center of hand
const center2endpalmPix = (center2endpalmW**2 + center2endpalmH**2)**0.5  // number pixels from center of hand to end of palm
// pixels of full hand length as measured if straight going through curved triangle
const pixelsPerHandFull = tip2centerPix + center2endpalmPix
// straight line pixels from tip of finger to palm end
const tip2palmendStraight = 620
// determine the scaling factor from straight hand to curved hand ~0.9
const handContractionRatio = tip2palmendStraight / pixelsPerHandFull
// number of pixels per hand measurement, distCmPerPix = cmPerHand / pixelsPerHand
// example: if hand len is 18cm then curved straight length is 18cm * 0.9 = 16.2cm
// then CmPerPix = 16.2/tip2palmendStraight


function cylWidth2Height(cylW, volume, distCmPerPix) {
    // return scaled to hand height of cylinder given width and volume
    let cylRadCm = cylW / 2 * distCmPerPix
    let cylHCm = (volume / (Math.PI * cylRadCm**2))
    return cylHCm / distCmPerPix
}

function cylHeight2Width(cylH, volume, distCmPerPix) {
    // return scaled to hand width of cylinder given height and volume
    let newCylHCm = cylH * distCmPerPix
    let newCylRadCm = (volume / (newCylHCm * Math.PI))**0.5
    return (newCylRadCm * 2) / distCmPerPix
}

function distPerPixHand(imgH, userHandLenCm) {
    const imgW = imgH / fullImgHeight * fullImgWidth
    // return distCmPerPix
    return (handContractionRatio * userHandLenCm) / (tip2palmendStraight/fullImgWidth * imgW)
}

function computeVolume(sphereSize, distInCmPerPix, unit) {

    // 1ml == 1 cubic cm
    const pixRadius = sphereSize / 2
    const radiusCm = pixRadius * distInCmPerPix
    var volumeMl = radiusCm ** 3 * Math.PI / 0.75

    if (!unit) {return volumeMl}

    var computeVol = computeVolumeUnit(unit, volumeMl)

    /*
    if (computeVol <= 0.1) {
        computeVol = Math.round(computeVol * 1000) / 1000
    }
    else if (computeVol <= 1) {
        computeVol = Math.round(computeVol * 100) / 100
    }
    else if (computeVol <= 10) {
        computeVol = Math.round(computeVol * 10) / 10
    }
    else if (computeVol <= 100) {
        computeVol = Math.round(computeVol)
    }
    else if (computeVol <= 1000) {
        computeVol = Math.round(computeVol * 0.1) / 0.1
    }
    else {
        computeVol = Math.round(computeVol * 0.01) / 0.01
    }
     */
    var volume = computeMl(unit, computeVol)
    volume = Math.max(0.01, volume)
    volume = Math.min(10000, volume)

    return volume

}


function positionToParameters(position, userHandLenCm) {

    // constrain position within bounds
    const curPos = Math.max(0.0001, Math.min(0.99999, position))
    const imgH = (1 - curPos) * maxImgH + curPos * minImgH
    const imgW = imgH / fullImgHeight * fullImgWidth

    // compute volume based on two image sizes and user hand size
    let maxdistCmPerpix = distPerPixHand(minImgH, userHandLenCm)
    const maxSphereSize = 2 * (maxSphereVol * 0.75 / Math.PI) ** (1 / 3) / maxdistCmPerpix

    const sphSize = curPos * maxSphereSize
    const distCmPerPix = distPerPixHand(imgH, userHandLenCm)

    return [curPos, sphSize, imgH, imgW, distCmPerPix]
}


export function optimizePosition(initVolume, userHandLenCm) {

    var leftPos = 0
    var rightPos = 1
    var checkPos = (rightPos - leftPos)/2
    const [curPos, sphSize, imgH, imgW, distCmPerPix] = positionToParameters(checkPos, userHandLenCm)
    var volume = computeVolume(sphSize, distCmPerPix, '')
    var vDiff = initVolume - volume

    var cnt=0
    while (Math.abs(vDiff)/initVolume > 0.005) {

        if (vDiff > 0) {leftPos = checkPos}
        else {rightPos = checkPos}
        checkPos = leftPos + (rightPos - leftPos)/2
        let [curPos, sphSize, imgH, imgW, distCmPerPix] = positionToParameters(checkPos, userHandLenCm)
        checkPos = curPos
        volume = computeVolume(sphSize, distCmPerPix, '')
        vDiff = initVolume - volume
        cnt += 1
        if (cnt > 16) {break}
    }

    return checkPos

}



export function HandSphere(props) {

    const gstore = useContext(GlobalStoreContext)
    const userHandLenCm = gstore.profile.handLen
    const [handimg] = useImage(imgs[`hand_large_color_${gstore.profile.skinshade}@2x.png`]);

    const [sphereimg] = useImage(imgs['blue_sphere@2x.png']);
    const [cylinderimg] = useImage(imgs['blue_cyllinder@2x.png']);

    const height = props.height
    const width = props.width
    const unit = props.unit
    const setVolume = props.setVolume
    const position = props.pos
    // const initVolume = props.volume
    // const setPosition = props.setPos

    const cylRef = useRef(null)
    const [activeShape, setActiveShape] = useState('sphere')

    const [cylW, setCylW] = useState(100)

    // determine volume from existing sizes
    var [curPos, sphSize, imgH, imgW, distCmPerPix] = positionToParameters(position, userHandLenCm)
    const imgXposFrac = (1-curPos) * left2FingerFrac + curPos * left2CenterFrac
    const imgYposFrac = (1-curPos) * top2FingerFrac + curPos * top2CenterFrac
    const imgX = -imgXposFrac*imgW  + 0.5*width
    const imgYFrac = (1-curPos) * 0.6 + curPos * 0.75
    const imgY = -imgYposFrac*imgH  + imgYFrac*height
    const volume = computeVolume(sphSize, distCmPerPix, unit)

    useEffect(() => {
        if (setVolume) {setVolume(volume)}
    })

    const vertOffset = (1.1-curPos) * Hp(8)
    const horzOffset = curPos * Wp(2)
    const xOffset = imgXposFrac * imgW + horzOffset
    const yOffset = imgYposFrac * imgH - vertOffset
    // position bottom of sphere to top left corner of image no matter size of sphere, then move offset
    var xposSph = -0.5 * sphSize + imgX + xOffset
    var yposSph = -sphSize + imgY + yOffset

    // update cylinder positoins
    const cylDragRad = Hp(10)
    // determine constrained cylinder size (height/width) from volume
    var cylH = cylWidth2Height(cylW, volume, distCmPerPix)
    if (cylH > imgYFrac*height - 2.5*cylDragRad) {
        cylH = imgYFrac*height - 2.6*cylDragRad
        const newClyW = cylHeight2Width(cylH, volume, distCmPerPix)
        setCylW(newClyW)
    }
    if (cylH < 0.7*cylDragRad) {
        cylH = 0.8*cylDragRad
        const newClyW = cylHeight2Width(cylH, volume, distCmPerPix)
        setCylW(newClyW)
    }

    // center initial cylinder to top left corner of image, then offset to desired position
    var xposCyl = -0.5 * cylW + imgX + xOffset
    var yposCyl = -cylH + imgY + yOffset

    const cylDragY = yposCyl - 1.3*cylDragRad
    const cylDragX = xposCyl + 0.5*cylW
    const maxCylDragY = yposCyl + cylH - 1.5*cylDragRad //+ 26

    function setCylinderH(e) {
        if (!cylRef.current) {return}
        const curYpos = cylRef.current.getAbsolutePosition().y
        const pixDiff = cylDragY - curYpos
        let newCylW = cylHeight2Width((cylH+pixDiff), volume, distCmPerPix)
        setCylW(newCylW)
    }

    if (!cylW || cylW > 0.5*width) {setCylW(0.5*width)}

    // hand mask parameters
    const maskEnd = 0.8
    const maskCut = maskEnd / 2
    const maskH = height - imgY - top2FingerFrac*imgH
    const midArmXPos = imgX + imgW - right2midarmFrac*imgW
    const maskW = Math.min(0.5*width, Math.max(0.2*width, (width-midArmXPos)/maskCut))
    return (
        <Stage width={width} height={height}>
            <Layer>
                <Rect width={width} height={height}
                      fill={BCOLOR}
                />
                {(activeShape === 'sphere') &&
                <Image
                    image={sphereimg}
                    height={sphSize} width={sphSize}
                    y={yposSph} x={xposSph}
                />
                }
                <Image
                    x={imgX} y={imgY} height={imgH} width={imgW}
                    image={handimg}
                />
                {/*
                <Rect
                    x={width-maskW} y={height-maskH}
                    width={maskW} height={maskH}
                    fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                    fillLinearGradientEndPoint={{ x: maskW, y: 0}}
                    fillLinearGradientColorStops={[0, BCOLOR + '00', maskCut, BCOLOR + 'E3', maskEnd, BCOLOR + 'FF']}
                />
                */}

            </Layer>
            {(activeShape === 'cylinder') &&
            <Layer
                onDragStart={(e) => setCylinderH(e)}
                onDragMove={(e) => setCylinderH(e)}
                onDragEnd={(e) => setCylinderH(e)}
            >
                <Image
                    image={cylinderimg}
                    height={cylH} width={cylW}
                    y={yposCyl} x={xposCyl}
                />
                <Circle
                    ref={node => cylRef.current = node}
                    y={cylDragY} x={cylDragX}
                    radius={cylDragRad}
                    stroke={stheme.color.brand}
                    strokeWidth={1}
                    draggable={true}
                    dragBoundFunc={(pos) => {
                        return {
                            x: cylDragX,
                            y: Math.min(Math.max(pos.y, 1.1*cylDragRad), maxCylDragY)
                        }
                    }}
                />
            </Layer>
            }
            <Layer>
                <ShapeSelect
                    height={0.2*height} width={0.2*height}
                    y={0.8*height} x={0.1*height}
                    activeShape={activeShape} setActiveShape={setActiveShape}
                />
            </Layer>
        </Stage>
    )
}