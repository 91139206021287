/*
Examples of horizontal scrolling
https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
http://jsfiddle.net/8dtrktpw/
 */

import React, {useState, useEffect} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";

export function SVerticalScroll(props) {

    const height = props.height
    const width = props.width
    const display = props.display ? props.display : 'block'

    const children = props.children
    const scrollref = props.scrollref

    useEffect(() => {
        if ((children.length > 0) && (scrollref && scrollref.current)) {
            const obj = children[children.length-1]
            scrollref.current.scrollIntoView()
        }

    }, [scrollref])

    return (
        <SDiv
            {...props}
            width={width} height={height}
            display={display}
            valign={'start'} halign={'start'}
            overflow={'auto'}
            overflowY={'scroll'}
            overScrollBehaviorY={'none'}
        >
            <SDiv
                width={width}
                direction={'column'}
                valign={props.valign ? props.valign : 'start'}
                halign={props.halign ? props.halign : 'center'}
                overScrollBehaviorY={'none'}
            >
                {children}
            </SDiv>
        </SDiv>
    )
}