
import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SButton} from "components/library/styledhtml/SButton";
import {Hpa, Wpa} from "utils/util";
import {LandingHeader, HeaderSpacing} from "components/pages/landing/LandingHeader";
import {getDMdimensions} from "utils/util";


const yourdata = `
We believe your data is YOUR data.    

This means you own your data. We do not sell your data to anyone.     

You should be allowed to choose who can or cannot see the data you create for yourself.   

You should be able to take your data wherever you want, show your data to whomever you want and unshow it just as easy.    

The privacy and security applied to your data should ensure that no one else can get ahold of your data without your permission.   

As simple as this sounds, this concept is a paradigm shift, especially in health data.   Many companies have evolved to profit from the data they gather on individuals by selling the individuals data to others in some context.   Our business model means that every individual is our direct customer and Simpa exists to use your data for YOUR benefit and goals.    

Simpa has no other motivation other than helping you on your journey as best as possible.
`

function Title(props) {

    const width = props.width
    const text = props.text

    return (
        <SDiv font={'h3'} textColor={'b800'} width={width}
              manualWrap textAlign={'start'} halign={'center'}>
            {text}
        </SDiv>

    )
}


function SubText(props) {

    const width = props.width
    const text = props.text
    const textAlign= props.textAlign ? props.textAlign : 'center'

    return (
        <SDiv font={'h4r'} textColor={'g600'} width={width}
              manualWrap textAlign={textAlign} halign={'center'}>
            {text}
        </SDiv>

    )
}

export function YourData(props) {

    var [width, height] = getDMdimensions()

    var textWidth = Wpa(0.8)
    if (window.innerWidth > window.innerHeight) {
        textWidth = Wpa(0.6)
    }

    const mainBackground = 'g0'

    return (
        <SDiv width={width} color={mainBackground} valign={'start'}>
            <HeaderSpacing/>
            <SDiv height={Hpa(20)}/>
            <LandingHeader/>
            <SDiv height={Hpa(20)}/>
            <Title text={'YOUR data'} width={textWidth}/>
            <SubText text={yourdata} width={textWidth} textAlign={'start'}/>
            <SDiv width={Wpa(0)} height={Hpa(0.5)}/>
        </SDiv>

    )
}