

// UserProfile
import {types, flow} from "mobx-state-tree";
import {callApi} from "api/api";
import {utc} from "utils/util";

export const Restrictions = types
    .model({
        user_restrictions: types.map(types.boolean),
        state: types.optional(types.enumeration("State", ["init", "pending", "done", "error"]), 'init'),
        loadTime: types.optional(types.number, 0)
    })
    .actions(self => ({
        //afterCreate(){
        //    console.log('CON', self.desc)
        //},
        doNothing(n) {
        },
        set(restriction, value) {
            self.user_restrictions.set(restriction, value)
        },
        loadRestrictions: flow(function* loadRestrictions() {
            //self.state = "pending";
            try {
                // ... yield can be used in async/await style
                const ret = yield callApi(
                    'SimpaNutrition', 'restrictions',
                    {}, true)
                self.user_restrictions.clear()
                for (let i=0; i<ret.length; i++) {
                    self.user_restrictions.set(ret[i], false)
                }
                self.state = "done"
                self.loadTime = utc()
            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch restrictions", error)
                self.state = "error"
            }
        }),
    }))
    .views( self => ({
        get nothing() {
            return null
        },
        get restrictions() {
            if (self.user_restrictions.size === 0) {
                self.loadRestrictions()
            }
            return self.user_restrictions.toJS()
        },
    }));