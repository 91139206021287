import React, {useContext, useEffect, useRef, useState} from "react";
import {GlobalStoreContext} from "models/rootStore";
import {Close, Search} from "grommet-icons/es6";

import {Hp, Wp} from "utils/util";
import {useApi} from "api/api";

import {Consumable} from "models/intake/consumable";
import {Composition} from "models/intake/composition";
import {SDiv, SInput} from "components/library/styledhtml";
import {ConCompView} from "components/library/intake/viewIntake/ConCompView";
import {stheme} from "themes/theme";
import {SVerticalScroll} from "components/library/basic/SVerticalScroll";


export function ConsumableSearch(props) {

    const restrict = useContext(GlobalStoreContext).profile.restrictions.restrictions

    const width = props.width ? props.width : Wp(0)
    const height = props.height ? props.height : Hp(300)
    const subHeight = Hp(30)
    const onSelect = props.onSelect
    const currentId = props.currentId
    const [value, setValue] = useState(props.initValue)
    const [items, setItems] = useState([])
    const [counter, setCounter] = useState(0)

    const [search, callSearch] = useApi('SimpaNutrition', 'search', true, true);

    const lastValTime = useRef(0);
    const callDelay = 500

    function makeSearchCall() {
        const restrictions = []
        for (const [r, hasR] of restrict.entries()) {
            if (hasR) {
                restrictions.push(r)
            }
        }
        var dbs = ['SR28']
        const params = {
            'srch_term': value.trim(),
            'max_return': 50,
            'cutoff': 2,
            'restrictions': restrictions,
            'databases': dbs
        }
        callSearch(params)
    }


    useEffect(() => {
        if (value.trim()) {
            if (Date.now() - lastValTime.current >= callDelay) {
                makeSearchCall()
            } else {
                setTimeout(function () {
                    setCounter(counter + 1)
                }, callDelay + 1)
            }
        } else {
            setItems([])
        }
        lastValTime.current = Date.now()
    }, [value, counter])

    function getConComp(data) {

        var cc
        if (data.type === 'con') {
            cc = Consumable.create(data)
        } else {
            cc = Composition.create(data)
        }
        return cc

    }

    function select(cc) {
        if (onSelect) {onSelect(cc)}
    }

    function openNothing(concomp) {}

    useEffect(() => {
        if (search.hasData) {
            const tmpItems = []

            for (let i = 0; i < search.data.length; i++) {
                const cc = getConComp(search.data[i].concomp)
                var highlight = false
                if (currentId && currentId === cc.id) {
                    highlight = true
                }
                tmpItems.push(
                    <SDiv
                        key={i}
                        onClick={() => select(cc)}
                    >
                        <ConCompView
                            concomp={cc} key={i} openEdit={() => {}}
                            height={Hp(30)} width={0.9*width}
                            volumeOff={true} highlight={highlight}
                        />
                        <SDiv height={4}/>
                    </SDiv>
                )
            }
            if (value) {
                setItems(tmpItems)
            } else {
                setItems([])
            }

        }
    }, [search.counter, currentId])

    function clear(e) {
        e.stopPropagation()
        setValue('')
    }

    return (
        <SDiv
            width={width} color={'g0'}
            onClick={(e) => e.stopPropagation()}
        >
            <SDiv
                direction={'row'}
                width={width - Wp(80) + Hp(10)}
            >
                <SDiv height={0.5 * subHeight} width={0.5 * subHeight}>
                    <Search size={`${Hp(16)}px`} color={stheme.color.brands.b900}/>
                </SDiv>
                <SDiv width={Hp(5)}/>
                <SInput
                    height={subHeight} width={width - Wp(85)}
                    value={value} onChange={(e) => setValue(e.target.value)}
                />
                <SDiv width={Hp(5)}/>
                <SDiv
                    height={0.5 * subHeight} width={0.5 * subHeight} onClick={(e) => clear(e)}
                    borderRadius={100} borderStyle={'solid'} borderColor={'g200'}
                    borderWidth={1}
                >
                    <Close size={`${Hp(12)}px`} color={stheme.color.greys.g200}/>
                </SDiv>
            </SDiv>
            <SDiv height={Hp(10)}/>
            <SDiv font={'b5'} textColor={'g200'} height={Hp(10)}>
                Database search results
            </SDiv>
            <SDiv height={Hp(10)}/>

            <SVerticalScroll width={width} height={height - Hp(30) - subHeight}>
                {items}
            </SVerticalScroll>
        </SDiv>
    )

}