import styled from "styled-components";
import React, {useState} from "react";

import {stheme, getColor} from "themes/theme";
import {getDim} from "components/library/utility/utilityFunctions";
import {hexToRgbA, Hp} from "utils/util";

const StyleTextArea = styled.textarea(props => ({

    'height': props.height,
    'width': props.width,
    //background: props.color,
    'background-color': props.color,
    'resize': props.resize ? props.resize : 'none',

    'value': props.value,
    'type': props.type,
    'readonly': props.readonly,
    'disabled': props.disabled,
    'size': props.size,
    'min': props.min,
    'max': props.max,
    'maxHeight': props.maxHeight,
    'pattern': props.pattern,
    'placeholder': props.placeholder,
    'required': props.required,
    'autocomplete': props.autocomplete,
    'outline': props.outline ? props.outline : 'none',
    ':focus': {
        //'border-style': 'none none solid none',
        //'border-color': props.activeBorderColor ? props.activeBorderColor : stheme.color.brand,
        'outline-offset': 0,
    },
    '-webkit-box-shadow': props.fillOff ? '0 0 0px 1000px white inset' : null,
    'filter': props.fillOff ? 'none' : null,

    // borders
    border: props.border,
    'border-radius': props.borderRadius ? props.borderRadius : null,
    'border-style': props.borderStyle ? props.borderStyle : 'none none none none',
    'border-color': props.borderColor ? props.borderColor : stheme.color.greys.g200,
    'border-width': props.borderWidth ? props.borderWidth : '2px',
    'border-left': props.borderLeft,
    'border-right': props.borderRight,
    'border-top': props.borderTop,
    'border-bottom': props.borderBottom,

    padding: props.padding,

    // text and fonts
    color: props.textColor,
    'text-align': props.textAlign,
    'font-size': props.font_size ? props.font_size : '26px',
    'font-weight': props.font_weight,
    'font-family': props.font_family,
    'line-height': props.line_height,
    'text-overflow': 'ellipsis',
    //'word-break': 'break-all',
    'word-wrap': 'break-word',
    'white-space': 'pre-line',//props.manualWrap ? 'pre-line' : null,
    overflow: props.overflow ? props.overflow : null,

}))


export function STextArea(props) {

    const blurOnEnter = props.blurOnEnter // defocus input when enter pushed (default)

    var font = props.font
    if (!font) {font = stheme.font.b1}
    else if (stheme.font.hasOwnProperty(font)) {font = stheme.font[font]}
    // setup font defaults
    const fontSize = props.fontSize ? props.fontSize : font.size
    const fontFamily = props.fontFamily ? props.fontFamily : font.family
    const lineHeight = props.lineHeight ? props.lineHeight : font.sp
    const fontWeight = props.fontWeight ? props.fontWeight : font.weight

    var color = getColor(props.color, false)
    var txtColor = getColor(props.textColor, false)
    var borderColor = getColor(props.borderColor, false)

    var borderStyle = props.borderStyle
    if ((borderColor && !borderStyle) || borderStyle === 'solid') {
        borderStyle = 'solid solid solid solid'
    }

    if (props.op) {
        if (!txtColor) {txtColor = stheme.color.ogrey}
        if (txtColor.match('\#')) {txtColor = hexToRgbA(txtColor, props.op)}
    }
    else if (!txtColor) {txtColor = stheme.color.greys.d1}

    var boxShadow = props.boxShadow
    if (boxShadow === 'normal') {boxShadow = '0px 7px 14px #0000001A'}
    else if (props.elevation && !props.boxShadow) {
        boxShadow = props.elevation
    }


    function handleKeyPress(e) {
        if(e.keyCode === 13 && blurOnEnter){
            e.target.blur();
        }
    }

    return (
        <StyleTextArea
            {...props}
            onKeyDown={(e) => handleKeyPress(e)}
            color={color}
            borderColor={borderColor}
            borderStyle={borderStyle}
            boxShadow={boxShadow}

            font_size={fontSize}
            font_family={fontFamily}
            font_weight={fontWeight}
            line_height={lineHeight}
            textColor={txtColor}
        />
    )
}