
import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Hpa, Wpa} from "utils/util";
import {LandingHeader, HeaderSpacing} from "components/pages/landing/LandingHeader";
import {getDMdimensions} from "utils/util";
import {ResponsiveLayout} from "components/ResponsiveLayout/responsiveLayout";


const mission = `
We empower, enable, and help all humans to achieve unique health goals
`

const missionm = `
We empower, enable, and help 
all humans to achieve 
unique health goals
`
const vision = `
A world in which all people live a healthy and full life
`

const visionm = `
A world in which all people 
live a healthy and full life
`

const about = `
We have brought our heart and soul into envisioning and building Simpa.  Life is a (sometimes complex) journey.   Simpa attained its name to be a SIMulated Personal Advocate.   

You are unique and we believe it is important to meet you in the context of your own experiences and journey.   We are not all the same and there is bias in all facets of life, including health information.  We recognize knowledge in health is biased in many ways.   This is why we require our users to build up their own data.   

From your data and your goals, we look for patterns in your life that are meaningful to you.    A lot of scientific knowledge might be good for X% of the ‘average’ population, but is it meaningful or useful to you?   Simpa was designed to make it much easier for you to collect your data and then leverage that information to help you along your unique journey.

Simpa does not try to tell you where to go, but rather tries to understand where you want to go and then help take you there.

Our goal is to create a product using the most advanced technology possible to be an advocate for human beings that might benefit from one.    As a personal advocate, Simpa lets you set your own goals, then attempts to empower and help you, delivering insights, analysis and suggestions, along Your journey toward Your goals.
`

function Title(props) {

    const width = props.width
    const text = props.text

    return (
        <SDiv font={'h3'} textColor={'b800'} width={width}
              manualWrap textAlign={'start'} halign={'start'}>
            {text}
        </SDiv>

    )
}


function SubText(props) {

    const width = props.width
    const text = props.text
    const textAlign= props.textAlign ? props.textAlign : 'center'

    return (
        <SDiv font={'h4r'} textColor={'g600'} width={width}
              manualWrap textAlign={textAlign} halign={'start'}>
            {text}
        </SDiv>

    )
}

export function AboutSimpaCore(props) {

    var [width, height] = getDMdimensions()

    var textWidth = Wpa(0.8)
    if (window.innerWidth > window.innerHeight) {
        textWidth = Wpa(0.6)
    }

    const mainBackground = 'g0'

    return (
        <SDiv width={width} color={mainBackground} valign={'start'}>
            <HeaderSpacing/>
            <SDiv height={Hpa(20)}/>
            <LandingHeader/>
            <SDiv width={Wpa(0)} height={Hpa(0.4)} color={'g0'}>
                <SDiv height={Hpa(20)}/>
                <Title text={'Mission'} width={textWidth}/>
                <SubText text={missionm} width={textWidth} textAlign={'start'}/>
                <SDiv height={Hpa(30)}/>
            </SDiv>
            <SDiv width={Wpa(0)} height={Hpa(0.4)} color={'g50'} boxShadow={'normal'}>
                <SDiv height={Hpa(40)}/>
                <Title text={'Vision'} width={textWidth}/>
                <SubText text={visionm} width={textWidth} textAlign={'start'}/>
                <SDiv height={Hpa(40)}/>
            </SDiv>
            <SDiv height={Hpa(60)}/>
            <Title text={'Simpa values'} width={textWidth}/>
            <SubText text={about} width={textWidth} textAlign={'start'}/>
            <SDiv width={Wpa(0)} height={Hpa(0.5)}/>
        </SDiv>

    )
}

export function AboutSimpa(props) {

    return (
        <ResponsiveLayout
            path={'/'}
            renderDesktop={() => (<AboutSimpaCore {...props}/>)}
            renderMobile={() => (<AboutSimpaCore {...props}/>)}
        />
    )

}