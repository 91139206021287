// Composition
import {types, clone} from "mobx-state-tree";
import {Consumable} from "models/intake/consumable";
import {cleanText, parseText} from "models/intake/utility/parseConComp";


export const Composition = types
    .model({
        id: types.optional(types.string, ""),
        desc: types.optional(types.string, ""),
        name: types.optional(types.string, ""),
        subs: types.array(types.union(types.late(() => Composition), Consumable)),
        dens: types.optional(types.number, 1),
        time: types.optional(types.number, 0),
        loc: types.optional(types.array(types.number), [0, 0]),
        meta: types.map(types.string),
        moist: types.optional(types.number, 0),
        fat: types.optional(types.array(types.union(types.number, types.string)), ["0", 0]),
        retn: types.array(types.string),
        verified: types.optional(types.boolean, false),
        ver: types.optional(types.string, ""),
        type: types.literal('comp'),
        open: types.optional(types.boolean, false),
        shiftOn: types.optional(types.boolean, false),
        deleteOn: types.optional(types.boolean, false),
        editSubsOn: types.optional(types.boolean, true),
        editOn: types.optional(types.boolean, true),
        date: types.optional(types.string, ""),
        saveOnChange: types.optional(types.boolean, false),
        tmpText: types.optional(types.string, ''),
        tmpSubs: types.array(types.union(types.late(() => Composition), Consumable)),
    })
    .actions(self => ({
        parseText(text) {
            if (self.tmpText === text) {return}
            self.tmpText = text
            // parse input text into array of composition/consumables
            self.tmpSubs = parseText(cleanText(text + ' '), true)
        },
        clearTemp() {
            self.tmpText = ''
            self.tmpSubs.clear()
        },
        saveTemp() {
            self.tmpSubs.forEach(function (sub) {
                self.subs.push(clone(sub))
            });
            self.tmpText = ''
            self.tmpSubs.clear()
            //self.updateName()
            //const g = self.grams
        },
        closeAll() {
            self.subs.forEach(function (sub) {
                if (sub.iscomp) {
                    sub.setIsOpen(false)
                    sub.setShiftOn(false)
                    sub.setDeleteOn(false)
                    sub.closeAll()
                }
            });
            self.setDeleteOn(false)
            self.setShiftOn(false)
        },
        selectSub(subdesc) {
            self.subs.forEach(function (sub) {
                if (sub.iscomp && sub.desc === subdesc) {
                    self.closeAll()
                    sub.setIsOpen(true)
                }
            });
        },
        toggleSubOpen(subdesc) {
            self.subs.forEach(function (sub) {
                if (sub.iscomp && sub.desc === subdesc) {
                    sub.setIsOpen(!sub.open)
                }
            });

        },
        delete(index) {
            if (index < self.subs.length) {
                self.subs.splice(index, 1)
            }
        },
        setShiftOn(value) {
            self.shiftOn = value
            if (value) {self.setDeleteOn(false)}
        },
        setDeleteOn(value) {
            self.deleteOn = value
            if (value) {self.setShiftOn(false)}
        },
        shiftup(index) {
            // shift up is actually decreasing the index
            if (index > 0) {
                var sub = clone(self.subs.splice(index, 1)[0])
                self.subs.splice(index-1, 0, sub)
            }
        },
        shiftdown(index) {
            // shift up is actually increasing the index
            if (index < self.subs.length-1) {
                var sub = clone(self.subs.splice(index, 1)[0])
                self.subs.splice(index+1, 0, sub)
            }
        },
        updateName(restrictions=[]) {
            self.subs.forEach(function (sub) {
                //sub.updateName(restrictions)
            });
        },
        setDesc(desc) {
            self.desc = desc
        },
        setVolume(volume) {
            const fracChange = volume / self.volume
            self.subs.forEach(function (sub) {
                sub.setVolume(fracChange*sub.volume)
            });
        },
        setNameId(name, id) {
            self.name = name
            self.id = id
        },
        setIsOpen(bool) {
            self.open = bool
            if (bool === false) {
                self.setDeleteOn(false)
                self.setShiftOn(false)
            }
        },
        setSubsEditOn(bool) {
            self.subs.forEach(function (sub) {
                if (sub.iscomp) {
                    sub.setEditOn(bool)
                }
            });
        },
        setEditOn(bool) {
            self.editOn = bool
        },
        setEditSubs(bool) {
            self.editSubsOn = bool
        },
        setAllTop(bool) {
            self.subs.forEach(function (sub) {
                if (sub.iscomp) {
                    sub.setIsOpen(bool)
                    if (bool === false) {
                        sub.setShiftOn(false)
                        sub.setDeleteOn(false)
                    }
                }
            });
        },
        setAllTime(time) {
            var desiredDt = new Date(time * 1000)
            if (!self.time) {
                if (self.desc === 'Breakfasst') {
                    desiredDt.setHours(8)
                }
                else if (self.desc === 'Lunch') {
                    desiredDt.setHours(12)
                }
                else if (self.desc === 'Dinner') {
                    desiredDt.setHours(18)
                }
                self.time = desiredDt.getTime() / 1000
            }
            else {
                var curDt = new Date(self.time * 1000)
                curDt.setFullYear(desiredDt.getFullYear())
                curDt.setMonth(desiredDt.getMonth())
                curDt.setDate(desiredDt.getDate())
                self.time = curDt.getTime() / 1000
            }
            self.subs.forEach(function (sub) {
                if (sub.iscomp) {
                    sub.setAllTime(time)
                }
            });
        },
        setTime(hour, min) {
            var curDt = new Date(self.time * 1000)
            curDt.setHours(hour)
            curDt.setMinutes(min)
            self.time = curDt.getTime() / 1000
        },
        setDate(date) {
            if (date.length === 10) {
                self.date = date
            }
        },
        setSaveOnChange(bool) {
            self.saveOnChange = bool
        },
    }))
    .views( self => ({

        get hasDesc() {
            return self.desc.length > 0
        },

        get grams() {
            var totGrams = 0;
            self.subs.forEach(function (sub) {
                totGrams += sub.grams
            });

            //  fat and moisture cooking adjustments
            const percent_change = self.fat[1] + self.moist

            return ((100 + percent_change) / 100.) * totGrams;
        },

        get volume() {
            var totVolume = 0;
            self.subs.forEach(function (sub) {
                totVolume += sub.volume
            });
            return totVolume
        },
        get density() {
            return 0
        },
        get length() {
            return self.subs.length
        },
        get numSubs() {
            return self.subs.length
        },
        get totalSubs() {
            var totSubs = self.numSubs;
            self.subs.forEach(function (sub) {
                totSubs += sub.totalSubs
            });
            return totSubs
        },
        get buildSubs() {
            return self.subs.map((x) => x)
        },
        get iscomp() {
            return true
        },

        get localTime() {
            if (self.time !== 0) {
                const dt = new Date(self.time*1000)
                return dt.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
            }
            else {
                return "<time>"
            }
        },
        get hour() {
            if (self.time !== 0) {
                return new Date(self.time * 1000).getHours()
            }
            else {
                return 12
            }
        },
        get minute() {
            if (self.time !== 0) {
                return new Date(self.time * 1000).getMinutes()
            }
            else {
                return 0
            }

        },
        isSubOpen(subdesc) {
            var isOpen = false
            self.subs.forEach(function (sub) {
                if (sub.iscomp && sub.desc === subdesc) {
                    isOpen = sub.open
                }
            });
            return isOpen
        },
        get json() {
            const subs = []
            self.subs.forEach(function (sub) {
                subs.push(sub.json)
            });
            const obj = {
                id: self.id,
                name: self.name,
                desc: self.desc,
                subs: subs, // for loop for subs
                g: self.grams,
                dens: self.dens,
                time: self.time,
                loc: self.loc.toJSON(),
                meta: self.meta.toJSON(),
                moist: self.moist,
                fat: self.fat.toJSON(),
                retn: self.retn.toJSON(),
                verified: self.verified,
                ver: self.ver,
                open: self.open,
                type: self.type,
            }
            return obj
        }
    }));
