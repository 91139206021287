
import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SButton} from "components/library/styledhtml/SButton";
import {SImg} from "components/library/styledhtml/SImg";
import {LoginSignup} from "components/library/common/auth/LoginSignup";

import {TextSection} from "components/pages/landing/DesktopLanding";
import {sectionInfo} from "components/pages/landing/info";
import {LandingHeader} from "components/pages/landing/LandingHeader";
import {Hp, Wp} from "utils/util";

import {imgs} from "utils/images";

import {GAevent} from "components/library/network/googleAnalytics";
import {headerHeight} from "components/library/nav/dimensions";

const mainBackground = 'g0'


function FutureHealth(props) {

    const color = props.color
    const textColor = props.textColor
    const font = props.font ? props.font : 'h3'
    return (
        <SDiv>
            <SDiv height={Hp(20)}/>
            <SDiv
                height={Hp(50)} width={Wp(0)}
                font={font} textAlign={'center'} textColor={textColor} manualWrap
                color={color}
            >
                {sectionInfo.title}
            </SDiv>
            <SDiv
                height={Hp(40)} width={Wp(0)}
                font={'b1'} textAlign={'center'} textColor={'b800'} manualWrap
                color={color}
            >
                {sectionInfo.title_sub}
            </SDiv>
            <SDiv height={Hp(10)}/>
        </SDiv>
    )
}

function SubSection(props) {

    const num = props.num
    const h = sectionInfo[`szm${num}`]
    var width = Wp(340)
    const height = width * h/340
    //var height = Hp(h)

    const subtext = sectionInfo[`sub${num}`]
    const gap = 8*subtext.split('\n').length

    return(
        <SDiv
            width={Wp(0)}
            valign={'start'} halign={'center'}
        >
            <SDiv height={Hp(24)}/>
            <TextSection mobile={true} type={1} text={sectionInfo[`h${num}`]} position={'auto'}/>
            <TextSection mobile={true} type={2} text={subtext} position={'auto'}/>
            <SDiv height={Hp(gap)} width={Wp(0)}/>
            <SImg src={imgs[sectionInfo[`src${num}`]]} width={width} height={height}/>
        </SDiv>
    )
}



export function MobileLanding(props) {

    const [showSignup, setShowSignup] = useState(false)

    var blm = props.blm

    const cardW = Wp(340)
    const cardH = cardW * 180 / 340

    const phoneW = Wp(575)
    const phoneH = phoneW * 600/575

    const processH = Hp(1080)
    const processW = processH * 420/1080

    const headingColor = blm ? 'black' : mainBackground
    const headingTextColor = blm ? 'white' : 'g600'

    function closeSignup() {
        setShowSignup(false)
    }

    function signup() {
        GAevent('User','Clicked Signup')
        setShowSignup(true)
    }

    return (
        <SDiv
            direction="column"
            width={Wp(0)}
            valign={'start'}
            color={mainBackground}
        >
            <SDiv height={headerHeight()} width={Wp(0)}/>
            <FutureHealth color={headingColor} textColor={headingTextColor}/>
            <SDiv
                direction="column"
                height={Hp(600)}
                width={Wp(0)}
                backgroundImage={imgs['landing_phone@2x.png']}
                backgroundSize={`${phoneW}px ${phoneH}px`}
                backgroundPosition={'top'}
                valign={'start'}
            >
            </SDiv>
            <SDiv height={Hp(24)}/>
            <TextSection mobile={true} type={1} text={sectionInfo[`h1`]} height={Hp(60)} width={Wp(0)}/>
            <SDiv height={Hp(24)}/>
            <TextSection mobile={true} type={2} text={sectionInfo[`sub1`]} height={Hp(60)} width={Wp(0)}/>
            <SDiv height={Hp(64)}/>
            <SDiv
                direction="column"
                height={Hp(1000)}
                width={Wp(0)}
                backgroundImage={imgs['landing_process@2x.png']}
                backgroundSize={`${processW}px ${processH}px`}
                backgroundPosition={'top'}
                valign={'start'}
            >
                <TextSection mobile={true} type={1} text={sectionInfo[`h2`]} top={Hp(320)}/>
                <TextSection mobile={true} type={2} text={sectionInfo[`sub2`]} top={Hp(320)}/>
                <TextSection mobile={true} type={1} text={sectionInfo[`h3`]} top={Hp(320+200)}/>
                <TextSection mobile={true} type={2} text={sectionInfo[`sub3`]} top={Hp(320+200)}/>
            </SDiv>
            <SImg src={imgs['landing_card001@2x.png']} width={cardW} height={cardH}/>
            <SImg src={imgs['landing_card002@2x.png']} width={cardW} height={cardH}/>
            <SImg src={imgs['landing_card003@2x.png']} width={cardW} height={cardH}/>
            <SubSection num={4}/>
            <SubSection num={5}/>
            <SubSection num={6}/>
            <SubSection num={7}/>
            <SubSection num={8}/>
            <SDiv height={Hp(200)}/>

            <SButton
                height={Hp(48)} width={Wp(327)}
                position={'fixed'}
                bottom={Hp(26)}
                onClick={() => signup()}
            >
                Sign up
            </SButton>
            <LandingHeader
                color={headingColor} textColor={headingTextColor}
            />
            <LoginSignup isOpen={showSignup} close={closeSignup} smstype={'signup'}/>
        </SDiv>
    )
}

