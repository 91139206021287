import React, {useEffect, useState} from "react";
import {Router} from "@reach/router";

import {HandSize} from "components/pages/personalization/HandSize";
import {HandShade} from "components/pages/personalization/HandShade";
import {GenderSelect} from "components/pages/personalization/GenderSelect";
import {SButton} from "components/library/styledhtml";
import {navigate} from "@reach/router";

import {HANDSHADE, HANDSIZE, SEXSEL} from "components/pages/personalization/paths";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {Profile} from "models/profile/userprofile";
import {Hp, Wp} from "utils/util";
import {SDiv} from "components/library/styledhtml";
import {MainHeader} from "components/library/nav/MainHeader";
import {Spinner} from "components/library/common/Spinner";
import {SelectButton} from "components/library/common/buttons/SelectButton";
import {VolumeEstimator} from "components/library/volume/VolumeEstimator";

export function EditPersonalization(props) {

    var sel
    if (props.selected === 'Shade' || props.selected || 'Size') {
        sel = props.selected
    }
    const [selected, setSelected] = useState(sel ?? 'Shade')
    const [saving, setSaving] = useState(false)
    const [volume, setVolume] = useState(240)

    function setNext() {
        if (selected === 'Shade') {setSelected('Size')}
        if (selected === 'Size') {setSelected('Tutorial')}
        if (selected === 'Tutorial') {setSelected('Size')}
        //setSaving(true)
    }

    // button height
    const bH = Hp(0.05)
    const subHeight = Hp(0) - Hp(0.09) - Hp(50) - 2*bH
    return(
        <SDiv>
            <MainHeader position={'relative'} showProfile={false}/>
            <SDiv height={Hp(10)}/>
            <SDiv>
                <SDiv height={Hp(20)} direction={'row'} halign={'between'}>
                    {saving && <SDiv width={Wp(100)}/>}
                    <SDiv>Personalize your hand for estimating volume</SDiv>
                    {saving && <SDiv width={Wp(100)} font={'b1'} textColor={'a800'}>saving...</SDiv>}
                </SDiv>
                <SDiv height={Hp(10)}/>
                <SDiv height={bH} direction={'row'} width={Wp(0.9)} halign={'around'}>
                    <SelectButton name={'Shade'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Size'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Tutorial'} selected={selected} setSelected={setSelected} height={bH}/>
                </SDiv>
                {selected === 'Shade' &&
                    <HandShade setNext={setNext}/>
                }
                {selected === 'Size' &&
                    <HandSize setNext={setNext}/>
                }
                {selected === 'Tutorial' &&
                    <SDiv>
                        <SDiv height={Hp(20)}/>
                        <SDiv height={Hp(50)} width={Wp(0)} manualWrap textAlign={'center'} textColor={'a800'}>
                            {`Estimate volume by moving slider\nmatch sphere or cylinder size\nto your personalized hand`}
                        </SDiv>
                        <VolumeEstimator height={Hp(300)} width={Wp(0)} volume={volume} setVolume={setVolume} setNext={setNext}/>
                        <SButton height={Hp(40)}
                                 position={'absolute'} bottom={Hp(20)}
                                 onClick={() => navigate(-1)}
                                 disabled={volume === 240}
                        >
                            Got it!
                        </SButton>
                    </SDiv>
                }

            </SDiv>

        </SDiv>
    )
}




export function Personalization(props) {

    return (
        <Router>
            <EditPersonalization path={'/'}/>
        </Router>
    )
}