import React from "react";
import {Hp, Wp} from "utils/util";
import {SButton} from "components/library/styledhtml/SButton";


export function MainButton(props) {

    const width = props.width ? props.width : Wp(310)
    const height = props.height ? props.height : Hp(48)
    const onClick = props.onClick
    const disabled = props.disabled
    const secondary = props.secondary

    return (

        <SButton
            height={height} width={width}
            disabled={disabled} onClick={() => onClick()}
            secondary={secondary}
        >
            {props.children}
        </SButton>
    )
}
