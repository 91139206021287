
import React, {useState, useEffect} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Keypad} from "components/library/keyboards/Keypad";
import {MainButton} from "components/library/common/buttons/MainButton";
import {Hp, Wp} from "utils/util";
import {navigate} from "@reach/router";

import {useAuthentication} from "api/api";
import {useIsMountedRef} from "utils/custom_hooks";


function DigitBox(props) {

    const width = Wp(40)
    const height = Hp(36)

    return (
        <SDiv
            height={height} width={width}
            font={'h3'} textColor={'g900'}
            borderStyle={'none none solid none'}
            borderColor={props.number ? 'brand' : 'g200'}
        >
            {props.number}
        </SDiv>
    )
}


export function TextValidate(props) {

    const isMountedRef = useIsMountedRef();

    var height = props.height
    var width = props.width
    var setVerify = props.setVerify
    const onSuccess = props.onSuccess

    const number = props.number
    const [code, setCode] = useState([])
    const [nav, setNav] = useState((false))
    const [returnValidate, callValidate] = useAuthentication('validate_sms_code', true);
    const [errormsg, setErrorMsg] = useState('')
    const [disable, setDisable] = useState(false)

    // navigate if number is not passed, need to use the useEffect method as rerender does not occur
    // on page navigated to otherwise.
    if (!number && !nav) {
        setNav(true)
    }
    useEffect(() => {
        if (nav) {navigate('/')}
    }, [nav])


    useEffect(() => {
        if (returnValidate.data) {
            if (returnValidate.data['success'] === true) {
                if (onSuccess) onSuccess(returnValidate.data)
            }
            else {
                setErrorMsg('Something went wrong, please try again')
                setTimeout(function () {
                    if (isMountedRef) {
                        setErrorMsg('')
                        if (setVerify) setVerify(false)
                    }
                }, 4000);
            }
        }
    }, [returnValidate.counter])

    function pressContinue() {
        callValidate({'phone': number, 'code': code.join('')})
        setDisable(true)
    }

    function onNumber(num) {
        if (num.toLowerCase() === 'delete') {
            if (code.length > 0) {setCode(code.slice(0, code.length - 1))}
        }
        else if (code.length < 4) {setCode(number => [...number, num]);}
    }

    function pressBack() {
        navigate(-1)
    }

    var dispnumber = 'xxx-xxx-xxx'
    if (number && number.length === 10) {
        dispnumber = number.slice(0,3) + '-' + number.slice(3,6) + '-' + number.slice(6,10)
    }
    const smstext = `Please enter the code sent\nto your phone ${dispnumber}\n(check text messages)`

    return (
        <SDiv
            direction="column"
            height={height}
            width={width}
            valign={'end'}
        >
            <SDiv height={Hp(8)}/>
            <SDiv
                height={Hp(48)} width={Wp(280)}
                font={'b2'} textAlign={'center'} textColor={'g400'} manualWrap
            >
                {smstext}
            </SDiv>
            <SDiv height={Hp(10)}/>
            <SDiv
                direction={'row'}
                height={Hp(40)} width={Wp(260)}
            >
                <DigitBox  number={code[0]}/>
                <SDiv width={Wp(20)}/>
                <DigitBox  number={code[1]}/>
                <SDiv width={Wp(20)}/>
                <DigitBox  number={code[2]}/>
                <SDiv width={Wp(20)}/>
                <DigitBox  number={code[3]}/>
            </SDiv>
            <SDiv height={Hp(12)}/>
            <MainButton
                disabled={(code.length !== 4) || returnValidate.loading || disable}
                onClick={() => pressContinue()}
            >
                Validate
            </MainButton>
            {errormsg &&
            <SDiv textColor={'danger'} width={Wp(300)} textAlign={'center'}>
                {errormsg}
            </SDiv>
            }
            <Keypad
                fixed={true}
                onPress={onNumber} height={Hp(0.33)} width={width ? width : Wp(0)}
            />
        </SDiv>
    )
}
