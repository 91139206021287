
import React, {useEffect, useState} from "react";
import DateTime from "luxon/src/datetime";

import {Hp, Wp} from "utils/util";
import {SDiv, SButton} from "components/library/styledhtml";
import {SMenu} from "components/library/basic/SMenu";
import {useOutsideAlerter} from "utils/custom_hooks";

const MONTHS = {
    'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5,
    'Jun': 6, 'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
}

function DaysDisplay(props) {

    const width = props.width
    const height = props.height

    const dayWidth = width / 7

    return (
        <SDiv
            direction={'row'}
            width={width} height={height}
        >
            <SDiv width={dayWidth} height={height}>{'Mon'}</SDiv>
            <SDiv width={dayWidth} height={height}>{'Tue'}</SDiv>
            <SDiv width={dayWidth} height={height}>{'Wed'}</SDiv>
            <SDiv width={dayWidth} height={height}>{'Thu'}</SDiv>
            <SDiv width={dayWidth} height={height}>{'Fri'}</SDiv>
            <SDiv width={dayWidth} height={height}>{'Sat'}</SDiv>
            <SDiv width={dayWidth} height={height}>{'Sun'}</SDiv>
        </SDiv>
    )
}

function CalendarDay(props) {

    const width = props.width
    const height = props.height
    const monthObj = props.monthObj
    const selectedObj = props.selectedObj
    const secondary = props.secondary
    const onClick = props.onClick
    const highlights = props.highlights ?? new Set()
    highlights.add('2021-02-01')
    highlights.add('2021-02-11')

    var now = DateTime.local();
    var isToday = now.year === monthObj.year && now.month === monthObj.month && now.day === monthObj.day;
    var isSelected = props.isSelected ?? false
    //var isSelected = selectedObj.year === monthObj.year && selectedObj.month === monthObj.month && selectedObj.day === monthObj.day;

    function inHighlights() {
        const m = `${monthObj.month}`
        const d = `${monthObj.day}`
        const txt = `${monthObj.year}-${m.padStart(2, 0)}-${d.padStart(2,0)}`
        return highlights.has(txt)
    }

    const smallBoxSz = 0.25*height
    return (
        <SDiv
            width={width} height={height}
            borderColor={'g200'} borderWidth={'1px'}
            color={inHighlights() ? 'a200' : isToday ? 'a100' : null}
            onClick={() => onClick ? onClick(monthObj) : null}
        >
            <SDiv height={smallBoxSz}/>
            <SDiv
                textColor={secondary ? 'g200' : 'g800'}
                height={2*smallBoxSz}
            >
                {monthObj.day}
            </SDiv>
            <SDiv
                direction={'row'} halign={'around'}
                width={width} height={smallBoxSz}
            />
        </SDiv>
    )
}

function CalendarRow(props) {

    const width = props.width
    const height = props.height
    const days = props.days
    const row = props.row
    const onClick = props.onClick
    const monthObj = props.monthObj
    const dayWidth = width / 7
    const highlights = props.highlights

    const dispArray = []
    var day;
    var i = 0
    for (day of days) {
        var secondary = false
        var newMonthObj = monthObj.minus({month: 0})
        if (row === 1 && day > 16) {
            // first row can actually never be less than 21 or so
            secondary = true
            newMonthObj = monthObj.minus({month: 1})
        }
        else if (row >= 4 && day < 16) {
            // 16 is smallest number to possibly start 4th row
            secondary = true
            newMonthObj = monthObj.plus({month: 1})
        }

        dispArray.push(
            <CalendarDay
                key={i} width={dayWidth} height={height} secondary={secondary}
                monthObj={newMonthObj.set({day: day})}
                selectedObj={monthObj}
                highlights={highlights}
                onClick={onClick}
            />
        )
        i += 1
    }

    return (
        <SDiv
            direction={'row'}
            width={width} height={height}
        >
            {dispArray}
        </SDiv>
    )
}

function CalendarDisplay(props) {

    const width = props.width
    const height = props.height
    const monthObj = props.monthObj
    const onClick = props.onClick
    const highlights = props.highlights

    const tmpDt = monthObj.set({day: 1})
    const priorMonth = monthObj.minus({months: 1})
    //const nextMonth = monthObj.plus({months: 1})

    const dispArray = []

    var stNum = 1
    var nextNum = 0
    for (let row = 1; row <= 6; row++) {

        const stWeekday = row === 1 ? tmpDt.weekday : 1
        var days = []
        if (row === 1 && stWeekday !== 1) {
            // starting weekday at Monday rather than Sunday, tuesday is 2 so that is 0 delta
            var delta = stWeekday - 2
            if (stWeekday === 0) {delta = 6}

            for (let j = priorMonth.daysInMonth - delta; j <= priorMonth.daysInMonth; j++) {
                days.push(j)
            }
            while (days.length < 7) {
                days.push(stNum)
                stNum += 1
            }
        } else {
            for (let d = 1; d <= 7; d++) {
                if (stNum <= monthObj.daysInMonth) {
                    days.push(stNum)
                    stNum += 1
                }
            }
        }

        while (days.length < 7) {
            nextNum += 1
            days.push(nextNum)
        }

        dispArray.push(
            <CalendarRow
                key={row} width={width} height={height / 6}
                days={days} monthObj={monthObj} row={row}
                highlights={highlights}
                onClick={onClick}
            />
        )
    }

    return (
        <SDiv
            width={width}
        >
            {dispArray}
        </SDiv>
    )

}

function ArrowButton(props) {

    return (
        <SDiv
            width={Wp(26)} color={'b500'} textColor={'g0'}
            boxShadow={'normal'} borderRadius={6}
            onClick={() => props.onClick()}
        >
            {props.text}
        </SDiv>

    )
}

export function SCalendar(props) {


    const width = props.width ?? Wp(0.75)
    const height = props.height ?? Hp(260)
    const onClick = props.onClick
    const close = props.close

    const outsideRef = useOutsideAlerter(close ? close : null)

    const now = DateTime.local()
    const initYear = props.initYear ?? now.year
    const initMonth = props.initMonth ?? now.month
    const initDay = props.initDay ?? now.day
    const initTimezone = props.initTimezone ?? 'America/Los_Angeles'
    const highlights = props.highlights ?? new Set()

    const datetime = props.datetime ??  DateTime.fromObject({year: initYear, month: initMonth, day: initDay, zone: initTimezone})

    const [year, setYear] = useState(datetime.year)
    const [month, setMonth] = useState(datetime.month)
    const [timezone, setTimezone] = useState(datetime.zoneName)
    const [dtObj, setDtObj] = useState(datetime)

    const toggleHeight = 0.15*height
    const daysHeight = 0.15*height
    const calendarHeight = 0.7*height

    useEffect(() => {
        setDtObj(DateTime.fromObject({year: year, month: month, zone: timezone}))
    }, [year, month])

    function dates() {
        const curDate = DateTime.fromObject({zone: timezone})
        return [
            curDate.year - 5,
            curDate.year - 4,
            curDate.year - 3,
            curDate.year - 2,
            curDate.year - 1,
            curDate.year,
            curDate.year + 1,
        ]
    }

    function updateMonth(month) {
        setMonth(MONTHS[month])
    }

    function decrementMonth() {
        if (month === 1) {
            setMonth(12)
            setYear(year - 1)
        } else {
            setMonth(month - 1)
        }
    }

    function incrementMonth() {
        if (month === 12) {
            setMonth(1)
            setYear(year + 1)
        } else {
            setMonth(month + 1)
        }
    }

    return (
        <SDiv ref={outsideRef}>
            <SDiv direction={'row'} width={width} height={toggleHeight} halign={'around'}>
                <SDiv direction={'row'} width={0.5 * width} halign={'start'}>
                    <ArrowButton onClick={decrementMonth} text={'<'}/>
                    <SMenu
                        items={Object.keys(MONTHS)} direction={'down'}
                        label={dtObj.monthShort} onSelect={updateMonth}
                        width={Wp(66)}
                    />
                    <ArrowButton onClick={incrementMonth} text={'>'}/>
                </SDiv>
                <SDiv direction={'row'} width={0.5 * width} halign={'end'}>
                    <ArrowButton onClick={() => setYear(year - 1)} text={'<'}/>
                    <SMenu
                        items={dates()} direction={'down'}
                        label={dtObj.year} onSelect={setYear}
                        width={Wp(66)}
                    />
                    <ArrowButton onClick={() => setYear(year + 1)} text={'>'}/>
                </SDiv>
            </SDiv>
            <DaysDisplay width={width} height={daysHeight}/>
            <CalendarDisplay width={width} height={calendarHeight} monthObj={dtObj} onClick={onClick} highlights={highlights}/>
            <SDiv height={Hp(10)}/>
            <SButton width={Wp(160)} height={Hp(40)} secondary={false} onClick={() => props.close ? props.close() : null}>Close Calendar</SButton>
        </SDiv>
    )
}