import React, {useState} from "react";
import {navigate} from "@reach/router";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Hpa, Wpa, Wpd} from "utils/util";
import {SImg} from "components/library/styledhtml/SImg";
import {imgs} from "utils/images";
import {LandingMenu} from "components/library/nav/LandingMenu";
import {LoginSignup} from "components/library/common/auth/LoginSignup";
import {apiValidateToken, isTokenValid} from "models/security/token";

import {APP, HOME} from "components/paths";
import {headerHeight} from "components/library/nav/dimensions";

const mainBackground = 'g0'


function Simpa(props) {

    const height = props.height
    const textColor = props.textColor
    var space = Wpa(12)
    const size = 0.6* height

    function home() {
        navigate('/')
    }

    return(
        <SDiv direction={'row'} onClick={() => home()}>
            <SDiv
                height={size} width={size}
                borderRadius={100}
                boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'}
            >
                <SImg height={size} width={size} src={imgs['simpa_logo_blue@2x.png']}/>
            </SDiv>
            <SDiv width={space}/>
            <SDiv height={height} font='h3l' textColor={textColor}>
                Simpa
            </SDiv>
        </SDiv>
    )

}


export function HeaderSpacing(props) {
    const height = props.height ? props.height : headerHeight()
    return (
        <SDiv height={height}/>
    )
}


export function LandingHeader(props) {

    const color = props.color ? props.color : mainBackground
    const textColor = props.textColor
    const height = headerHeight()
    const position = props.position ? props.position : 'fixed'
    const [showLogin, setShowLogin] = useState(false)

    var sideSpace = Wpa(0.04)
    function clickLogin() {
        apiValidateToken()
        if (isTokenValid(['user'])) {
            navigate(`/${APP}/${HOME}`)
        }
        else {
            setShowLogin(true)
        }
    }

    function closeLogin() {
        setShowLogin(false)
    }

    return (
        <SDiv
            direction={'row'} halign={'between'} valign={'center'}
            height={height} width={Wpd(0)}
            color={color} position={position}
            boxShadow={'normal'}
        >
            <SDiv direction={'row'} halign={'start'}>
                <SDiv width={sideSpace}/>
                <LandingMenu/>
            </SDiv>
            <Simpa height={height} textColor={textColor}/>
            <SDiv direction={'row'} halign={'end'}>
                <SDiv
                    height={Hpa(20)} width={Wpa(40)}
                    font={'button1'} textColor={'b500'}
                    onClick={() => clickLogin()}
                >
                    Login
                </SDiv>
                <SDiv width={sideSpace}/>
            </SDiv>
            <LoginSignup isOpen={showLogin} close={closeLogin} isPassword/>
        </SDiv>
    )
}
