import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './components/App';
require('typeface-roboto')


function renderApp() {
    ReactDOM.render(<App />, document.getElementById('root'));
}

renderApp();

if (module.hot) {
    // new components
    //module.hot.accept(['./components/App'], () => {
    //    renderApp()
    //});

    /*
    // model changes of MST
    module.hot.accept(['./models/token'], () => {
        // Need to have access to original states
        //const snapshot = getSnapshot(jwtState);
        //jwtState = Token.create(snapshot);
        renderApp()
    })
    */

}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
