import React, {useState, useEffect, useContext, useRef} from "react";
import {observer} from "mobx-react-lite";
import {navigate} from "@reach/router";

import {Hp, Wp} from "utils/util";
import {SDiv, SButton, STextArea} from "components/library/styledhtml";
import {SModal} from "components/library/basic/SModal";
import {SVerticalScroll} from "components/library/basic/SVerticalScroll";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";

import {Spinner} from "components/library/common/Spinner";
import {Send} from "grommet-icons";


import {GlobalStoreContext} from "models/rootStore";
import {stheme} from "themes/theme";
import {ConCompView} from "components/library/intake/viewIntake/ConCompView";

//const ChatRow = React.forwardRef((props, ref) => {

const ChatRow = observer((props) => {

    const width = props.width
    const message = props.message
    const lastDirection = props.lastDirection
    const scrollRef = props.scrollRef

    var vpad = Hp(4)
    var vmarginTop = Hp(4)
    var vmarginBottom = Hp(4)
    var hpad = Wp(8)
    var hmargin = Wp(16)
    if (message.direction === lastDirection) {
        vmarginTop = 0
        vmarginBottom = 0
    }

    const boxWidthFrac = 0.8
    if (message.direction === 'in') {
        return (
            <SDiv
                direction='row' width={width} halign={'end'}
                ref={scrollRef}
            >
                <SDiv width={(1-boxWidthFrac)*width}/>
                <SDiv
                    maxWidth={boxWidthFrac*width}
                    borderRadius={14}
                    color={'b800'}
                    textColor={'g0'}
                    font={'b2'}
                    paddingLeft={hpad} paddingRight={hpad} paddingTop={vpad} paddingBottom={vpad}
                    marginLeft={hmargin} marginRight={hmargin}
                    marginTop={vmarginTop} marginBottom={vmarginBottom}
                >
                    {message.text}
                </SDiv>
            </SDiv>
        )
    }
    return (
        <SDiv
            direction='row' width={width} halign={'start'}
            ref={scrollRef}
        >
            <SDiv
                maxWidth={boxWidthFrac*width}
                borderRadius={14}
                color={'g50'}
                font={'b2'}
                paddingLeft={hpad} paddingRight={hpad} paddingTop={vpad} paddingBottom={vpad}
                marginLeft={hmargin} marginRight={hmargin}
                marginTop={vmarginTop} marginBottom={vmarginBottom}
            >
                {message.text}
                {/*<MyStyledText textAlign='start' size={'16px'}>{message.text}</MyStyledText>*/}
            </SDiv>
            <SDiv width={(1-boxWidthFrac)*width}/>
        </SDiv>
    )
})


export const ChatMessages = observer((props) => {

    const width = props.width ? props.width : Wp(0)
    const height = props.height ? props.height : Hp(200)
    const lastRef = React.createRef()

    const messages = props.conversation.getMessages
    if (!messages.length) {
        return (
            <SDiv height={height} width={width} boxShadow={'normal'}>
                <Spinner/>

            </SDiv>
        )
    }

    function getMessages(lastScrollRef) {
        const m = []
        let tmpRef = null
        for (let i=0; i<messages.length; i++) {
            if (i === messages.length -1) {
                tmpRef = lastScrollRef
            }
            m.push(
                <ChatRow
                    lastDirection={i>0 ? messages[i-1].direction : ''}
                    key={i} message={messages[i]} width={width}
                    scrollRef={tmpRef}
                />
            )
        }
        return m
    }

    const messageRows = getMessages(lastRef)
    return (
        <SVerticalScroll
            height={height} width={width} boxShadow={'normal'}
            //scrollToBottom={true}
            scrollref={lastRef}
        >
            {messageRows}
        </SVerticalScroll>
    )

})


export const ChatBotModal = observer((props) => {

    const width = props.width ? props.width : Wp(0)
    const height = props.height ? props.height : Hp(0.7)

    const [sendChat, callSendChat] = useApi('SimpaService', 'receive_chatbot', true);

    const onClose = props.onClose
    const gstore = useContext(GlobalStoreContext)
    const conversation = gstore.getConversation()
    const [value, setValue] = useState('')

    function onValue(value) {
        value = value.replace(/return/g, '\n')
        value = value.replace(/enter/g, '\n')
        setValue(value)

    }

    function closeChat() {
        gstore.conversation.clear()
        onClose()
    }

    useEffect(() => {
        if (sendChat.hasData) {
            console.log(sendChat.data)
        }
    }, [sendChat.counter])

    function send() {
        if (value) {
            const message = {'text': value}
            const params = {
                token: rawToken(),
                message: message,
            }
            callSendChat(params)
            setValue('')
        }
    }

    return (
        <SModal
            topGap={Hp(0.02)} sideFrac={0}
            onClose={() => closeChat()} title={'Simpa Chat'}
            overflow={'hidden'}
        >
            <ChatMessages
                width={width} height={height}
                conversation={conversation}
            />
            <SDiv
                direction={'row'}
                halign={'start'}
                width={width}
            >
                <SDiv width={Hp(12)}/>
                <STextArea
                    width={0.8*width} height={Hp(40)}
                    maxHeight={Hp(80)}
                    font={'b2s'} padding={'12px 12px'}
                    borderColor={'g100'} borderRadius={10}
                    value={value} onChange={(e) => onValue(e.target.value)}

                />
                <Send
                    color={stheme.color.brands.b800}
                    onClick={() => send()}
                />
            </SDiv>
        </SModal>
    )
})



export function ChatBotButton(props) {

    const [open, setOpen] = useState(false)

    return(
        <SDiv>
            <SButton
                width={Wp(0.7)}
                onClick={() => setOpen(true)}
            >
                Chat
            </SButton>
            {open && <ChatBotModal onClose={() => setOpen(false)}/>}
        </SDiv>
    )
}
