/*
Interesting idea to handle transitions
https://codesandbox.io/embed/jp1wr1867w
 */
import React, {useState, useEffect, useContext} from "react";
import {navigate} from "@reach/router";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {SInput} from "components/library/styledhtml/SInput";
import {Hp, Wp} from "utils/util";
import {SModal} from "components/library/basic/SModal";
import {MainButton} from "components/library/common/buttons/MainButton";

import {SmsPhoneVerifyInput} from "components/library/common/sms/SmsPhoneVerifyInput";
import {TextValidate} from "components/library/common/sms/TextValidate";
import {setNewToken} from "models/security/token";

import {icons} from "utils/images";
import {SArrow} from "components/library/basic/SArrow";
import {getPhoneDisplay} from "components/library/inputs/PhoneInput";
import {Close} from "grommet-icons/es6";
import {GlobalStoreContext} from "models/rootStore";
import {DimensionsContext} from "models/dimensionsStore";
import {APP, ONBOARD, HOME} from "components/paths";
import {useAuthentication} from "api/api";


function LoginSelect(props) {

    const height = props.height
    const width = props.width
    const setIsSms = props.setIsSms
    const isSms = props.isSms

    return (
        <SDiv
            height={height} width={width}
            direction={'row'}
        >
            <SDiv height={Hp(24)} width={Wp(100)}
                  color={isSms ? 'b200' : null} textColor={isSms ? 'b800' : 'g400'}
                  borderRadius={Hp(4)}
                  onClick={() => setIsSms(true)}
            >
                SMS code
            </SDiv>
            <SDiv height={Hp(24)} width={Wp(8)}/>
            <SDiv height={Hp(24)} width={Wp(100)}
                  color={!isSms ? 'b200' : null} textColor={!isSms ? 'b800' : 'g400'}
                  borderRadius={Hp(4)}
                  onClick={() => setIsSms(false)}
            >
                Password
            </SDiv>

        </SDiv>

    )
}


function EnterPassword(props) {

    const [baseH, setBaseH] = useState(Hp(10))
    const [baseW, setBaseW] = useState(Wp(20))
    const [fullW, setFullW] = useState(Hp(0))

    const setIsSms = props.setIsSms
    const number = props.number
    const setNumber = props.setNumber

    const [password, setPassword] = useState('')
    const [ptype, setPtype] = useState('password')
    const [show, setShow] = useState('show')
    const [failMessage, setFailMessage] = useState('')
    const [phoneFocus, setPhoneFocus] = useState(false)
    const [passFocus, setPassFocus] = useState(false)
    const [valid, setValid] = useState(false)
    const [login, callLogin] = useAuthentication('login', true);


    useEffect(() => {

        if (login && login.data) {
            if ((login.data.success !== true) || !login.data.jwt) {
                setFailMessage('login failed')
            } else {
                setNewToken(login.data.jwt)
                navigate(`/${APP}/${HOME}`)
            }
        }
    }, [login.counter])

    function pressLogin() {
        callLogin({'email_phone': '1' + number, 'password': password})
    }

    useEffect(() => {
        if (number && number.length === 10 && password.length > 8) {
            setValid(true)
        }
        else {setValid(false)}

    })

    function onNumber(e) {
        var newnumber = e.target.value.replace(/\W/g, '')
        newnumber = newnumber.replace(/\s/g, '')
        newnumber = newnumber.replace(/\D/g, '')
        if (e.target.value.length === 15 && newnumber.length > 0) {
            newnumber = newnumber.substring(0, newnumber.length-1)
        }
        if (newnumber.length <= 10){
            setNumber(newnumber)
        }
    }

    function onPassword(e) {
        setPassword(e.target.value)
    }
    function switchShow() {
        if (ptype === 'password') {
            setPtype('text')
            setShow('hide')
        }
        else {
            setPtype('password')
            setShow('show')
        }
    }

    function onpasswordFocus() {
        setPassFocus(true)
        setPhoneFocus(false)
    }

    function onphoneFocus() {
        setPassFocus(false)
        setPhoneFocus(true)
    }

    return (
        <SDiv>
            <SDiv
                width={14*baseW} height={3*baseH} halign={'start'}
                direction={'row'}
                borderStyle={'none none solid none'}
                borderColor={phoneFocus ? 'b500' : 'g200'}
                paddingLeft={10}
            >
                <SImg paddingLeft={20} height={1.5*baseH} width={1.5*baseW} src={icons['flag_US.svg']}/>
                <SArrow direction={'down'} size={5}/>
                <SDiv
                    height={3*baseH} width={40}
                    font={'b2'} textColor={'g900'}
                    halign={'center'}
                >
                    +1
                </SDiv>
                <SInput
                    name={'username'}
                    width={11*baseW}
                    font={'b2'}
                    type={'tel'} placeholder={'(___) ___-____'} pattern={'\d*'}
                    value={getPhoneDisplay(number)}
                    borderStyle={'none'}
                    fillOff={true}
                    onChange={(e) => onNumber(e)}
                    onFocus={(e) => onphoneFocus()}
                    onBlur={(e) => setPhoneFocus(false)}
                />
                <SDiv width={2*baseW}/>
            </SDiv>
            <SDiv height={2*baseH}/>
            <SDiv
                width={14*baseW} height={3*baseH} halign={'start'}
                direction={'row'}
                borderStyle={'none none solid none'}
                borderColor={passFocus ? 'b500' : 'g200'}
            >
                <SInput
                    name={'password'}
                    width={14*baseW}
                    type={ptype} placeholder={'Password'}
                    value={password}
                    borderStyle={'none'}
                    font={'b2'}
                    onChange={(e) => onPassword(e)}
                    fillOff={true}
                    onFocus={(e) => onpasswordFocus()}
                    onBlur={(e) => setPassFocus(false)}
                />
                <SDiv width={0.5*baseW}/>
                <SDiv
                    width={1.5*baseW} font={'b1'}
                    textColor={'b500'}
                    onClick={() => switchShow()}
                >
                    {show}
                </SDiv>
            </SDiv>
            <SDiv height={2.4*baseH}/>
            <SDiv
                direction={'row'}
                height={1.6*baseH} width={fullW}
            >
                <SDiv font={'b3'} textColor={'g900'}>Don't have a password? </SDiv>
                <SDiv width={4}/>
                <SDiv
                    font={'b3'} textColor={'b500'}
                    onClick={() => setIsSms(true)}
                >
                    Login with SMS code.
                </SDiv>
            </SDiv>
            <SDiv height={1.5*baseH}/>
            <MainButton
                width={15*baseW} height={4.8*baseH}
                type={'submit'}
                onClick={() => pressLogin()}
                disabled={!valid}
            >
                Login
            </MainButton>
            {failMessage && <SDiv textColor={'danger'}>{failMessage}</SDiv>}
        </SDiv>
    )
}


export function LoginSignup(props) {

    const gstore = useContext(GlobalStoreContext)

    var height = Hp(0)
    var width = Wp(0)
    const isOpen = props.isOpen
    const tmpClose = props.close
    const smstype = props.smstype ? props.smstype : 'login'

    const [isSms, setIsSms] = useState(!props.isPassword)
    const [verify, setVerify] = useState(false)
    const [number, setNumber] = useState(gstore.lastLoginNumber)

    const topGap = Hp(0.1)
    const bottomGap = Hp(0.3)
    const leftGap = Wp(0.05)
    const rightGap = Wp(0.05)


    function onSuccess(data) {
        if (data.jwt) {setNewToken(data.jwt)}
        if (smstype === 'login') {
            navigate(`/${APP}/${HOME}`)
        }
        else (
            navigate(`/${APP}/${ONBOARD}`)
        )
    }

    function close() {
        if (tmpClose) {tmpClose()}
    }
    if (!isOpen) {
        return (null)
    }
    const modalWidth = width - leftGap - rightGap
    const modalHeight = height - topGap - bottomGap
    const title = verify ? 'Validate code' : smstype === 'login' ? 'Login' : 'Sign up'

    return (
        <SModal
            topGap={topGap} bottomGap={bottomGap} leftGap={leftGap} rightGap={rightGap}
            modalWidth={modalWidth} modalHeight={modalHeight}
            onClose={close} title={title}
        >
            {/*
            <SDiv font={'h4'}>
                {title}
            </SDiv>
            */}
            {(verify && smstype === 'login') && <SDiv height={Hp(56)}/>}
            {
                (smstype === 'signup') &&
                <SDiv height={Hp(56)}
                      textAlign={'center'} manualWrap={true} textColor='b800'
                >
                    {'Simpa helps you with your health\nby texting with you.'}
                </SDiv>
            }
            {
                (!verify && smstype === 'login') &&
                <LoginSelect
                    height={Hp(56)}
                    isSms={isSms} setIsSms={setIsSms}
                />
            }
            {(isSms && !verify) &&
            <SmsPhoneVerifyInput smsType={smstype} setVerify={setVerify} number={number} setNumber={setNumber}/>
            }
            {(!isSms && !verify) &&
            <EnterPassword setIsSms={setIsSms} number={number} setNumber={setNumber}/>
            }
            {verify &&
            <TextValidate width={modalWidth} number={number} setVerify={setVerify} onSuccess={onSuccess}/>
            }
        </SModal>
    )
}

