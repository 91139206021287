
import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SButton} from "components/library/styledhtml/SButton";
import {Hpa, Wpa} from "utils/util";
import {LandingHeader, HeaderSpacing} from "components/pages/landing/LandingHeader";
import {getDMdimensions} from "utils/util";


const blmtext = `
The injustices that have been imparted upon the black community of the United States is unconscionable.   At least 246 years of ‘legal’ slavery, at least 99 years of ‘legal’ segregation/injustice/racism and a continued 76+ years of systemic racism, violence and inequalities.  

We at Simpa, not only stand in solidarity with the black community, but are working every day to build a service that helps empower the black community.  Empowering black lives in their own health is a starting point.   Racism is a public health issue.   Black lives are disproportionately and negatively impacted in health due to systemic inequalities in healthcare and society.

The lack of deployed resources to the black community are part of the problem, and delivering the resources required to live healthy happy lives (that others are afforded) is essential.   Simpa is finding ways to deploy the power of information and technology to empower individuals and advocate for their goals.   Simpa is attempting to equalize our healthcare system for all humans.

We recognize that black lives have a different experiential context of life than others in the United States.  We strive to ensure Simpa respects those experiences and delivers solutions to individuals that they want instead of what someone else thinks they should have.

- Simpa Team
`

const sorentext = `
I helped create Simpa to empower others within their own lives, but how can I possibly know how to do that?   

While I have experienced a large breadth of human emotions like happiness, pain, suffering, loss, and love, I have experienced them in my own unique journey.   I understand the biases of experience that get imprinted unconsciously within me.   

As a white male co-founder, I recognize that the set of experiences I have had in my life are not the same as for so many others.   Systemic racism against African Americans and the black community exists and pervades the fabric of every sector of the United States, from police and justice to healthcare and education, none of which I experience.    

Women have the right to equality but have not received it, from worker’s rights to reproductive rights, none of which I experience.   

LGBTQIA individuals have been stereotyped and made to feel unwelcome in so many factors of life, none of which I experience.    

Being who I am, growing up in the United States, means I have not had so many of the unjust experiences others live with daily.   The awareness that I nor anyone else could possibly understand the life of all others on the planet is at the core of the design of Simpa.

My father received a purple heart and bronze star fighting on the front-lines of the Battle of the Bulge in WWII.  One of his consistent messages to me was the injustices imparted upon black Americans.  He engrained in me the fact that both himself and his fellow black soldiers were sent to die for America overseas, but when they returned they could not go to the same bathroom.  He challenged me to never be complacent, have deep awareness, and take action on the injustices the black community faces.
 
While we can never walk in another’s shoes, my objective is to listen, learn and empathize.  As a human I have experienced loss and pain, so when someone tells me about loss and pain, maybe I can tap those feelings to at least understand how difficult that moment is.   Through empathy we can dismantle the feeling of disconnect between ourselves and other humans to understand we are the same while respecting our differences.

I believe in living the 'platinum' rule:  do unto others as they would have done unto them.   This requires trying to learn and understand others first.   I hope Simpa will be able to do the same by empowering, enabling, and helping all humans trying to achieve their unique goals, without bias, and without judgment.

- Soren Solari (co-Founder)
`

const miketext = `
I helped create Simpa to empower others within their own lives, but how can I possibly know how to do that?” 

As a African American Founder, I have faced many injustices and tragedies in my life. As a brother to  police officers, and grandson to many that have served this nation from nurses, to the battle field. I have continued to fight for the unheard, or marginalized people. Systemic Racism isn’t only a black and white problem. It’s a problem that has plagued this entire world for hundreds of years. At Simpa, it’s our responsibility to design for the human. As a African American, it’s my job to make sure that we execute at every level for everyone and not just some. 

Everyday that I wake up is an opportunity to change the conversation that is spoken about my community to the world.

Everyday I wake up, I have an opportunity to prove to the world that we as a people can join together to build, to laugh, and to truly be who we are as humans. When I was younger, I had identity issues. I was told by America that just being me wasn’t good enough, and that I had to be another way or this type of way. As I grew older, the real me didn’t want to sit in the back seat any longer. I am an African born in America, and have dealt with emotional, physical, and intentional racism, and classism.  

I believe we all can do our part in making this world a place for everyone to live, prosper, and build together. 

My mother being a pastor for 40 years, and a dietician for 38 has instilled in me my roots, and my Father being the classic version of an Entrepreneur has created in me my willingness to adapt to any environment. 

These are a part of my Super powers. What are yours? 

I stand here to tear down the walls of systemic racism, the injustices of our judicial system, and the constant harassment of my black community. 

I stand here to tear down the walls of mental depression, and anxiety injected into the black community through manipulation.

I stand here to fight the battle of more diversity that will lead to inclusion in emerging technology fields across our nation and the world. 

- Michael McNair (co-Founder)
`

const GEN = 'General'
const MIKE = 'Michael'
const SOREN = 'Soren'

export function Blm(props) {

    var [width, height] = getDMdimensions()
    const [active, setActive] = useState(GEN)


    var textWidth = Wpa(0.9)
    if (window.innerWidth > window.innerHeight) {
        textWidth = Wpa(0.6)
    }

    return (
        <SDiv width={width} color={'black'} valign={'start'}>
            <HeaderSpacing/>
            <SDiv height={Hpa(20)}/>
            <SDiv font={'h2'} textColor={'white'} width={width} height={Hpa(60)}>
                #BLACKLIVESMATTER
            </SDiv>
            <SDiv font={'h5'} textColor={'g50'}>
                Statements
            </SDiv>
            <SDiv height={Hpa(20)}/>
            {/*
            <SDiv direction={'row'} width={textWidth} halign={'between'}>
                <SButton height={Hpa(30)} secondary={active !== MIKE} onClick={() => setActive(MIKE)}>{MIKE}</SButton>
                <SButton height={Hpa(30)} secondary={active !== GEN} onClick={() => setActive(GEN)}>{GEN}</SButton>
                <SButton height={Hpa(30)} secondary={active !== SOREN} onClick={() => setActive(SOREN)}>{SOREN}</SButton>
            </SDiv>
            */}
            {active === GEN &&
                <SDiv font={'b1'} textColor={'white'} width={textWidth} manualWrap textAlign={'start'}>
                    {blmtext}
                </SDiv>
            }
            {/*
            {active === MIKE &&
            <SDiv font={'b1'} textColor={'white'} width={textWidth} manualWrap textAlign={'start'}>
                {miketext}
            </SDiv>
            }
            {active === SOREN &&
            <SDiv font={'b1'} textColor={'white'} width={textWidth} manualWrap textAlign={'start'}>
                {sorentext}
            </SDiv>
            }
            */}
            <LandingHeader color={'black'} textColor={'white'}/>
            <SDiv width={Wpa(0)} height={Hpa(0.5)}/>
        </SDiv>

    )
}