
export const APP = 'app'
export const SIGNUP = 'signup'
export const LOGIN = 'login'
export const ONBOARD = 'onboarding'
export const HOME = 'home'
export const RECIPE = 'recipes'
export const INSIGHT = 'insights'
export const DATA = 'mydata'
export const GOAL = 'goals'
export const ANALYSIS = 'analysis'
export const SUGGEST = 'suggestions'
export const QUEST = 'quests'
export const PROFILE = 'profile'
export const PERSONALIZE = 'personalization'
export const MYDATA = 'mydata'
export const INTAKE = 'intake'
export const LABS = 'labs'

export const TEST = 'testing'
export const DEMO = 'demo'


export function path() {
}
