import React, {useState} from "react";
import styled from "styled-components";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {stheme} from "themes/theme";
import {getDim} from "components/library/utility/utilityFunctions";

import {icons} from "utils/images";


const KEYGRAY = stheme.color.greys.g50;

const StyleKBox = styled.div(props => ({

    background: (!props.disabled && props.pressed) ? '#E0E0E0' : props.color,
    display: 'flex',
    'flex-direction': props.direction ? props.direction : 'column',
    'align-items': props.align ? props.align : 'center',
    'justify-content': props.justify ? props.justify : 'center',
    height: props.height ? `${props.height}px` : '100%',
    width: props.width ? `${props.width}px` : '100%',
    'border-style': props.borderStyle,
    'border-color': KEYGRAY,
    'border-width': '2px',
    // font
    'font-size': stheme.font.h2.size,
    'font-family': stheme.font.h2.family,
}));


export function KBox(props) {

    const [pressed, setPressed] = useState(false)

    return (
        <StyleKBox
            {...props}
            pressed={pressed}
            onMouseOut={(e) => setPressed(false)}
            onMouseDown={(e) => setPressed(true)}
            onMouseUp={(e) => setPressed(false)}
            onTouchStart={(e) => setPressed(true)}
            onTouchEnd={(e) => setPressed(false)}
            onTouchCancel={(e) => setPressed(false)}
        >
            {props.label}
            {props.children}
        </StyleKBox>
    )
}


export function Keypad(props) {

    const height = props.height
    const width = props.width
    const keyWidth = props.width/3
    const topKeyHeight = 0.76/3 * height
    const bottomRowHeight = 0.24 * height
    const color = props.color ? props.color : 'g0'
    const periodOn = props.periodOn
    const fixed = props.fixed ? props.fixed : false

    const onPress = props.onPress

    const borderStyle = 'none solid solid none'
    const rightStyle = 'none solid none none'
    const bottomStyle = 'none none solid none'

    function pressNumber(e, num) {
        e.stopPropagation()
        if (onPress) onPress(num.toString())
    }

    function pressString(e, val) {
        e.stopPropagation()
        if (onPress) onPress(val)
    }
    var position, top = null

    if (fixed) {
        position = 'fixed'
        top=window.innerHeight-height
    }

    return (
        <SDiv
            height={height} width={width} color={color}
            position={position} top={top}
        >
            <SDiv direction={'row'} height={topKeyHeight}>
                <KBox onClick={(e) => pressNumber(e, 1)} label="1" borderStyle={borderStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressNumber(e, 2)} label="2" borderStyle={borderStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressNumber(e, 3)} label="3" borderStyle={bottomStyle} width={keyWidth}/>
            </SDiv>
            <SDiv direction={'row'} height={topKeyHeight}>
                <KBox onClick={(e) => pressNumber(e, 4)} label="4" borderStyle={borderStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressNumber(e, 5)} label="5" borderStyle={borderStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressNumber(e, 6)} label="6" borderStyle={bottomStyle} width={keyWidth}/>
            </SDiv>
            <SDiv direction={'row'} height={topKeyHeight}>
                <KBox onClick={(e) => pressNumber(e, 7)} label="7" borderStyle={borderStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressNumber(e, 8)} label="8" borderStyle={borderStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressNumber(e, 9)} label="9" borderStyle={bottomStyle} width={keyWidth}/>
            </SDiv>
            <SDiv direction={'row'} height={bottomRowHeight}>
                {!periodOn && <KBox disabled color={KEYGRAY} borderStyle={rightStyle} width={keyWidth}/>}
                {periodOn && <KBox onClick={(e) => pressString(e, '.')} label="." borderStyle={rightStyle} width={keyWidth}/>}
                <KBox onClick={(e) => pressNumber(e, 0)} label="0" borderStyle={rightStyle} width={keyWidth}/>
                <KBox onClick={(e) => pressString(e, 'delete')} color={KEYGRAY} width={keyWidth}>
                    <SImg height={0.5*bottomRowHeight} width={0.5*keyWidth} src={icons['backspace.svg']}/>
                </KBox>
            </SDiv>
        </SDiv>
    )
}