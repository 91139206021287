import React, {useEffect, useState, useCallback} from 'react';
import ReactGA from 'react-ga';
import {Router} from "@reach/router"

import {WindowDimensionsProvider} from "components/ResponsiveLayout/windowDimensionsProvider";
import {globalStore, GlobalStoreContext} from "models/rootStore";
import {dimensionsStore, DimensionsContext} from "models/dimensionsStore";
import {getToken} from "models/security/token";
import {Spinner} from "components/library/common/Spinner";
import {SWebsocket} from "components/library/network/SWebsocket";

// Components
import {SDiv} from "components/library/styledhtml/SDiv";

// Main pages
import {About} from "components/pages/about";
import {Landing} from "components/pages/landing";
import {SignUp} from "components/pages/signup";
import {Onboarding} from "components/pages/onboarding";
import {LoginSignup} from "components/library/common/auth/LoginSignup";
import {Home} from "components/pages/home";
import {Insights} from "components/pages/insights";
import {Goals} from "components/pages/goals";
import {Suggestions} from "components/pages/suggestions";
import {MyData} from "components/pages/mydata";
import {Recipes} from "components/pages/recipes";
import {Analysis} from "components/pages/analysis";
import {Quests} from "components/pages/quests";
import {Profile} from "components/pages/profile";
import {Personalization} from "components/pages/personalization";

//testing
import {Development} from "components/pages/development";

// constants
import {
    LOGIN, SIGNUP, ONBOARD,
    HOME, RECIPE, INSIGHT, SUGGEST, ANALYSIS, GOAL, DATA, QUEST, PROFILE,
    PERSONALIZE
} from "components/paths";
import {containerDim} from "utils/util";

// google analytics setup
import {GAtiming} from "components/library/network/googleAnalytics";
ReactGA.initialize('UA-175223619-1');
ReactGA.pageview('/');


function NotImplemented(props) {

    const height = window.innerHeight
    return (
        <SDiv align={'center'} justify={'center'} height={0.15*height}>
            Not Implemented
        </SDiv>
    )
}


function FlexLayout(props) {

    const [w, h] = containerDim()

    // Nested boxes to handle making a 'phone box' on a desktop (or rather wide) screen
    return (
        <SDiv halign={'center'} valign={'start'}>
            <SDiv halign={'center'} valign={'start'} height={h} width={w} elevation={'true'}>
                {props.children}
            </SDiv>
        </SDiv>

    )
}


function SecurityBlock(props) {


    const access = props.access
    const [valid, setValid] = useState(false)

    useEffect(() => {
        validateToken()
        setValid(true)
    });

    const validateToken = useCallback(
        () => {
            getToken(access)
        },
        [access],
    );

    return (
        <FlexLayout>
            <SWebsocket checkToken={validateToken}/>
            {!valid && <Spinner/>}
            {valid &&
                <Router>
                    {props.children}
                </Router>
            }
        </FlexLayout>
    )
}


function ProtectedRoutes() {

    const access = ['user', 'devel', 'admin', 'daybeta']

    return (
        <SecurityBlock access={access}>
            <NotImplemented path='/*'/>
            <Onboarding path={`${ONBOARD}/*`}/>

            <Home path={`${HOME}/*`}/>
            <MyData path={`${DATA}/*`}/>
            <Insights path={`${INSIGHT}/*`}/>
            <Goals path={`${GOAL}/*`}/>
            <Suggestions path={`${SUGGEST}/*`}/>
            <Recipes path={`${RECIPE}/*`}/>
            <Analysis path={`${ANALYSIS}/*`}/>
            <Quests path={`${QUEST}/*`}/>
            <Profile path={`${PROFILE}/*`}/>

            <Personalization path={`${PERSONALIZE}/*`}/>

            {/*TESTING*/}
            <Development path={'testing/*'}/>
        </SecurityBlock>
    )
}


function DemoProtectedRoutes() {

    const access = ['daybeta', 'user', 'devel', 'admin']

    return (
        <SecurityBlock access={access}>
            <Development path={'testing/*'}/>
        </SecurityBlock>
    )
}


function UnProtectedRoutes() {

    return (
        <FlexLayout>
            <Router>
                <Landing path={'/'}/>
                <About path={'/about/*'}/>
                <NotImplemented path='/*'/>
            </Router>
        </FlexLayout>
    )
}


function App() {

    const initTime = new Date().getMilliseconds()
    dimensionsStore.updateScreenSize()

    useEffect(() => {
        GAtiming('Timing','landingPage_render', new Date().getMilliseconds() - initTime);
    }, [])

    // setup window sizing global storage
    useEffect(() => {
        const handleResize = () => {
            dimensionsStore.updateScreenSize()
        }
        window.addEventListener('resize', handleResize)
        return () => { window.removeEventListener('resize', handleResize) }
    }, [])

    return (
        <WindowDimensionsProvider>
            <DimensionsContext.Provider value={dimensionsStore}>
                <GlobalStoreContext.Provider value={globalStore}>
                    <Router>
                        <UnProtectedRoutes path={'/*'}/>
                        <ProtectedRoutes path={'/app/*'}/>
                        <DemoProtectedRoutes path={'/demo/*'}/>
                    </Router>
                </GlobalStoreContext.Provider>
            </DimensionsContext.Provider>
        </WindowDimensionsProvider>
    );
}


export default App;
