import React, {useEffect, useState} from "react";

import {SButton, SDiv} from "components/library/styledhtml";
import {SMenu} from "components/library/basic/SMenu";
import {Hp, Wp} from "utils/util";
import {navigate} from "@reach/router";
import {observer} from "mobx-react-lite";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {SModal} from "components/library/basic/SModal";
import {HeaderBuffer, MainHeader} from "components/library/nav/MainHeader";
import {SCalendar} from "components/library/calendar/SCalendar";
import {Close, Like, Trash} from "grommet-icons";
import {SVerticalScroll} from "components/library/basic/SVerticalScroll";
import {ConCompView} from "components/library/intake/viewIntake/ConCompView";
import {Composition} from "models/intake/composition";
import {Spinner} from "components/library/common/Spinner";
import {APP, INTAKE, MYDATA} from "components/paths";
import {EditConComp} from "components/library/intake/createIntake/EditConComp";
import {AddIntake} from "components/library/intake/createIntake/AddIntake";
import {Profile} from "models/profile/userprofile";
import {useIsMountedRef} from "utils/custom_hooks";
import {formatDate} from "utils/dates";


const LowFirst = 'Low score first'
const HighFirst = 'High score first'
const AZ = 'A to Z'
const ZA = 'Z to A'


export const NutritionScoringDetails = observer((props) => {

    const scoreData = props.scoreData
    const sortBy = props.sortBy ?? LowFirst

    const iconH = Hp(18)

    function sortOrder() {

        const array = []
        for (let i=0; i<scoreData.mcs.length; i++) {
            array.push([scoreData.mc[i], i])
        }
        array.sort(function(a, b) {
            return a[0] - b[0];
        })
        const indices = []
        for (let i=0; i<scoreData.mcs.length; i++) {
            indices.push(array[i][1])
        }
        if (sortBy === HighFirst) {
            indices.reverse()
        }
        return indices;
    }

    function lowhi(i) {
        var lowhi = <Like color={'#2EAD6E'} width={iconH}/>
        if (scoreData.uhr1[i] < 100) {
            lowhi = <SDiv textColor={'danger'}>High</SDiv>
        }
        else if (scoreData.lhr1[i] < 100) {
            lowhi = <SDiv textColor={'a800'}>Low</SDiv>
        }
        return lowhi
    }

    function getList() {
        const display = []


        var indicies = [...Array(scoreData.mc.length).keys()];
        if (sortBy === ZA) {
            indicies.reverse()
        }
        else if (sortBy === LowFirst || sortBy === HighFirst) {
            indicies = sortOrder()
        }

        for (let j=0; j<indicies.length; j++) {
            const i = indicies[j]
            const score = {nc: scoreData.mc[i]}

            display.push(
                <SDiv key={i}>
                    <SDiv key={i} height={Hp(40)} width={Wp(0.9)}
                          halign={'start'} direction={'row'}
                          boxShadow={'normal'}
                          borderRadius={8}

                    >
                        {lowhi(i)}
                        <SDiv width={Wp(10)}/>
                        <ScoreBox scheme='grey' height={Hp(30)} width={Hp(40)} score={scoreData.mc[i].toFixed(1)}/>
                        <SDiv width={Wp(10)}/>
                        <SDiv>
                            {scoreData.mcs[i]}
                        </SDiv>
                    </SDiv>
                    <SDiv height={Hp(10)}/>
                </SDiv>
            )
        }
        return display
    }

    return (

        <SVerticalScroll halign={'start'}>
            {getList()}
        </SVerticalScroll>

    )

})

function ScoreBox(props) {

    const height = props.height
    const width = props.width
    const score = props.score
    const scheme = props.scheme ?? 'redgreen'

    function selectScoreColor() {
        if (scheme === 'redgreen') {
            if (score < 20) {return 'red'}
            if (score < 40) {return 'darkorange'}
            if (score < 60) {return 'yellow'}
            if (score < 80) {return '#bfff00'}
            else {return 'lime'}
        }
        else {
            if (score < 10) {return 'g900'}
            if (score < 20) {return 'g800'}
            if (score < 30) {return 'g700'}
            if (score < 40) {return 'g600'}
            if (score < 50) {return 'g500'}
            if (score < 60) {return 'g400'}
            if (score < 70) {return 'g300'}
            if (score < 80) {return 'g200'}
            if (score < 90) {return 'g100'}
            if (score < 100) {return 'g50'}
            else {return 'g0'}
        }
    }
    function selectScoreTextColor() {
        if (scheme === 'redgreen') {
            if (score >= 80) {return 'white'}
            else {return 'black'}
        }
        else {
            if (score < 70) {return 'white'}
            else {return 'black'}
        }
    }

    return (
        <SDiv
            height={height} width={width}
            borderRadius={8} boxShadow={'normal'}
            color={selectScoreColor()} textColor={selectScoreTextColor()}
        >
            {score}
        </SDiv>
    )


}


export const NutritionScoringBox = observer((props) => {

    const width = props.width
    const height = props.height
    const concomp = props.compRef
    const scoreClick = props.scoreClick
    const [ncScore, setNcScore] = useState(null)
    const [profile, setProfile] = useState(null)

    const [scoreNutrition, callScoreNutrition] = useApi('SimpaNutrition', 'score_nhf', true, true);
    const [loadProfile, callLoadProfile] = useApi('SimpaService', 'load_user_profile', true, true);

    useEffect(() => {
        if (concomp && concomp.grams > 0) {
            if (concomp.volume && concomp.volume !== 1 && profile) {
                setNcScore(null)
                callScoreNutrition({concomp: concomp.json})
            }
        }
    }, [concomp.grams, concomp.volume, concomp.totalSubs, profile])

    useEffect(() => {
        callLoadProfile({token: rawToken()})
    }, [])

    useEffect(() => {
        const profile = loadProfile.data
        if (!profile) {return}
        setProfile(Profile.create(profile))
    }, [loadProfile.counter])

    useEffect(() => {
        if (scoreNutrition.data) {
            setNcScore(scoreNutrition.data)
        }
    }, [scoreNutrition.counter])

    function showDetails() {

        if (scoreClick && ncScore) {
            scoreClick(ncScore)
        }
    }

    return (
        <SDiv width={width} height={height} onClick={() => showDetails()}>
                {ncScore == null && <Spinner/>}
                {ncScore !== null &&
                    <ScoreBox scheme='redgreen' height={height} width={width} score={ncScore.nc.toFixed(1)}/>
                }
        </SDiv>
    )
})

function SaveComponent(props) {

    const concomp = props.concomp
    const [saveFoodIntake, callSaveFoodIntake] = useApi('FoodIntakeService', 'save_user_foodintake', true, true);

    useEffect(() => {
        if (concomp.date) {
            callSaveFoodIntake({'token': rawToken(), date: concomp.date, foodintake: concomp.json})
        }
    }, [concomp.grams, concomp.volume, concomp.totalSubs])

    return (<SDiv/>)
}

function DateSelect(props) {

    const date = props.date
    const width = props.width
    const onDelete = props.onDelete
    const loadOnOpen = props.loadOnOpen ?? false
    const scoreClick = props.scoreClick
    const [loadFoodIntake, callLoadFoodIntake] = useApi('FoodIntakeService', 'load_user_foodintake', true, true);
    const [deleteFoodIntake, callDeleteFoodIntake] = useApi('FoodIntakeService', 'delete_user_foodintake', true, true);
    const [fillIn, callFillIn] = useApi('SimpaNutrition', 'fill_in_concomp', true, true);
    const isMountedRef = useIsMountedRef();

    const [concomp, setConcomp] = useState(null)
    const [editConComp, setEditConComp] = useState(false)
    const [addConComp, setAddConComp] = useState(false)

    const concompW = 0.92*width

    function load() {
        const params = {token: rawToken(), date: date}
        callLoadFoodIntake(params)
    }

    function deleteIntake() {
        setConcomp(null)
        callDeleteFoodIntake({'token': rawToken(), date: date})
    }

    useEffect(() => {
        if (onDelete) onDelete()
    }, [deleteFoodIntake.counter])

    function setnewcomp(data) {
        const tmpComp = Composition.create(data)
        tmpComp.setIsOpen(true)
        // turn off top level edit
        tmpComp.setEditSubs(false)
        tmpComp.setSubsEditOn(false)
        tmpComp.setSaveOnChange(true)
        tmpComp.setDate(date)
        setConcomp(tmpComp)
    }

    useEffect(() => {
        if (loadFoodIntake.data) {
            setnewcomp(loadFoodIntake.data)
        }
    }, [loadFoodIntake.counter])

    function closeAdd() {
        // NEED to merge the addConComp back into the main conComp as it is not the whole concomp
        callFillIn({concomp: concomp.json})
        setAddConComp(null)
    }


    useEffect(() => {
        if (fillIn.data) {
            setnewcomp(fillIn.data)
        }
    }, [fillIn.counter])

    if (!isMountedRef.current || loadFoodIntake.loading) {
       return (<Spinner/>)
    }
    else if (!concomp) {
        return (

            <SButton
                width={0.92*width}
                color={'b500'} borderRadius={Hp(8)}
                onClick={() => load()}
                font={'h5'} textColor={'g0'}
            >
                {formatDate(date)}
            </SButton>

        )
    }
    else {

        return (
            <SDiv color={'b800'} borderRadius={Hp(8)}>
                <SDiv height={Hp(6)}/>
                <SDiv width={concompW} halign={'around'} direction={'row'} height={Hp(30)}>
                    <SDiv width={Wp(20)}/>
                    <Close color='white' onClick={() => setConcomp(null)}/>
                    <SDiv width={0.7*width} font={'h5'} textColor={'g0'}>
                        {formatDate(date)}
                    </SDiv>
                    <Trash onClick={() => deleteIntake()} color={'red'}/>
                    <SDiv width={Wp(20)}/>
                    {concomp && <SaveComponent concomp={concomp}/>}
                    {concomp.totalSubs > 4 &&
                    <NutritionScoringBox
                        width={Hp(30)} height={Hp(30)} compRef={concomp}
                        scoreClick={scoreClick}
                        // need some variables below to trigger scoring on change
                        subs={concomp.totalSubs} volume={concomp.volume} grams={concomp.grams}
                    />
                    }
                    <SDiv width={Wp(20)}/>
                </SDiv>

                <SDiv height={Hp(8)}/>
                <ConCompView
                    concomp={concomp}
                    openEdit={setEditConComp} openAdd={setAddConComp}
                    //height={Hp(30)}
                    width={concompW}
                    volumeOff={false} //highlight={highlight}
                />
                <SDiv height={Hp(6)}/>
                {editConComp &&
                <EditConComp
                    concomp={editConComp} onClose={() => setEditConComp(null)}
                    nameOff={false}
                    setConComp={setEditConComp}
                />}
                {addConComp &&
                <AddIntake
                    width={0.8*width}
                    composition={addConComp} name={addConComp.desc}
                    onClose={() => closeAdd()}
                />
                }
            </SDiv>
        )
    }
}


export const IntakeDatesPage = observer((props) => {

    const width = props.width ? props.width : Wp(0)
    const height = props.height ? props.height : Hp(0.7)

    const [calOpen, setCalOpen] = useState(false)
    const [scoreData, setScoreData] = useState(null)
    const [items, setItems] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const subHeight = Hp(40)
    const [sortBy, setSortBy] = useState(LowFirst)

    const [saveFoodIntake, callSaveFoodIntake] = useApi('FoodIntakeService', 'save_user_foodintake', true, true);
    const [getDates, callGetDates] = useApi('FoodIntakeService', 'get_user_dates', true, true);

    useEffect(() => {
        callGetDates({token: rawToken()})
    }, [])

    function onDelete() {
        callGetDates({token: rawToken()})
    }

    function onScoreClick(score) {
        setScoreData(score)
    }
    function onScoreClose() {
        setScoreData(null)
    }

    useEffect(() => {
        callGetDates({token: rawToken()})
    }, [saveFoodIntake.counter])

    useEffect(() => {
        if (getDates.hasData) {
            const dates = getDates.data.date
            var tempItems = []
            for (let i = dates.length - 1; i >= 0; i--) {
                const cc = Composition.create({desc: dates[i], type: 'comp'})
                var loadOnOpen = false
                if (selectedDate && selectedDate === dates[i]) {loadOnOpen = true}
                tempItems.push(
                    <SDiv key={i}>
                        <DateSelect width={width} date={dates[i]} onDelete={onDelete} loadOnOpen={loadOnOpen} scoreClick={onScoreClick}/>
                        <SDiv height={Hp(10)}/>
                    </SDiv>
                )
            }
            setItems(tempItems)
        }
    }, [getDates.counter])

    function selectDate(dateObj) {
        const dt = dateObj.toISO().slice(0,10)
        const cc = Composition.create({desc: dt, type: 'comp'})
        callSaveFoodIntake({'token': rawToken(), date: dt, foodintake: cc.json})
        setSelectedDate(dt)
        setCalOpen(false)
    }

    function closeCal() {
        setCalOpen(false)
    }

    return (
        <SDiv width={Wp(0)} valign={'start'}>
            <MainHeader showBack={true} showProfile={true}/>
            <HeaderBuffer/>
            <SDiv height={Hp(0.02)}/>
            <SButton
                height={Hp(0.05)} width={0.6*width} color={'a800'}
                onClick={() => setCalOpen(true)}
            >
                Add new intake by date
            </SButton>
            <SDiv height={Hp(0.02)}/>
            <SVerticalScroll width={width} height={0.86}>
                {items}
            </SVerticalScroll>
            {calOpen &&
            <SModal>
                <SDiv height={Hp(20)}/>
                <SDiv font={'h3'}>
                    Select date to record food
                </SDiv>
                <SCalendar onClick={selectDate} close={() => closeCal()}/>
            </SModal>
            }
            {scoreData &&
            <SModal onClose={() => onScoreClose()} title={'score'}>
                <SDiv height={Hp(20)}/>
                <SDiv font={'h3'}>
                </SDiv>
                <SMenu
                    width={Wp(260)}
                    items={[LowFirst, HighFirst, AZ, ZA]} direction={'down'} label={sortBy} onSelect={setSortBy}/>
                <NutritionScoringDetails scoreData={scoreData} sortBy={sortBy}/>
            </SModal>
            }
        </SDiv>
    )
})




export function IntakeDatesButton(props) {

    return(
        <SDiv>
            <SButton
                width={Wp(0.7)}
                onClick={() => navigate(`/${APP}/${MYDATA}/${INTAKE}`)}
            >
                Food Intake
            </SButton>
        </SDiv>
    )
}
