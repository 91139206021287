
import {dimensionsStore} from "models/dimensionsStore";

// utc timestamp function
export function utc(minOffset=0) {
    // convert minute offset to milliseconds
    const curtime = new Date().getTime()
    return curtime - (minOffset * 60000)
}


// Pixel conversion to string.   Mostly used for scaling passed width and height to GrommetV2
/**
 * @return {string}
 */
export function Px(val) {
    return `${Math.round(val)}px`
}


// original auto scaling based on a vertical mobile screen and design specs to a small iphone.
const widthNormFactor = 375;
const fullHeightNormFactor = 667;  // 667 - 82 - 48  (~full design minus header and bottom)
const heightNormFactor = fullHeightNormFactor - 82 -48;  // 667 - 82 - 48  (~full design minus header and bottom)

// auto scaling based on desktop
const deskWidthNormFactor = 1440
const deskHeightNormFactor = 768

export function isPhone() {
    // Return true/false depending on aspect ratio
    return dimensionsStore.aspect < 1
}

/**
 * @return {number}
 */
export function Wp(val) {
    // Uniform conversion to pixels based on UX layout of a normFactor (i.e. 375) width pixel screen
    //var innerWidth = window.innerWidth
    //const innerHeight = window.innerHeight
    var innerWidth = dimensionsStore.width ? dimensionsStore.width : window.innerWidth
    var innerHeight = dimensionsStore.height ? dimensionsStore.height : window.innerHeight
    if ( innerWidth > innerHeight) {
        innerWidth = widthNormFactor / fullHeightNormFactor * innerHeight
    }
    if (val <= 0) {
        return innerWidth
    }
    else if (val < 1) {
        return Math.round(innerWidth * val)
    }
    else {
        return Math.round(val / widthNormFactor * innerWidth)
    }
}


/**
 * @return {number}
 */
export function Hp(val) {
    // Uniform conversion to pixels based on UX layout of a 667 height pixel screen
    //const innerHeight = window.innerHeight
    var innerHeight = dimensionsStore.height ? dimensionsStore.height : window.innerHeight
    if (val <= 0) {
        return innerHeight
    }
    else if (val < 1) {
        return Math.round(innerHeight * val)
    }
    else {
        return Math.round(val / heightNormFactor * innerHeight)
    }
}


/**
 * @return {number}
 */
export function Wpd(val) {
    // Uniform conversion to pixels based on UX layout of a normFactor (i.e. 375) width pixel screen
    var innerWidth = window.innerWidth
    if (val <= 0) {
        return innerWidth
    }
    else if (val < 1) {
        return Math.round(innerWidth * val)
    }
    else {
        return Math.round(val / deskWidthNormFactor * innerWidth)
    }
}


/**
 * @return {number}
 */
export function Hpd(val) {
    // Uniform conversion to pixels based on UX layout of a 667 height pixel screen
    const innerHeight = window.innerHeight
    if (val <= 0) {
        return innerHeight
    }
    else if (val < 1) {
        return Math.round(innerHeight * val)
    }
    else {
        return Math.round(val / deskHeightNormFactor * innerHeight)
    }
}



export const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')


export function hexToRgbA(hex, opacity='1'){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+`,${opacity})`;
    }
    throw new Error('Bad Hex');
}

export function containerDim() {
    var height = window.innerHeight
    var width = window.innerWidth
    const isMobile = height > width
    if (!isMobile) {
        return [(375 / 667) * height, height]
    } else {
        return [width, height]
    }

}

export function getDMdimensions() {
    var width = Wp(0)
    var height = Hp(0)
    if (window.innerWidth > window.innerHeight) {
        width = Wpd(0)
        height = Hpd(0)
    }
    return [width, height]
}

// Return width based on desktop or mobile track
/**
 * @return {number}
 */
export function Wpa(w) {
    if (window.innerWidth > window.innerHeight) {return Wpd(w)}
    else {return Wp(w)}

}

// Return height based on desktop or mobile track
/**
 * @return {number}
 */
export function Hpa(w) {
    if (window.innerWidth > window.innerHeight) {return Hpd(w)}
    else {return Hp(w)}

}
