import React from "react";

import {Hp} from "utils/util";
import {SDiv} from "components/library/styledhtml/SDiv";
import {IntakeDatesButton} from "components/pages/mydata/intake/intakeDates";
import {LabsPageButton} from "components/pages/mydata/labs/labsPage";
import {MainHeader, HeaderBuffer} from "components/library/nav/MainHeader";
import {Wp} from "utils/util";

export function MyDataHome(props) {

    return (
        <SDiv width={Wp(0)} valign={'start'}>
            <MainHeader showBack={true} showProfile={true}/>
            <HeaderBuffer/>
            <SDiv height={Hp(40)}/>
            <LabsPageButton/>
            <SDiv height={Hp(10)}/>
            <IntakeDatesButton/>
        </SDiv>
    )
}