// Create profile from scratch first time

import React, {useEffect, useState} from "react";
import {SButton, SDiv, SInput} from "components/library/styledhtml";
import {navigate} from "@reach/router";
import {Hp, Wp} from "utils/util";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {Spinner} from "components/library/common/Spinner";
import {Keypad} from "components/library/keyboards/Keypad";
import {Profile} from "models/profile/userprofile";
import {observer} from "mobx-react-lite";
import {MainHeader} from "components/library/nav/MainHeader";
import {APP, PROFILE} from "components/paths";
import {SelectButton} from "components/library/common/buttons/SelectButton";


const EnterHeight = observer((props) => {

    const profile = props.profile
    const height = props.height ?? Hp(0.7)
    const setNext= props.setNext

    const setFeet = profile.setFeet
    const setInches = profile.setInches

    const [onFeet, setOnFeet] = useState(true)
    const [firstInches, setFirstInches] = useState(true)

    function clickNumber(num) {
        if (onFeet) {
            if (num === 'delete') {
                setFeet(0)
            }
            else {
                setFeet(num)
                setOnFeet(false)
            }
        }
        else {
            if (num === 'delete') {
                setInches(0)
                setFirstInches(true)
            }
            else if (firstInches) {
                setInches(num)
                if (num === '1') {
                    setFirstInches(false)
                }
            }
            else {
                if (profile.inches.length === 2) {
                    setInches(num)
                }
                else if ((num === '0' || num === '1') && profile.inches === '1') {
                    setInches(profile.inches + num)
                }
                else {setInches(num)}
            }
        }
    }

    function clickNext() {
        if (setNext) {setNext()}
    }

    // divide space into 100 slices of height
    const space = 0.01 * height
    return (
        <SDiv>
            <SDiv height={5*space}/>
            <SDiv height={10*space} font={'h3'}>Enter your height</SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={8*space}>
                <SDiv width={10*space} textColor={'g300'}>Feet</SDiv>
                <SDiv width={6*space}/>
                <SDiv width={10*space} textColor={'g300'}>Inches</SDiv>
            </SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={10*space}>
                <SDiv
                    width={10*space} height={10*space} boxShadow={'normal'} borderRadius={10}
                    color={onFeet ? 'b100' : 'g0'}
                    onClick={() => setOnFeet(true)}
                >
                    {profile.feet}
                </SDiv>
                <SDiv width={6*space}/>
                <SDiv width={10*space} height={10*space} boxShadow={'normal'} borderRadius={10}
                      color={!onFeet ? 'b100' : 'g0'}
                      onClick={() => setOnFeet(false)}
                >
                    {profile.inches}
                </SDiv>
            </SDiv>
            <SDiv height={5*space}/>
            <SButton height={10*space} disabled={Number(profile.feet) === 0}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
            <SDiv height={4*space}/>
            <Keypad onPress={clickNumber} periodOn={false} height={50*space} width={Wp(0)}/>
        </SDiv>
    )

})


const EnterWeight = observer((props) => {

    const profile = props.profile
    const setNext= props.setNext
    const height = props.height ?? Hp(0.7)

    const pounds = profile.pounds
    const setPounds = profile.setPounds

    function clickNumber(num) {
        if (num === 'delete') {
            setPounds('0')
        }
        else if (pounds.length < 3) {
            if (pounds === '0') {setPounds(num)}
            else {setPounds(pounds + num)}
        }
        else{
            setPounds(num)
        }
    }

    function clickNext() {
        if (setNext) {setNext()}
    }

    const space = 0.01 * height
    return (
        <SDiv>
            <SDiv height={5*space}/>
            <SDiv height={10*space} font={'h3'}>Enter your weight</SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={8*space}>
                <SDiv width={10*space} textColor={'g300'}>Pounds</SDiv>
            </SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={10*space}>
                <SDiv
                    width={Hp(60)} height={10*space} boxShadow={'normal'} borderRadius={10}
                    color={'b100'}
                >
                    {pounds}
                </SDiv>
            </SDiv>
            <SDiv height={5*space}/>
            <SButton height={10*space} disabled={Number(profile.weight) === 0}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
            <SDiv height={4*space}/>
            <Keypad onPress={clickNumber} periodOn={false} height={50*space} width={Wp(0)}/>
        </SDiv>
    )

})



const EnterBday = observer((props) => {

    const profile = props.profile
    const height = props.height
    const setNext= props.setNext

    const [d, setD] = useState(profile.day)
    const [m, setM] = useState(profile.month)
    const [y, setY] = useState(profile.year)
    const [isValid, setIsValid] = useState(false)

    const [onMonth, setOnMonth] = useState(true)
    const [onDay, setOnDay] = useState(false)
    const [onYear, setOnYear] = useState(false)

    useEffect(() => {
        const nd = Number(d)
        const nm = Number(m)
        const ny = Number(y)
        const dt = new Date()
        if (nd > 0 && nd < 32 && nm > 0 && nm < 13 && ny > 1899 && ny <= dt.getFullYear()) {
            profile.setBirthday(ny, nm , nd)
            setIsValid(true)
        }
        else {setIsValid(false)}
    }, [d, m ,y])

    function clickNumber(num) {
        console.log('valid', profile.validBday, profile.year, profile.month, profile.day)
        if (num === 'delete') {
            if (onDay) {setD('00')}
            if (onMonth) {setM('00')}
            if (onYear) {setY('0000')}
        }
        else if (onDay) {
            if (d.length === 1) {
                if (d === '0' || d === '1' || d === '2') {
                    setD(d + num)
                    setPosition('year')
                }
                if (d === '3' && (num === '0' || num === '1')){
                    setD(d + num)
                    setPosition('year')
                }
            }
            else if (!(num === '0' || num === '1' || num === '2' || num === '3')) {
                setD('0' + num)
                setPosition('year')
            }
            else {
                setD(num)
            }
        }
        else if (onMonth) {
            if (m.length === 1) {
                if (m === '0' && num !== '0'){
                    setM(m + num)
                    setPosition('day')
                }
                else if (m === '1'){
                    if (num === '0' || num === '1' || num === '2') {
                        setM(m + num)
                        setPosition('day')
                    }
                }
            }
            else if (!(num === '0' || num === '1')) {
                setM('0' + num)
                setPosition('day')
            }
            else {
                setM(num)
            }
        }
        else if (onYear) {

            var yr = Number(y)
            if (y.length === 4) {yr = 0}
            if (yr === 0 && (num === '1' || num === '2')) {setY(num)}
            else if (yr === 1 && num === '9') {setY(y + num)}
            else if (yr === 2 && num === '0') {setY(y + num)}
            else if (yr === 0 || yr === 1 || yr === 2) {}
            else if (yr === 19) {setY(y + num)}
            else if (yr === 20 && (num === '0' || num === '1' || num === '2')) {setY(y + num)}
            else if (yr < 200) {
                setY(y + num)
            }
            else {
                const dt = new Date()
                const tmpYr = y + num
                if (Number(tmpYr) <= dt.getFullYear()) {
                    setY(tmpYr)
                }
            }
        }

    }

    function setPosition(value) {
        if (value === 'day') {
            setOnDay(true)
            setOnMonth(false)
            setOnYear(false)
        }
        if (value === 'month') {
            setOnDay(false)
            setOnMonth(true)
            setOnYear(false)
        }
        if (value === 'year') {
            setOnDay(false)
            setOnMonth(false)
            setOnYear(true)
        }
    }

    function clickNext() {
        if (setNext) {setNext()}
    }

    const space = 0.01 * height
    return (
        <SDiv>
            <SDiv height={5*space}/>
            <SDiv height={10*space} font={'h3'}>Enter your birthday</SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={8*space}>
                <SDiv width={10*space} textColor={'g300'}>MM</SDiv>
                <SDiv width={5*space} textColor={'g300'}>/</SDiv>
                <SDiv width={10*space} textColor={'g300'}>DD</SDiv>
                <SDiv width={5*space} textColor={'g300'}>/</SDiv>
                <SDiv width={10*space} textColor={'g300'}>YYYY</SDiv>
            </SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={10*space}>
                <SDiv
                    width={10*space} height={10*space} boxShadow={'normal'} borderRadius={10}
                    color={onMonth ? 'b100' : 'g0'}
                    onClick={() => setPosition('month')}
                >
                    {m}
                </SDiv>
                <SDiv width={5*space}>/</SDiv>
                <SDiv width={10*space} height={10*space} boxShadow={'normal'} borderRadius={10}
                      color={onDay ? 'b100' : 'g0'}
                      onClick={() => setPosition('day')}
                >
                    {d}
                </SDiv>
                <SDiv width={5*space}>/</SDiv>
                <SDiv width={10*space} height={10*space} boxShadow={'normal'} borderRadius={10}
                      color={onYear ? 'b100' : 'g0'}
                      onClick={() => setPosition('year')}
                >
                    {y}
                </SDiv>
            </SDiv>
            <SDiv height={5*space}/>
            <SButton height={10*space} disabled={!isValid}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
            <SDiv height={4*space}/>
            <Keypad onPress={clickNumber} periodOn={false} height={50*space} width={Wp(0)}/>
        </SDiv>
    )

})


const EnterName = observer((props) => {

    const profile = props.profile
    const height = props.height

    const name = profile.username
    const setName = profile.setUsername

    const [value, setValue] = useState(name)

    const setNext= props.setNext

    useEffect(() => {
        setName(value)
    }, [value])
    function clickNext() {
        if (setNext) {setNext()}
    }

    const space = 0.01 * height
    return (
        <SDiv>
            <SDiv height={5*space}/>
            <SDiv height={10*space} font={'h3'}>Enter your name</SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={8*space}>
            </SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={10*space}>
                <SInput value={value} onChange={(e) => setValue(e.target.value)}/>
            </SDiv>
            <SDiv height={5*space}/>
            <SButton height={10*space} disabled={value.length === 0}
                     position={'absolute'} bottom={Hp(20)}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
        </SDiv>
    )

})


const EnterSex = observer((props) => {

    const profile = props.profile
    const height = props.height
    const setNext= props.setNext

    const [sex, setSex] = useState(profile.sex)
    const lactation = profile.lactation
    const pregnancy = profile.pregnancy

    useEffect(() => {
        profile.setSex(sex)
    }, [sex])

    function clickNext() {
        if (setNext) {setNext()}
    }

    const space = 0.01 * height
    return (
        <SDiv>
            <SDiv height={5*space}/>
            <SDiv height={10*space} font={'h3'}>Select your sex</SDiv>
            <SDiv direction={'row'} width={Wp(0.7)} height={8*space}>
                <SDiv
                    height={8*space} width={20*space}
                    borderRadius={8} boxShadow={'normal'}
                    color={sex === 'M' ? 'b100' : 'g0'}
                    onClick={() => setSex('M')}
                >
                    Male
                </SDiv>
                <SDiv width={10*space}/>
                <SDiv
                    height={8*space} width={20*space}
                    borderRadius={8}
                    boxShadow={'normal'}
                    color={sex === 'F' ? 'b100' : 'g0'}
                    onClick={() => setSex('F')}
                >
                    Female
                </SDiv>
            </SDiv>
            {sex !== 'F' && <SDiv direction={'row'} width={Wp(0.7)} height={10*space}/>}
            {sex !== 'F' && <SDiv direction={'row'} width={Wp(0.7)} height={5*space}/>}
            {sex === 'F' &&
            <SDiv direction={'row'} width={Wp(0.7)} height={13*space}>

                <SDiv
                    height={8*space} width={20*space}
                    borderRadius={8}
                    boxShadow={'normal'}
                    color={lactation ? 'b100' : 'g0'}
                    onClick={() => profile.setLactation(true)}
                >
                    Lactating
                </SDiv>
                <SDiv width={10*space}/>
                <SDiv
                    height={8*space} width={20*space}
                    borderRadius={8}
                    boxShadow={'normal'}
                    color={pregnancy ? 'b100' : 'g0'}
                    onClick={() => profile.setPregnancy(true)}
                >
                    Pregnant
                </SDiv>
                <SDiv width={10*space}/>
                <SDiv
                    height={8*space} width={20*space}
                    borderRadius={8} boxShadow={'normal'}
                    color={(!lactation && !pregnancy) ? 'b100' : 'g0'}
                    onClick={() => {profile.setLactation(false); profile.setPregnancy(false)}}
                >
                    Neither
                </SDiv>
            </SDiv>
            }
            {sex === 'F' && <SDiv direction={'row'} width={Wp(0.7)} height={2*space}/>}
            <SButton height={10*space} disabled={sex !== 'M' && sex !== 'F'}
                     position={'absolute'} bottom={Hp(20)}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
        </SDiv>
    )

})


const EnterTimezone = observer((props) => {

    const profile = props.profile
    const height = props.height
    const setNext= props.setNext

    //const [timezone, setTimezone] = useState(profile.timezone)
    const timezone = profile.timezone


    function clickNext() {
        if (setNext) {setNext()}
    }

    const space = 0.01 * height
    return (
        <SDiv>
            <SDiv height={5*space}/>
            <SDiv height={10*space} font={'h3'}>What is your timezone?</SDiv>
            <SButton
                height={10*space} width={Wp(0.6)}
                onClick={() => profile.setTimezone('America/Los_Angeles')}
                color={'b100'} textColor={'g600'} secondary={timezone !== 'America/Los_Angeles'}
            >
                Pacific: America/Los Angeles
            </SButton>
            <SDiv height={3*space}/>
            <SButton
                height={10*space} width={Wp(0.6)}
                onClick={() => profile.setTimezone('America/Denver')}
                color={'b100'} textColor={'g600'} secondary={timezone !== 'America/Denver'}
            >
                Mountain: America/Denver
            </SButton>
            <SDiv height={3*space}/>
            <SButton
                height={10*space} width={Wp(0.6)}
                onClick={() => profile.setTimezone('America/Chicago')}
                color={'b100'} textColor={'g600'} secondary={timezone !== 'America/Chicago'}
            >
                Central: America/Chicago
            </SButton>
            <SDiv height={3*space}/>
            <SButton
                height={10*space} width={Wp(0.6)}
                onClick={() => profile.setTimezone('America/New_York')}
                color={'b100'} textColor={'g600'} secondary={timezone !== 'America/New_York'}
            >
                Eastern: America/New York
            </SButton>
            <SDiv height={3*space}/>
            <SButton
                height={10*space} width={Wp(0.6)}
                color={'b100'} textColor={'g600'} disabled={true}
            >
                Other
            </SButton>
            <SDiv height={3*space}/>
            <SButton height={10*space} disabled={false}
                     position={'absolute'} bottom={Hp(20)}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
        </SDiv>
    )

})


const EnterFitness = observer((props) => {

    const profile = props.profile
    const height = props.height
    const setNext= props.setNext

    //const [timezone, setTimezone] = useState(profile.timezone)
    const minpmile = profile.min_p_mile

    function clickNext() {
        if (setNext) {setNext()}
    }

    const space = 0.01 * height
    const buttonS = 8
    return (
        <SDiv>
            <SDiv height={12*space} font={'h3'}>Estimate your fitness</SDiv>
            <SDiv height={12*space} font={'b2'} manualWrap textAlign={'center'} valign={'start'}>
                {'Approximate the fastest you could walk/run 1 MILE\nmaintaining a conversation the whole time'}
            </SDiv>
            <SButton
                height={buttonS*space} width={Wp(0.6)}
                onClick={() => profile.setMinPMile(5)}
                color={'b100'} textColor={'g600'} secondary={!(minpmile > 3 && minpmile < 6)}
            >
                4-6 minutes (very fast)
            </SButton>
            <SDiv height={2*space}/>
            <SButton
                height={buttonS*space} width={Wp(0.6)}
                onClick={() => profile.setMinPMile(7.5)}
                color={'b100'} textColor={'g600'} secondary={!(minpmile >= 6 && minpmile < 9)}
            >
                6-9 minutes (fast run)
            </SButton>
            <SDiv height={2*space}/>
            <SButton
                height={buttonS*space} width={Wp(0.6)}
                onClick={() => profile.setMinPMile(10.5)}
                color={'b100'} textColor={'g600'} secondary={!(minpmile >= 9 && minpmile < 12)}
            >
                9-12 minutes (run)
            </SButton>
            <SDiv height={2*space}/>
            <SButton
                height={buttonS*space} width={Wp(0.6)}
                onClick={() => profile.setMinPMile(13.5)}
                color={'b100'} textColor={'g600'} secondary={!(minpmile >= 12 && minpmile < 15)}
            >
                12-15 minutes (jog)
            </SButton>
            <SDiv height={2*space}/>
            <SButton
                height={buttonS*space} width={Wp(0.6)}
                onClick={() => profile.setMinPMile(18)}
                color={'b100'} textColor={'g600'} secondary={!(minpmile >= 15 && minpmile < 20)}
            >
                15-20 minutes (walk)
            </SButton>
            <SDiv height={2*space}/>
            <SButton
                height={buttonS*space} width={Wp(0.6)}
                onClick={() => profile.setMinPMile(22)}
                color={'b100'} textColor={'g600'} secondary={!(minpmile >= 20)}
            >
                20+ minutes (slow walk)
            </SButton>
            <SDiv height={4*space}/>
            <SButton height={10*space} disabled={minpmile <= 3}
                     position={'absolute'} bottom={Hp(20)}
                     onClick={() => clickNext()}
            >
                Save
            </SButton>
        </SDiv>
    )

})



export function BuildProfile(props) {

    const [selected, setSelected] = useState('Name')
    const [loadProfile, callLoadProfile] = useApi('SimpaService', 'load_user_profile', true, true);
    const [storeProfile, callStoreProfile] = useApi('SimpaService', 'store_user_profile', true, true);
    const [profile, setProfile] = useState(null)
    const [saving, setSaving] = useState(false)


    useEffect(() => {
        callLoadProfile({token: rawToken()})
    }, [])

    function setNext() {
        if (selected === 'Name') {setSelected('Height')}
        if (selected === 'Height') {setSelected('Weight')}
        if (selected === 'Weight') {setSelected('Birthday')}
        if (selected === 'Birthday') {setSelected('Sex')}
        if (selected === 'Sex') {setSelected('Fitness')}
        if (selected === 'Fitness') {setSelected('Timezone')}
        setSaving(true)
        callStoreProfile({token: rawToken(), user_profile: profile.json})
    }

    useEffect(() => {
        setSaving(false)
    }, [storeProfile.counter])

    useEffect(() => {
        const profile = loadProfile.data
        if (!profile) {return}
        setProfile(Profile.create(profile))
    }, [loadProfile.counter])

    // button height
    const bH = Hp(0.05)
    const subHeight = Hp(0) - Hp(0.09) - Hp(50) - 2*bH
    return(
        <SDiv>
            <MainHeader position={'relative'} showProfile={false}/>
            <SDiv height={Hp(10)}/>
            {!profile && <Spinner/>}
            {profile &&
            <SDiv>
                <SDiv height={Hp(20)} direction={'row'} halign={'between'}>
                    {saving && <SDiv width={Wp(100)}/>}
                    <SDiv>Edit Profile</SDiv>
                    {saving && <SDiv width={Wp(100)} font={'b1'} textColor={'a800'}>saving...</SDiv>}
                </SDiv>
                <SDiv height={Hp(10)}/>
                <SDiv height={bH} direction={'row'} width={Wp(0.9)} halign={'around'}>
                    <SelectButton name={'Name'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Height'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Weight'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Birthday'} selected={selected} setSelected={setSelected} height={bH}/>
                </SDiv>
                <SDiv height={Hp(0.02)}/>
                <SDiv height={bH} direction={'row'} width={Wp(0.9)} halign={'around'}>
                    <SelectButton name={'Sex'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Fitness'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SelectButton name={'Timezone'} selected={selected} setSelected={setSelected} height={bH}/>
                    <SDiv width={Wp(70)}/>
                </SDiv>
                {selected === 'Height' &&
                <EnterHeight height={subHeight} profile={profile} setNext={setNext}/>
                }
                {selected === 'Weight' &&
                <EnterWeight height={subHeight} profile={profile} setNext={setNext}/>
                }
                {selected === 'Birthday' &&
                <EnterBday height={subHeight} profile={profile} setNext={setNext}/>
                }
                {selected === 'Name' &&
                <EnterName height={subHeight} profile={profile} setNext={setNext}/>
                }
                {selected === 'Sex' &&
                <EnterSex height={subHeight} profile={profile} setNext={setNext}/>
                }
                {selected === 'Timezone' &&
                <EnterTimezone height={subHeight} profile={profile} setNext={setNext}/>
                }
                {selected === 'Fitness' &&
                <EnterFitness height={subHeight} profile={profile} setNext={setNext}/>
                }
            </SDiv>
            }

        </SDiv>
    )
}
