import React, {useState, useContext} from "react";
import {navigate} from "@reach/router";

import {GlobalStoreContext} from "models/rootStore";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {SButton} from "components/library/styledhtml/SButton";
import {Hp, Wp} from "utils/util";
import {imgs, icons} from "utils/images";

import {PersonalizeHeader} from "components/pages/personalization/PersonalizeHeader";
import {PERSONALIZE, APP} from "components/paths";
import {HANDSIZE} from "components/pages/personalization/paths";

const IMGH = 96

function HandImage(props) {

    const num = props.num
    const active = props.active
    const onClick = props.onClick
    const src=`hand_color_${num}@2x.png`
    return (
        <SDiv
            width={Wp(IMGH)} height={Wp(IMGH)}
            color={num === active ? 'b50' : 'g50'}
            borderColor={num === active ? 'b200' : null}
            borderRadius={'256px'}
            onClick={() => onClick(num)}
        >
            <SImg width={Wp(IMGH)} height={Wp(IMGH)} src={imgs[src]}/>
        </SDiv>
    )
}



export function HandShade(props) {

    const gstore = useContext(GlobalStoreContext)
    const setNext = props.setNext
    const [active, setActive] = useState(gstore.profile.skinshade)

    function done() {
        gstore.profile.setSkinShade(active)
        if (setNext) {setNext()}
        //navigate(`/${APP}/${PERSONALIZE}/${HANDSIZE}`)
    }

    const gridH = 2*Wp(IMGH) + Hp(16)
    const maintxt = 'Select your skin tone \n' +
        'to customize your experience'
    return (
        <SDiv width={Wp(0)} valign={'start'}>
            <SDiv height={Hp(30)}/>
            <SDiv width={Wp(0)} height={gridH}>
                <SDiv
                    height={Wp(IMGH)} width={Wp(0)}
                    direction={'row'} halign={'around'}
                >
                    <HandImage num={'1'} active={active} onClick={setActive}/>
                    <HandImage num={'2'} active={active} onClick={setActive}/>
                    <HandImage num={'3'} active={active} onClick={setActive}/>
                </SDiv>
                <SDiv height={Hp(16)}/>
                <SDiv
                    height={Wp(IMGH)} width={Wp(0)}
                    direction={'row'} halign={'around'}
                >
                    <HandImage num={'4'} active={active} onClick={setActive}/>
                    <HandImage num={'5'} active={active} onClick={setActive}/>
                    <HandImage num={'6'} active={active} onClick={setActive}/>
                </SDiv>
            </SDiv>
            <SDiv height={Hp(10)}/>
            <SDiv font={'b2'}>
                Note: color shown is grey scale
            </SDiv>
            <SButton height={Hp(40)} disabled={!active}
                     position={'absolute'} bottom={Hp(20)}
                     onClick={() => done()}
            >
                Save
            </SButton>
        </SDiv>
    )
}