import React, {useEffect, useRef, useState} from "react";


// custom hook to determine if component is mounted and to prevent unwanted errors and memory leaks
export function useIsMountedRef(){
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    });
    return isMountedRef;
}


/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(closeFunc) {
    const ref = useRef(null)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (closeFunc) closeFunc(event)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return ref
}