import styled from "styled-components";
import React, {useState} from "react";

import {getColor, stheme} from "themes/theme";
import {getDim} from "components/library/utility/utilityFunctions";
import {Hp} from "utils/util";

const StyleButton = styled.button(props => ({

    'background': `${props.background_color} 0% 0% no-repeat padding-box`,
    'box-shadow': props.boxShadow,
    'border-radius': props.borderRadius ? props.borderRadius : `${Hp(24)}px`,
    opacity: props.opacity,
    height: props.height ? getDim(props.height) : '50px',
    width: props.width ? getDim(props.width) : '100px',

    position: props.position ? props.position : 'static',
    top: props.top ? getDim(props.top) : null,
    'margin-bottom': props.top ? getDim(props.top) : null,
    bottom: props.bottom ? getDim(props.bottom) : null,
    'margin-top': props.bottom ? getDim(props.bottom) : null,
    left: props.left ? getDim(props.left) : null,
    'margin-right': props.left ? getDim(props.left) : null,
    right: props.right ? getDim(props.right) : null,
    'margin-left': props.right ? getDim(props.right) : null,

    // text info
    border: '0px solid #222222',
    color: props.textColor,
    padding: '0px 0px',
    'text-align': props.align ? props.align : 'center',
    //'text-decoration': 'none',
    display: 'inline-block',
    'font-size': props.font_size,
    'font-weight': props.font_weight,
    'font-family': props.font_family,
    'line-height': props.line_height,
    'text-shadow': props.textShadow,
    'white-space': 'pre',
    'outline': 'none',


}))


export function SButton(props) {

    const [hover, setHover] = useState(false)
    const [pressed, setPressed] = useState(false)

    function mouseDown(e) {
        setPressed(true)
        setHover(false)
    }

    function mouseUp(e) {
        setPressed(false)
    }

    function mouseOver(e) {
        setHover(true)
    }

    function mouseOut(e) {
        setHover(false)
        setPressed(false)
    }

    var font = props.font
    if (!font) {font = stheme.font.button}
    else if (stheme.font.hasOwnProperty(font)) {font = stheme.font[font]}
    // setup font defaults
    const fontSize = props.fontSize ? props.fontSize : font.size
    const fontFamily = props.fontFamily ? props.fontFamily : font.family
    const lineHeight = props.lineHeight ? props.lineHeight : font.sp
    const fontWeight = props.fontWeight ? props.fontWeight : font.weight

    var color = getColor(props.color, false)

    var boxShadow = '0px 4px 16px rgba(0, 0, 0, 0.16)'
    var textColor = getColor(props.textColor, true, stheme.color.greys.g0)
    var textShadow = props.disabled ? null : '0px 4px 16px rgba(0, 0, 0, 0.16)'
    var backgroundColor = color ? color : stheme.color.brands.b500
    if (props.disabled) {backgroundColor = stheme.color.greys.g100}
    else if (hover) {backgroundColor = stheme.color.brands.b800}
    else if (pressed) {backgroundColor = stheme.color.brands.b900}
    if (props.secondary) {
        textShadow = null
        backgroundColor = stheme.color.greys.g0
        textColor = stheme.color.greys.g900
        if (props.disabled) {
            backgroundColor = stheme.color.greys.g100
            textColor = stheme.color.greys.g0
        }
        else if (hover) {backgroundColor = stheme.color.greys.g50}
        else if (pressed) {backgroundColor = stheme.color.greys.g100}
    }
    if (props.disabled) {
        boxShadow = null
        textColor = stheme.color.greys.g300
    }


    return (
        <StyleButton
            {...props}
            background_color={backgroundColor}
            pressed={pressed}
            boxShadow={boxShadow}

            font_size={fontSize}
            font_family={fontFamily}
            font_weight={fontWeight}
            line_height={lineHeight}
            textColor={textColor}
            textShadow={textShadow}

            onMouseOut={(e) => mouseOut(e)}
            onMouseOver={(e) => mouseOver(e)}
            onMouseDown={(e) => mouseDown(e)}
            onMouseUp={(e) => mouseUp(e)}
            onTouchStart={(e) => mouseDown(e)}
            onTouchEnd={(e) => mouseUp(e)}
            onTouchCancel={(e) => mouseOut(e)}
        />
    )
}