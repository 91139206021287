import React, {useState, useContext} from "react";
import {navigate} from "@reach/router";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {SButton} from "components/library/styledhtml/SButton";
import {Hp, Wp} from "utils/util";
import {imgs, icons} from "utils/images";

import {PersonalizeHeader} from "components/pages/personalization/PersonalizeHeader";
import {GlobalStoreContext} from "models/rootStore";



function SexBox(props) {

    const label = props.label
    const activelabel = props.active
    const setActive = props.setActive
    const active = label === activelabel
    const src = props.src

    return(
        <SDiv
            height={Hp(100)} width={Wp(327)}
            borderRadius={Hp(8)}
            textColor={active ? 'g900' : 'g400'}
            color={active ? 'b50' : 'g50'}
            borderColor={active ? 'b200' : null}
            onClick={() => setActive(label)}
            direction={'row'}
        >
            <SImg width={Hp(32)} height={Hp(32)} src={icons[src]}/>
            <SDiv width={Wp(12)}/>
            {label}
        </SDiv>
    )
}


export function GenderSelect(props) {

    const gstore = useContext(GlobalStoreContext)
    const [active, setActive] = useState(gstore.profile.gender)

    function done() {
        gstore.profile.setGender(active)
        // todo: determine next navigation
        navigate('../demo')
    }

    const maintxt = 'Select your sex'
    return (
        <SDiv height={Hp(0)} width={Wp(0)} valign={'start'}>
            <PersonalizeHeader num={3} title={maintxt}/>
            <SDiv width={Wp(327)} height={Hp(356)}>
                <SexBox label={'Female'} active={active} setActive={setActive} src={'gender_female.svg'}/>
                <SDiv height={Hp(12)} />
                <SexBox label={'Male'} active={active} setActive={setActive} src={'gender_male.svg'}/>
                <SDiv height={Hp(12)}/>
                <SexBox label={'Other'} active={active} setActive={setActive} src={'gender_other.svg'}/>
                <SDiv height={Hp(12)}/>
            </SDiv>
            <SButton
                width={Wp(327)} height={Hp(48)} secondary={true}
                onClick={() => done()}
                disabled={!active}
            >
                Done
            </SButton>

        </SDiv>
    )
}