
import React, {useState} from "react";
import {navigate} from "@reach/router";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {SImg} from "components/library/styledhtml/SImg";
import {icons} from "utils/images";

import {PERSONALIZE, APP} from "components/paths";


export function PersonalizeHeader(props) {

    const title = props.title
    const num = props.num
    const backnav = props.backnav

    const blockH = Hp(56)

    function clickBack() {
        if (backnav) {navigate(`/${APP}/${PERSONALIZE}/${backnav}`)}
        else {navigate(-1)}
    }

    var counterTxt = ''
    if (num) {counterTxt = `${num}/3`}

    return (
        <SDiv>
            <SDiv height={blockH} width={Wp(0)} halign={'start'} direction={'row'}>
                <SDiv width={Wp(24)}/>
                <SImg
                    src={icons['arrow_back.svg']}
                    height={Hp(16)} width={Hp(16)} color={'g400'}
                    onClick={() => clickBack()}
                />
                <SDiv
                    width={Wp(0)-Wp(80)} height={blockH}
                    font={'h6'} textColor={'g900'}
                >
                    {counterTxt}
                </SDiv>
                <SDiv width={Wp(40)}/>
            </SDiv>
            <SDiv height={Hp(8)}/>
            <SDiv height={blockH} font={'h4'} textColor={'g900'} manualWrap textAlign={'center'}>
                {title}
            </SDiv>
        </SDiv>
    )
}