/*
Examples of horizontal scrolling
https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
http://jsfiddle.net/8dtrktpw/
 */

import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";


export function SHorizontalScroll(props) {

    const height = props.height

    return (
        <SDiv
            {...props}
            width={Wp(0)} height={height}
            //borderRadius={'16px'}
            //boxShadow={'normal'}
            //color={'g50'}
            valign={'start'}
            halign={'start'}
            overflowY={'auto'}
            overScrollBehaviorX={'none'}
        >
            <SDiv
                height={height}
                direction={'row'}
                valign={props.valign ? props.valign : 'center'}
                halign={props.halign ? props.halign : 'start'}
                overScrollBehaviorX={'none'}
            >
                {props.children}
            </SDiv>
        </SDiv>
    )
}