import React from "react"

import {SModal} from "components/library/basic/SModal";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {Keypad} from "components/library/keyboards/Keypad";

import {Close} from "grommet-icons/es6";


export function KeyPadModal(props) {

    const title = props.title
    const close = props.close
    const onNumber = props.onNumber
    const periodOn = props.periodOn

    const boxWidth = Wp(326)
    const boxHeight = Hp(0) - Hp(24*2 + 220)

    function clickNumber(num) {
        if (onNumber) {onNumber(num)}
    }

    return (
        <SModal>
            <SDiv width={Wp(0)} height={Hp(24)}/>
            <SDiv
                width={boxWidth} height={boxHeight}
                borderRadius={16} color={'g0'} boxShadow={'normal'}
                valign={'start'}
            >
                <SDiv width={boxWidth} height={Hp(60)} direction={'row'}>
                    <SDiv width={Wp(36)} height={Hp(60)}/>
                    <SDiv
                        width={boxWidth-2*Wp(36)} height={Hp(60)}
                        valign={'start'} position={'relative'} top={Hp(32)}
                        font={'h4'}
                    >
                        {title}
                    </SDiv>
                    <SDiv
                        width={Wp(36)} height={Hp(60)} position={'relative'}
                        halign={'end'} valign={'start'} top={Hp(15)} right={Hp(15)}
                    >
                        {close && <Close onClick={() => close()} height={Hp(16)} width={Hp(16)}/>}
                    </SDiv>
                </SDiv>
                {props.children}
            </SDiv>
            <SDiv width={Wp(0)} height={Hp(24)}/>
            <Keypad onPress={clickNumber} periodOn={periodOn} height={Hp(220)} width={Wp(0)}/>
        </SModal>
    )
}


