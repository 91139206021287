import React, {useState, useEffect, useRef} from "react";
import {navigate} from "@reach/router";
import {observer} from "mobx-react-lite";

import {stheme} from "themes/theme";
import {Wp, Hp} from "utils/util";
import {SDiv} from "components/library/styledhtml/SDiv";
import {SInput} from "components/library/styledhtml/SInput";
import {SArrow} from "components/library/basic/SArrow";
import {SButton} from "components/library/styledhtml/SButton";
import {SHorizontalScroll} from "components/library/basic/SHorizontalScroll";
import {InputPosSlider} from "components/library/inputs/InputPosSlider";

import {Close} from "grommet-icons";
import {CircleInformation} from "grommet-icons";
import {AddCircle} from "grommet-icons";

import {ReactComponent as TimeMorning} from 'Assets/Icons/time_morning.svg';
import {ReactComponent as TimeEvening} from 'Assets/Icons/time_evening.svg';
import {ReactComponent as TimeNoon} from 'Assets/Icons/time_noon_afternoon.svg';
import {ReactComponent as TimeNight} from 'Assets/Icons/time_night.svg';

import {Composition} from "models/intake/composition";
import {TwoWeekIntake, Intakes} from "models/intake/twoweekintake";


const TIMES = {
    'morning': '5am - 11am',
    'afternoon': '11am - 5pm',
    'evening': '5pm - 11pm',
    'night': '11pm - 5am',
}
const pallete = stheme.color.pallete.p100
const PALLETE = Object.keys(pallete)
const boxSz = Hp(76)

// testing
const comps = {
    'morning': [],
    'afternoon': [],
    'evening': [],
    'night': [],
}

function addComps(time, tmpNames) {
    for (let i=0; i<tmpNames.length; i++) {
        const comp = Composition.create({id: `${i}`, name: tmpNames[i], subs: [], type: 'comp'})
        comps[time].push(comp)
    }
}
addComps('morning', ['My breakfast', 'good breakfast', 'other breakfast', 'yummy breakfast'])
addComps('afternoon', ['My Lunch', 'This lunch', 'other lunch', 'super yummy lunch'])
addComps('evening', ['My Dinner', 'Late Snack', 'Mmmmmm dinner', 'Grub Dinner'])
addComps('night', ['Late night snack 1', 'Late Snack 2'])

const initIntakes = Intakes.create({morning: {}, afternoon: {}, evening: {}, night: {}})
const twoWeekIntake = TwoWeekIntake.create({intakes: initIntakes})
twoWeekIntake.addIntake('afternoon', '1', comps['afternoon']['1'])


function IntakeComp(props) {

    const comp = props.comp
    const active = props.active
    const setActive = props.setActive

    return (
        <SDiv
            height={Hp(56)} width={Wp(164)}
            borderRadius={Hp(8)}
            //boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'}
            boxShadow={'normal'}
            borderColor={comp.id === active ? 'g400' : null}
            color={selectPalleteColor(comp.name)}
            onClick={() => setActive(comp.id)}
        >
            {`${comp.id}-${comp.name}`}
        </SDiv>
    )
}


function Space(props) {
    return (
        <SDiv width={Wp(props.w ? props.w : 8)}/>
    )
}

function hashCode(s) {
    for(var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
}


function selectPalleteColor(s) {
    // get a number 1-10 based on hash of string
    const n = Math.abs(hashCode(s))/10
    const tmpIdx = Math.round((n-Math.floor(n))*10)
    return pallete[PALLETE[tmpIdx]]
}


function IntakeCards(props) {

    const compositions = props.compositions
    const activeComp = props.activeComp
    const setActiveComp = props.setActiveComp

    const cards = []
    for (let i=0; i<compositions.length; i++) {
        cards.push(
            <IntakeComp key={i} comp={compositions[i]} active={activeComp} setActive={setActiveComp}/>
        )
        cards.push(<Space key={10000+i}/>)
    }
    return (
        <SHorizontalScroll
            height={Hp(72)}
            paddingLeft={Hp(40)}
            valign={'center'}
        >
            {cards}
        </SHorizontalScroll>
    )

}


export function TimeBox(props) {

    const timedisp = props.timedisp
    const active = props.active
    const setActive = props.setActive

    function getImage() {
        if (timedisp === 'morning') {
            return <TimeMorning fill={stheme.color.stat.warn500} width={Hp(40)} height={Hp(40)}/>
        }
        else if (timedisp === 'afternoon') {
            return <TimeNoon fill={stheme.color.stat.warn500} width={Hp(40)} height={Hp(40)}/>
        }
        else if (timedisp === 'evening') {
            return <TimeEvening fill={stheme.color.stat.warn500} width={Hp(40)} height={Hp(40)}/>
        }
        else if (timedisp === 'night') {
            return <TimeNight fill={stheme.color.stat.warn500} width={Hp(40)} height={Hp(40)}/>
        }
    }

    const isActive = timedisp === active
    return (
        <SDiv
            width={boxSz} height={boxSz} valign={'start'}
            color={isActive ? 'b50' : 'g0'}
            textColor={isActive ? 'b500' : 'g300'}
            borderColor={isActive ? 'b500' : null} borderWidth={isActive ? 1 : null}
            borderRadius={Hp(8)}
            font={'o1'}
            boxShadow={'0px 4px 16px rgba(0, 0, 0, 0.16)'}
            onClick={() => setActive(timedisp)}
        >
            <SDiv height={Hp(8)}/>
            {getImage()}
            <SDiv height={Hp(4)}/>
            {TIMES[timedisp]}
        </SDiv>

    )
}


function TimeRow(props) {

    const active = props.active
    const setActive = props.setActive

    return (
        <SDiv direction={'row'} width={Wp(0)} height={Hp(76)}>
            <TimeBox timedisp={'morning'} active={active} setActive={setActive}/>
            <SDiv width={Wp(8)}/>
            <TimeBox timedisp={'afternoon'} active={active} setActive={setActive}/>
            <SDiv width={Wp(8)}/>
            <TimeBox timedisp={'evening'} active={active} setActive={setActive}/>
            <SDiv width={Wp(8)}/>
            <TimeBox timedisp={'night'} active={active} setActive={setActive}/>
        </SDiv>
    )

}

function FillBox(props) {

    const width = props.width
    const height = props.height
    const name = props.name
    const num = props.num
    const setSlot = props.setSlot

    const dispName = name ? name : `intake ${parseInt(num)+1}`

    return (
        <SDiv
            width={width} height={height}
            borderRadius={Hp(4)}
            color={name ? selectPalleteColor(name) : 'g50'}
            onClick={() => setSlot(num)}
            font={'b2'}
            textColor={name ? 'g800' : 'g100'}
            textAlign={'center'}
        >
            {dispName}
        </SDiv>
    )
}


const Intake2WeekSlots = observer((props) => {

    const twoweekintake = props.twoweekintake
    const timeperiod = props.timeperiod
    const setSlot = props.setSlot

    const boxH = Hp(48)
    const boxW = Wp(80)
    const spaceH = Hp(8)
    const spaceW = Wp(8)
    const height = 4*boxH + 3*spaceH
    const width = 4*boxW + 3*spaceW

    function getRow(rowNum) {
        var st_i = rowNum * 4
        var end_i = st_i + 4
        if (rowNum === 0) {
            st_i = 0
            end_i = 4
        }
        if (rowNum === 1) {
            st_i = 4
            end_i = 7
        }
        if (rowNum === 2) {
            st_i = 7
            end_i = 11
        }
        if (rowNum === 3) {
            st_i = 11
            end_i = 14
        }
        const row = []
        for (let i=st_i; i<end_i; i++) {
            let comp = twoweekintake.intake(timeperiod, `${i}`)
            const name = comp ? comp.name : ''
            row.push(<FillBox key={i} name={name} num={`${i}`} setSlot={setSlot} width={boxW} height={boxH}/>)
            row.push(<Space key={i+100}/>)
        }
        return (
            <SDiv direction={'row'} height={boxH} width={width} halign={'start'}>
                {row}
            </SDiv>
        )
    }

    return (
        <SDiv height={height} width={width} valign={'start'}>
            <SDiv height={Hp(8)}/>
            {getRow(0)}
            <SDiv height={Hp(8)}/>
            {getRow(1)}
            <SDiv height={Hp(8)}/>
            {getRow(2)}
            <SDiv height={Hp(8)}/>
            {getRow(3)}
        </SDiv>
    )
})


export function IntakeQuestTest(props) {

    const [active, setActive] = useState('afternoon')
    const [activeComp, setActiveComp] = useState('0')
    const [change, setChange] = useState(0)

    function close() {
        navigate(-1)
    }

    function setSlot(num) {
        twoWeekIntake.addIntake(active, num, comps[active][activeComp])
        setChange(change + 1)
    }

    return (
        <SDiv
            height={Hp(0)} width={Wp(0)}
            valign={'start'}
        >
            <SDiv
                direction={'row'} width={Wp(0)} height={Hp(52)}
                halign={'around'}
            >
                <CircleInformation color={stheme.color.brands.b500}/>
                <SDiv font={'h4'} textColor={'b700'}>2 Week Nutrition Intake</SDiv>
                <Close size={`${Hp(16)}px`} onClick={() => close()}/>
            </SDiv>
            <TimeRow active={active} setActive={setActive}/>
            <SDiv
                width={Wp(342)} height={Hp(42)}
                borderColor={'g200'} borderStyle={'none none solid none'}
                font={'b2'} textColor={'g400'}
                halign={'start'}
            >
                Search Intakes (not functional)
            </SDiv>
            <IntakeCards compositions={comps[active]} activeComp={activeComp} setActiveComp={setActiveComp}/>
            <Intake2WeekSlots twoweekintake={twoWeekIntake} timeperiod={active} setSlot={setSlot}/>
        </SDiv>

    )
}


