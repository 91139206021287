import React from "react";
import {navigate} from "@reach/router";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {SHorizontalScroll} from "components/library/basic/SHorizontalScroll";

import {Wp, Hp} from "utils/util";

import {imgs} from "utils/images";

import {ANALYSIS, DATA, GOAL, INSIGHT, RECIPE, SUGGEST, QUEST, PROFILE, APP} from "components/paths";

const BASEH = 512
const BASEW = 375


function cvert(val, imgH) {
    return imgH/BASEH * val
}

function choriz(val, imgW) {
    return imgW/BASEW * val
}

function ClickRegion(props) {

    const name = props.name
    const width = props.w
    const height = props.h
    // positioning
    var top = props.top
    var left = props.left ? props.left + window.innerWidth / 2 - width/2: 0

    return (
        <SDiv
            height={height} width={width}
            position={'absolute'} top={top} left={left}
            onClick={() => navigate(`/${APP}/${name}`)}
            //color={props.color} opacity={0.3}
        />
    )

}


function Card(props) {

    return (
        <SDiv
            display={'inline-block'}
            borderRadius={'16px'}
            height={Hp(152)} width={Wp(319)}
            boxShadow={'normal'} color={'g0'}
        />
    )
}


function HomeCardScroll(props) {

    return (
            <SHorizontalScroll height={Hp(180)}>
                <SDiv width={Wp(24)}/>

                <Card/>
                <SDiv width={Wp(12)}/>
                <Card/>
                <SDiv width={Wp(12)}/>

                <SDiv width={Wp(12)}/>
            </SHorizontalScroll>
    )
}


function ScrollTitle(props) {

    return (
        <SDiv width={Wp(0)-Wp(48)} height={Hp(32)} valign='end' halign={'start'}>
            <SDiv font={'b2'} textColor={'g200'}>{props.title}</SDiv>
        </SDiv>
    )
}



export function HomeMain(props) {

    const winH = window.innerHeight
    const winW = window.innerWidth

    const bottomBuff = Hp(32)
    const pageH = winH - bottomBuff

    // scale image to desired page height
    var imgW = Wp(0)
    var imgH = BASEH/BASEW * imgW
    if (imgH > pageH) {
        imgH = pageH
        imgW = BASEW/BASEH * imgH
    }

    // center the image
    const imgLeftOffset = 0 //(Wp(0)-imgW)/2
    const imgTopOffset = (pageH - imgH)/2

    const buttonSz = Hp(52)
    return (
        <SDiv
            color={'g50'}
            valign={'start'}
        >
            <SDiv
                width={buttonSz} height={buttonSz} position={'fixed'}
                boxShadow={'normal'} borderRadius={'100px'}
                top={pageH - buttonSz - Hp(24)}
                left={(winW+Wp(0))/2 - (Hp(24) + buttonSz)}
                onClick={() => navigate(`/${APP}/${QUEST}`)}
            >
                <SImg src={imgs['compass@2x.png']} width={buttonSz} height={buttonSz}/>
            </SDiv>

            <SDiv
                width={Wp(0)} height={pageH}
                backgroundImage={imgs['image_homepage_full@2x.png']}
                backgroundSize={`${imgW}px ${imgH}px`}
                backgroundPosition={`${imgLeftOffset}px ${imgTopOffset}px`}
                valign={'start'} halign={'center'}
            >

                <ClickRegion
                    name={INSIGHT} color={'red'}
                    h={125*imgH/BASEH} w={125*imgW/BASEW}
                    top={cvert(60, imgH)+imgTopOffset} left={-choriz(102, imgW)}
                />
                <ClickRegion
                    name={RECIPE} color={'grey'}
                    h={135*imgH/BASEH} w={100*imgW/BASEW}
                    top={cvert(225, imgH)+imgTopOffset} left={-choriz(126, imgW)}
                />
                <ClickRegion
                    name={GOAL} color={'magenta'}
                    h={110*imgH/BASEH} w={118*imgW/BASEW}
                    top={cvert(360, imgH)+imgTopOffset} left={-choriz(60, imgW)}
                />
                <ClickRegion
                    name={DATA} color={'yellow'}
                    h={90*imgH/BASEH} w={70*imgW/BASEW}
                    top={cvert(100, imgH)+imgTopOffset} left={choriz(5, imgW)}
                />
                <ClickRegion
                    name={DATA} color={'yellow'}
                    h={110*imgH/BASEH} w={150*imgW/BASEW}
                    top={cvert(190, imgH)+imgTopOffset} left={choriz(5, imgW)}
                />
                <ClickRegion
                    name={SUGGEST} color={'blue'}
                    h={125*imgH/BASEH} w={125*imgW/BASEW}
                    top={cvert(60, imgH)+imgTopOffset} left={choriz(110, imgW)}
                />
                <ClickRegion
                    name={ANALYSIS} color={'green'}
                    h={135*imgH/BASEH} w={90*imgW/BASEW}
                    top={cvert(255, imgH)+imgTopOffset} left={choriz(130, imgW)}
                />
                <ClickRegion
                    name={ANALYSIS} color={'green'}
                    h={70*imgH/BASEH} w={75*imgW/BASEW}
                    top={cvert(340, imgH)+imgTopOffset} left={choriz(47, imgW)}
                />
                <SDiv
                    width={buttonSz} height={buttonSz} position={'absolute'}
                    boxShadow={'normal'} borderRadius={'100px'}
                    top={pageH - buttonSz - Hp(24)}
                    left={(winW-Wp(0))/2 + Hp(24)}
                    onClick={() => navigate(`/${APP}/${PROFILE}`)}
                >
                    <SImg src={imgs['placeholder_avatar@2x.png']} width={buttonSz} height={buttonSz}/>
                </SDiv>
            </SDiv>
            <SDiv
                color={'g0'}
                borderRadius={`${Hp(16)}px`}
            >
                <SDiv height={Hp(12)}/>
                <SDiv
                    width={Wp(56)} height={Hp(6)}
                    borderRadius={`${Hp(6)}px`}
                    color={'g100'}
                />
                <SDiv height={Hp(36)}/>
                <ScrollTitle title={'MY QUESTS'}/>
                <HomeCardScroll/>
                <ScrollTitle title={'INSIGHTS'}/>
                <HomeCardScroll/>
                <ScrollTitle title={'SUGGESTIONS'}/>
                <HomeCardScroll/>
                <ScrollTitle title={'MY GOALS'}/>
                <HomeCardScroll/>
                <SDiv height={Hp(72)}/>
            </SDiv>
        </SDiv>
    )

}