import React, {useEffect, useState, useContext} from "react";

import {useAuthentication} from "api/api";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {MainButton} from "components/library/common/buttons/MainButton";
import {BasicInput} from "components/library/common/BasicInput";
import {useIsMountedRef} from "utils/custom_hooks";
import {GlobalStoreContext} from "models/rootStore";

export const TMPPASS = "MyCoolPassword1!"


export function SmsPhoneVerifyInput(props) {


    const gstore = useContext(GlobalStoreContext)

    const isMountedRef = useIsMountedRef();
    const smsType = props.smsType
    const setValidate = props.setVerify
    const number = props.number
    const setNumber = props.setNumber

    const [focus, setFocus] = useState(false)
    const [errormsg, setErrorMsg] = useState('')
    const [disable, setDisable] = useState(false)

    const [returnSignup, callSignup] = useAuthentication('sign_up', true);
    const [sendText, callSendText] = useAuthentication('send_reset_text', true);
    const smstext = "Receive a text containing a code\nto verify your phone number"

    useEffect(() => {
        var data
        if (returnSignup.data) {data = returnSignup.data}
        if (sendText.data) {data = sendText.data}

        if (data) {
            if (data['success'] === true) {
                setValidate(true)
                gstore.setLastLoginNum(number)
            }
            else {
                setErrorMsg(data.message)
                setTimeout(function () {
                    if (isMountedRef) {
                        setErrorMsg('')
                        setDisable(false)
                    }
                }, 5000);
            }
        }

    }, [returnSignup.counter, sendText.counter])


    function pressContinue() {
        // perform API call to send sms
        // todo: deal with password
        if (smsType === 'login') {
            callSendText({'phone': number, 'code_type': "login"})
        }
        else {
            callSignup({'email_phone': number, 'password': TMPPASS})
        }
        setDisable(true)
    }

    return (
        <SDiv
            onClick={() => setFocus(false)}
        >
            <SDiv
                height={Hp(48)} width={Wp(280)}
                font={'b2'} textAlign={'center'} textColor={'g400'} manualWrap
            >
                {smstext}
            </SDiv>
            <SDiv height={Hp(24)}/>
            <BasicInput
                width={Wp(300)} phone={'true'}
                numPadWidth={Wp(0.9)}
                focus={focus} setFocus={setFocus}
                value={number} setValue={setNumber}
            />
            <SDiv height={Hp(32)}/>
            <MainButton
                onClick={() => pressContinue()}
                disabled={(!number || number.length !== 10) || returnSignup.loading || disable}
            >
                Continue
            </MainButton>
            {errormsg &&
            <SDiv textColor={'danger'} width={Wp(300)} textAlign={'center'}>
                {errormsg}
            </SDiv>
            }
        </SDiv>
    )
}

