

// UserProfile
import {types, flow} from "mobx-state-tree";
import {callApi} from "api/api";
import {Restrictions} from "models/profile/restrictions";

// utc timestamp function
function utc(minOffset=0) {
    // convert minute offset to milliseconds
    const curtime = new Date().getTime()
    return curtime - (minOffset * 60000)
}

export const UserProfile = types
    .model({
        skinshade: types.optional(types.string, '3'),
        handLen: types.optional(types.number, 18),
        gender: types.optional(types.string, 'Female'),
        restrictions: Restrictions,
    })
    .actions(self => ({
        //afterCreate(){
        //    console.log('CON', self.desc)
        //},
        doNothing(n) {
        },
        setSkinShade(num) {
            // string number from '1' to '6'
            if (['1', '2', '3', '4', '5', '6'].includes(num)) {
                self.skinshade = num
            }
        },
        setHandLen(cm) {
            // hand length in cm
            self.handLen = cm
        },
        setGender(gender) {
            if (['Male', 'Female', 'Other'].includes(gender)) {
                self.gender = gender
            }
        },
    }))
    .views( self => ({
        get nothing() {
            return null
        },
    }));


const zeroPad = (num, places) => String(num).padStart(places, '0')


export const Profile = types
    .model({
        sex: types.string,
        height: types.number,
        weight: types.number,
        birthday: types.string,
        dietary_restrict: types.map(types.boolean),
        min_p_mile: types.number,
        lactation: types.boolean,
        pregnancy: types.boolean,
        zipcode: types.string,
        timezone: types.string,
        username: types.string,
        useremail: types.string,
        userphone: types.string,
        simpaphone: types.string,
    })
    .actions(self => ({
        setSex (sex) {
            if (sex === 'F') {
                self.sex = sex
            }
            if (sex === 'M') {
                self.sex = sex
            }
        },
        setUsername (username) {
            self.username = username
        },
        setTimezone (timezone) {
            self.timezone = timezone
        },
        setPregnancy (isPregnant) {
            self.pregnancy = isPregnant
            if (self.pregnancy) {self.setLactation(false)}
        },
        setLactation (isLactating) {
            self.lactation = isLactating
            if (self.lactation) {self.setPregnancy(false)}
        },
        setBirthday (year, month, day) {
            self.birthday = `${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`
        },
        setBirthdayString(bday) {
            self.birthday = bday
        },
        setDay(day) {
            console.log("Set day", day)
        },
        setMonth(month) {
            console.log("Set month", month)
        },
        setYear(year) {
            console.log("Set year", year)
        },
        setHeightEng(feet, inches) {
            self.height = Number(feet) * 0.3048 + Number(inches) * 0.0254
        },
        setWeightEng(lbs) {
            self.weight = lbs * 0.453592
        },
        setPounds(lbs) {
            self.weight = Number(lbs) * 0.453592
        },
        setMinPMile(minmile) {
            self.min_p_mile = minmile
        },
        setFeet(feet) {
            self.setHeightEng(feet, self.inches)
        },
        setInches(inches) {
            self.setHeightEng(self.feet, inches)
        },
        setRestrictions(restricts) {
            self.dietary_restrict = restricts
        }
    }))
    .views( self => ({
        isExpired(minOffset=60) {
            return self.loadTime < utc(minOffset);
        },
        get feet() {
            return `${Math.floor(self.height / 0.3048)}`
        },
        get inches() {
            return `${Math.round((self.height-(self.feet*0.3048))/0.0254)}`
        },
        get heightStr() {
            return `${self.feet} feet ${self.inches} inches`
        },
        get pounds() {
            return `${Math.round(self.weight / 0.453592)}`
        },
        get weightlbs() {
            return Math.round(self.weight / 0.453592)
        },
        get weightStr() {
            return `${self.weightlbs} pounds`
        },
        get min_p_mileStr() {
            return `${self.min_p_mile.toFixed(1)}`
        },
        get year() {
            if (!self.birthday) {return '0000'}
            const [y, m, d] = self.birthday.split('-')
            return y
        },
        get month() {
            if (!self.birthday) {return '00'}
            const [y, m, d] = self.birthday.split('-')
            return `${zeroPad(m, 2)}`
        },
        get day() {
            if (!self.birthday) {return '00'}
            const [y, m, d] = self.birthday.split('-')
            return `${zeroPad(d, 2)}`
        },
        get validBday() {
            if (!self.birthday) {return false}
            const [y, m, d] = self.birthday.split('-')
            var dt = new Date()
            var thisYear = dt.getFullYear()
            if (Number(y) < 1900 || Number(y) > thisYear) {return false}
            if (Number(d) < 1 || Number(d) > 31) {return false}
            if (Number(m) < 1 || Number(m) > 12) {return false}

            return true
        },
        get json () {
            return {
                sex: self.sex,
                height: self.height,
                weight: self.weight,
                birthday: self.birthday,
                dietary_restrict: self.dietary_restrict.toJSON(),
                min_p_mile: self.min_p_mile,
                lactation: self.lactation,
                pregnancy: self.pregnancy,
                zipcode: '', //self.zipcode, // will override timezone if set
                timezone: self.timezone,
                username: self.username,
                useremail: self.useremail,
                userphone: self.userphone,
                simpaphone: self.simpaphone,
            }
        }
    }));