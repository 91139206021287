import React from "react";

import useImage from "use-image";
import {Circle, Group, Image} from "react-konva";
import "konva/lib/shapes/Circle"
import "konva/lib/shapes/Image"

import {getColor} from "themes/theme";
import {imgs} from "utils/images";


export function ShapeSelect(props) {

    const height = props.height
    const width = props.width
    const y = props.y ? props.y : 0
    const x = props.x ? props.x : 0
    const shapeActive = props.activeShape
    const setActive = props.setActiveShape

    const [gsphereSm] = useImage(imgs['grey_sphere_small@2x.png']);
    const [gcylSm] = useImage(imgs['grey_cyllinder_small@2x.png']);

    const sz = height
    const img = shapeActive === 'sphere' ? gcylSm : gsphereSm
    const name = shapeActive === 'sphere' ? 'cylinder' : 'sphere'

    var imgH = 0.5 * height
    var imgW = 0.5 * height
    if (name === 'cylinder') {
        imgH = 0.66 * height
        imgW = 0.4 * height
    }

    return (
            <Group
                x={x} y={y}
                onClick={() => setActive(name)}
                onTouchStart={() => setActive(name)}
            >
                <Circle
                    width={sz} height={sz}
                    offsetX={-0.5*sz} offsetY={-0.5*sz}
                    fill={getColor('g0')}
                />
                <Image
                    image={img} width={imgW} height={imgH}
                    fill={getColor('g0')}
                    y={-0.5 * imgH} x={-0.5 * imgW}
                    offsetX={-0.5*sz} offsetY={-0.5*sz}
                />
            </Group>
    )
}