import React from "react";
import {Router} from "@reach/router";

import {InsightsHome} from "components/pages/insights/InsightsHome";

export function Insights(props) {

    return (
        <Router>
            <InsightsHome path={'/'}/>
        </Router>
    )
}