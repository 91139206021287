import {Hp, Wp} from "utils/util";
import {SButton} from "components/library/styledhtml";
import React from "react";

export function SelectButton(props) {

    const name = props.name
    const selected = props.selected
    const setSelected = props.setSelected
    const height = props.height ?? Hp(24)

    return (
        <SButton
            height={height} width={Wp(70)}
            font={'b2'}
            borderRadius={8}
            secondary={name !== selected}
            onClick={() => setSelected(name)}
            color={'b100'} textColor={'g800'}
        >
            {name}
        </SButton>
    )
}