import React from "react";

import {SDiv} from "components/library/styledhtml/SDiv";

export function AnalysisHome(props) {

    return (
        <SDiv>
            Analysis page
        </SDiv>
    )
}