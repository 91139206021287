import React, {useState} from "react";

import {observer} from "mobx-react-lite";
import {navigate} from "@reach/router";

import {SButton, SDiv} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";
import {APP, LABS, MYDATA} from "components/paths";
import {HeaderBuffer, MainHeader} from "components/library/nav/MainHeader";
import {LabUploadSection} from "components/pages/mydata/labs/labsUploadSection";
import {LabDataSection} from "components/pages/mydata/labs/labsDataSection";


export function LabsPageButton(props) {

    return(
        <SDiv>
            <SButton
                width={Wp(0.7)}
                onClick={() => navigate(`/${APP}/${MYDATA}/${LABS}`)}
            >
                Labs
            </SButton>
        </SDiv>
    )
}


export const LabsPage = observer((props) => {

    const width = props.width ? props.width : Wp(0)
    const height = props.height ? props.height : Hp(0.7)

    const [uploadOn, setUploadOn] = useState(false);
    const [dataOn, setDataOn] = useState(true);

    return (
        <SDiv width={Wp(0)} valign={'start'}>
            <MainHeader showBack={true} showProfile={true}/>
            <HeaderBuffer/>
            <SDiv height={Hp(0.02)}/>
            <SDiv
                width={Wp(0)}
                direction={'row'} halign={'spread'}
            >
                {!dataOn &&
                <SButton
                    height={Hp(28)} width={Hp(120)}
                    onClick={() => {setUploadOn(false); setDataOn(true)}}
                >
                    View lab data
                </SButton>
                }
                {!uploadOn &&
                <SButton
                    height={Hp(28)} width={Hp(120)}
                    onClick={() => {setUploadOn(true); setDataOn(false)}}
                >
                    Upload lab pdf
                </SButton>
                }
            </SDiv>
            <SDiv height={Hp(10)}/>
            {dataOn && <LabDataSection/>}
            {uploadOn && <LabUploadSection/>}

        </SDiv>
    )
})


