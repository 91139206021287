
const DaysOfWeek = ['Sun ', 'Mon ', 'Tues', 'Wed ', 'Thurs', 'Fri ', 'Sat ']

function jsDateString(d) {

    const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
    const mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d);
    const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
    return `${DaysOfWeek[d.getDay()]} ${mo} ${da}, ${ye}`;
}


export function formatDate(date) {
    const [year, month, day] = date.split('-')
    const d = new Date(year, month - 1, day);
    return jsDateString(d)
}

export function utc2Format(timestamp) {
    var date = new Date(timestamp * 1000);
    return (new Intl.DateTimeFormat('en-US').format(date))
    //const ye = new Intl.DateTimeFormat('en', {year: '4-digit'}).format(d);
    //const mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
    //const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
    //return `${mo} ${da}, ${ye}`;
    //return jsDateString(d)
}
