// Composition
import {types, clone, flow} from "mobx-state-tree";
import {utc} from "utils/util";
import {callApi} from "api/api";
import {rawToken} from "models/security/token";


export const ListMeasures = types
    .model({
        labs: types.array(types.number),
        loadTime: types.optional(types.number, 0),
        state: types.optional(types.enumeration("State", ["init", "pending", "done", "error"]), 'init'),
    })
    .actions(self => ({
        doNothing(text) {
        },
        load: flow(function* load() {

            // create condition to only load initially or after some time
            //if (self.name) {return}

            const max_time = utc() / 1000.;  // current time
            self.state = "pending";
            try {
                const retData = yield callApi(
                    'LabsMeasuresService', 'list_measures',
                    {'token': rawToken()}
                );

                console.log("LIST", retData)
                self.state = "done";
                self.loadTime = utc()
            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch list measures", error);
                self.state = "error"
            }
        }),
    }))
    .views( self => ({
        get length() {
            return self.labs.length
        }
    }));
