import React from "react"

import {SButton, SDiv} from "components/library/styledhtml";
import {Wp} from "utils/util";


export function CancelSaveButton(props) {

    const { onSave, onCancel, ...otherProps } = props

    const height = props.height
    const width = props.width
    const saveName = props.saveName ?? 'Save'
    const cancelName = props.cancelName ?? 'Cancel'
    const disabled = props.disabled
    const buttonWidth = props.buttonWidth ?? Wp(120)

    return (
        <SDiv
            {...otherProps}
            height={height} width={width}
            direction={'row'} halign={'around'}
        >
            <SButton
                height={height} width={buttonWidth}
                onClick={() => onCancel ? onCancel() : null}
                secondary={true}
            >
                {cancelName}
            </SButton>
            <SButton
                height={height} width={buttonWidth}
                onClick={() => onSave ? onSave() : null}
                disabled={disabled}
            >
                {saveName}
            </SButton>
        </SDiv>
    )
}