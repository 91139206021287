import React, {useState} from "react";
import {observer} from "mobx-react-lite";

import {Hp, Wp, Px} from "utils/util";
import {SDiv} from "components/library/styledhtml";
import {SArrow} from "components/library/basic/SArrow";
import {VolumeIndicator} from "components/library/intake/viewIntake/VolumeIndicator";
import {stheme} from "themes/theme";
import {AddCircle, Trash, LinkDown, LinkUp, SubtractCircle, InstallOption, Upgrade} from "grommet-icons";
import {ConCompView, Description} from "components/library/intake/viewIntake/ConCompView";
import {ReactComponent as TimeMorning} from "Assets/Icons/time_morning.svg";
import {ReactComponent as TimeNoon} from "Assets/Icons/time_noon_afternoon.svg";
import {ReactComponent as TimeEvening} from "Assets/Icons/time_evening.svg";
import {ReactComponent as TimeNight} from "Assets/Icons/time_night.svg";
import {imgs} from "utils/images";




function EditBoxes(props) {

    const width = props.width
    const height = props.height ? props.height : Hp(30)
    const openAdd = props.openAdd
    const openMove = props.openMove
    const openDelete = props.openDelete
    const deleteOn = props.deleteOn
    const moveOn = props.moveOn

    const colorDisable = stheme.color.greys.g200
    const colorDelete = openDelete ? deleteOn ? stheme.color.stat.danger : stheme.color.greys.g600 : colorDisable
    const colorMove = openMove ? moveOn ? stheme.color.stat.warn500 : stheme.color.brands.b900 : colorDisable
    return (
        <SDiv
            width={width} height={height}
            direction={'row'} halign={'spread'}
            borderRadius={4} borderWidth={1} borderColor={'g100'} borderStyle={'solid'}
            color={'g50'}
        >
            <SDiv
                width={0.33*width} height={height}
                onClick={() => openAdd()}
            >
                <AddCircle size={Px(Hp(20))} color={openAdd ? stheme.color.brands.b900 : colorDisable}/>
            </SDiv>
            <SDiv
                width={0.33*width} height={height} direction={'row'}
                onClick={openMove ? () => openMove() : null}
            >
                <LinkDown size={Px(Hp(16))} color={colorMove}/>
                <LinkUp size={Px(Hp(16))} color={colorMove}/>
            </SDiv>
            <SDiv
                width={0.33*width} height={height}
                onClick={openDelete ? () => openDelete() : null}
            >
                <SubtractCircle size={Px(Hp(20))} color={colorDelete}/>
            </SDiv>
        </SDiv>

    )
}


export const CompositionView = observer((props) => {

    // REQUIRED: pass the open modal through the chain to make sure it is on top
    const openEdit = props.openEdit
    const openAdd =  props.openAdd

    const height = props.height ? props.height : Hp(60)
    const parentWidth = props.width ? props.width : Wp(0)

    // Composition-Model
    const comp = props.composition
    const isOpen = comp.open
    const level = props.level

    const volumeOff = props.volumeOff
    const nameOff = props.nameOff
    const addOff = props.addOff
    const onDelete = props.onDelete
    const onShiftUp = props.onShiftUp
    const onShiftDown = props.onShiftDown

    const width = getSubWidth(parentWidth)
    function getSubWidth(thiswidth) {
        return Math.max(thiswidth - 8, Wp(0.7))
    }

    const txtWidth = 0.8*width

    function toggleDelete() {
        if (!comp.deleteOn) {
            comp.closeAll()
        }
        comp.setShiftOn(false)
        comp.setDeleteOn(!comp.deleteOn)
    }

    function toggleShift() {
        if (!comp.shiftOn) {
            comp.closeAll()
        }
        comp.setDeleteOn(false)
        comp.setShiftOn(!comp.shiftOn)
    }

    function deleteit(index) {
        comp.delete(index)
    }

    function shift(index, direction) {
        if (direction === 'up') {
            comp.shiftup(index)
        }
        if (direction === 'down') {
            comp.shiftdown(index)
        }
    }

    function callOpenAdd() {
        comp.closeAll()
        comp.clearTemp()
        if (openAdd) openAdd(comp)
    }
    function closeAddModal() {
        comp.clearTemp()
        comp.setShiftOn(false)
        comp.setDeleteOn(false)
    }

    const showDesc = comp.desc ? comp.desc : comp.name

    var showNormal = showDesc
    if (showDesc === 'Daily diet' && level === 0) {
        showNormal = false
    }

    function getDesc() {
        var d = ''

        const imgSz = Hp(26)
        if (showDesc === '5am - 11am') {
            d = <TimeMorning fill={stheme.color.stat.warn500} width={imgSz} height={imgSz}/>
        }
        else if (showDesc === '11am - 5pm') {
            d = <TimeNoon fill={stheme.color.stat.warn500} width={imgSz} height={imgSz}/>
        }
        else if (showDesc === '5pm - 11pm') {
            d =  <TimeEvening fill={stheme.color.stat.warn500} width={imgSz} height={imgSz}/>
        }
        else if (showDesc === '11pm - 5am') {
            d =  <TimeNight fill={stheme.color.stat.warn500} width={imgSz} height={imgSz}/>
        }
        else {
        }
        const newD = <SDiv direction={'row'} font={'b2'}>{d} {showDesc}</SDiv>

        return <Description desc={newD} width={txtWidth} height={0.5 * height} valign={'start'}/>
    }


    return (
        <SDiv
            width={width} halign={'end'}
        >
            {showNormal && <SDiv
                width={width} height={isOpen ? null : height}
                direction={'row'} halign={'start'}
                borderRadius={4} borderWidth={1} borderColor={'g100'} borderStyle={'solid'}
                color={'g0'}
                boxShadow={isOpen ? 'normal' : null}
            >
                <SDiv
                    width={Wp(40)} height={height} valign={'start'}
                    onClick={() => comp.setIsOpen(!isOpen)}
                >
                    <SDiv height={0.3 * height}/>
                    <SDiv height={0.3 * height}>
                        <SArrow size={8} color={'g400'} direction={isOpen ? 'down' : 'right'}/>
                    </SDiv>
                    <SDiv
                        height={0.2 * height}
                        textColor={'g300'} font={'b5'}
                    >
                        {comp.length}
                    </SDiv>
                </SDiv>
                <SDiv
                    width={txtWidth} height={height}
                    valign={'start'}
                    onClick={() => (comp.editOn && openEdit) ? openEdit(comp) : null}
                >
                    <SDiv width={txtWidth} height={0.32 * height}/>
                    {getDesc()}
                </SDiv>
                {(!volumeOff && !onDelete && !(onShiftDown || onShiftUp)) &&
                <SDiv
                    height={height}
                    onClick={() => (comp.editOn && openEdit) ? openEdit(comp) : null}
                >
                    {!volumeOff && <VolumeIndicator height={0.5 * height} volume={comp.volume}/>}
                </SDiv>
                }
                {onDelete &&
                <SDiv
                    height={height}
                    onClick={() => onDelete()}
                >
                    <Trash size={Px(0.36*height)} color={stheme.color.stat.danger}/>
                </SDiv>
                }
                {(onShiftUp || onShiftDown) &&
                <SDiv direction={'row'}>
                    <SDiv
                        height={height} valign={'around'}
                    >
                        <Upgrade
                            size={Px(0.36*height)} color={onShiftUp ? stheme.color.stat.warn500 : stheme.color.greys.g100}
                            onClick={onShiftUp ? () => onShiftUp() : null}
                        />
                        <InstallOption
                            size={Px(0.36*height)} color={onShiftDown ? stheme.color.stat.warn500 : stheme.color.greys.g100}
                            onClick={onShiftDown ? () => onShiftDown() : null}
                        />
                    </SDiv>
                    <SDiv width={Wp(10)} height={height}/>
                </SDiv>
                }
            </SDiv>
            }
            {(!addOff && (isOpen || !showDesc) && comp.editSubsOn) &&
            <EditBoxes
                width={getSubWidth(width)}
                openAdd={callOpenAdd}
                openMove={comp.length < 2 ? false : toggleShift}
                openDelete={comp.length === 0 ? false : toggleDelete}
                deleteOn={comp.deleteOn} moveOn={comp.shiftOn}
            />
            }
            {(isOpen || !showDesc) &&
            comp.subs.map((x, index) =>
                <ConCompView
                    concomp={x} key={index}
                    level={level}
                    width={width} height={height}
                    openEdit={openEdit} openAdd={openAdd}
                    nameOff={nameOff} volumeOff={volumeOff}
                    addOff={addOff}
                    onDelete={comp.deleteOn ? () => deleteit(index) : null}
                    onShiftUp={comp.shiftOn ? index > 0 ? () => shift(index, 'up') : null : null}
                    onShiftDown={comp.shiftOn ? index < comp.length -1 ? () => shift(index, 'down') : null : null}
                />
            )
            }
        </SDiv>

    )
})

