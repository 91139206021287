import React from "react";
import {Router} from "@reach/router";

import {MyDataHome} from "components/pages/mydata/MyDataHome";
import {Development} from "components/pages/development";
import {IntakeDatesPage} from "components/pages/mydata/intake/intakeDates";
import {LabsPage, LabsPageButton} from "components/pages/mydata/labs/labsPage";
import {INTAKE, LABS} from "components/paths";


export function MyData(props) {

    return (
        <Router>
            <MyDataHome path={'/*'}/>
            <IntakeDatesPage path={`/${INTAKE}`}/>
            <LabsPage path={`/${LABS}`}/>
            <Development path={'/dev'}/>
        </Router>
    )
}