

export function getAlignment(direct, halign, valign) {

    // align-items for cross-axis vertical for row
    // align-items: stretch|center|flex-start|flex-end|baseline|initial|inherit;
    // justify-content for directional horizontal for row
    // justify-content: flex-start|flex-end|center|space-between|space-around|initial|inherit;

    var direction = direct ? direct : 'column'
    var alignItems = null
    var justifyContent = null
    if (direction === 'row') {
        alignItems = valign ? valign : 'center'
        justifyContent = halign ? halign : 'center'
    }
    else {
        alignItems = halign ? halign : 'center'
        justifyContent = valign ? valign : 'center'
    }
    if (justifyContent === 'start') {justifyContent = 'flex-start'}
    if (justifyContent === 'end') {justifyContent = 'flex-end'}
    if (justifyContent === 'between') {justifyContent = 'space-between'}
    if (justifyContent === 'around') {justifyContent = 'space-around'}
    if (alignItems === 'start') {alignItems = 'flex-start'}
    if (alignItems === 'end') {alignItems = 'flex-end'}

    return [direction, alignItems, justifyContent]

}

export function getDim(val) {

    // create more flexible width and height values
    var value = val ? val : 1
    if (value <= 1 && value > 0) {value = `${Math.round(value*100)}%`}
    else {value = `${value}px`}

    return value
}