import {Hpa, Wpa} from "utils/util";

export function headerHeight() {

    var height
    const mobile = window.innerHeight > window.innerWidth
    if (mobile && !height) {
        height = Wpa(50)
    }
    if (!mobile && !height) {
        height = Wpa(72)
    }
    height = Hpa(0.1)
    return height

}