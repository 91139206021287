import React from "react";

import {SDiv} from "components/library/styledhtml/SDiv";

export function GoalsHome(props) {

    return (
        <SDiv>
            Goals page
        </SDiv>
    )
}