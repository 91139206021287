import React, {useState} from "react";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {SButton} from "components/library/styledhtml/SButton";
import {KeyPadModal} from "components/library/inputs/KeypadModal";


export function ModalAmountInput(props) {

    const onClose = props.onClose
    var onSave = props.onSave
    var unit = props.unit
    const title = props.title
    const subText = props.subText

    const [newValue, setNewValue] = useState([])
    const [periodOn, setPeriodOn] = useState(true)

    function onNumber(num) {

        var foundPeriod = num === '.';
        for (let i = 0; i < newValue.length; i++) {
            if (newValue[i] === '.') {
                if (foundPeriod) {
                    continue
                }
                foundPeriod = true
            }
        }
        if (num === 'delete') {
            if (newValue.length > 0) {
                setNewValue(newValue.slice(0, newValue.length - 1))
            }
        } else {
            setNewValue([...newValue, num])
        }
        if (foundPeriod) {
            setPeriodOn(false)
        } else {
            setPeriodOn(true)
        }

    }

    function save() {

        const floatV = parseFloat(newValue.join(''))
        if (onSave && newValue.length > 0 && floatV > 0) {
            onSave(floatV)
            onClose()
        }
    }

    return (
        <KeyPadModal onNumber={onNumber} periodOn={periodOn} title={title}>
            <SDiv font={'b2'} textColor={'g400'}>{subText}</SDiv>
            <SDiv height={Hp(32)}/>
            <SDiv direction={'row'} halign={'start'} height={Hp(32)} width={Wp(160)}>
                <SDiv
                    width={Wp(112)} height={Hp(32)}
                    textColor={'g900'} font={'b1'}
                    borderColor={'g200'} borderRadius={Hp(4)} borderStyle={'solid solid solid solid'}
                >
                    {newValue.join('')}
                </SDiv>
                <SDiv width={Wp(10)}/>
                <SDiv>{unit}</SDiv>
            </SDiv>
            <SDiv height={Hp(40)}/>
            <SDiv direction={'row'} halign={'around'} height={Hp(48)} width={1}>
                <SButton
                    secondary
                    height={Hp(48)} width={Wp(100)} borderRadius={Hp(24)} onClick={() => onClose()}>
                    Cancel
                </SButton>
                <SButton height={Hp(48)} width={Wp(100)} borderRadius={Hp(24)} onClick={() => save()}>
                    Enter
                </SButton>
            </SDiv>
        </KeyPadModal>
    )
}