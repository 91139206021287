import {Hp, Wp} from "utils/util";
import {getColor} from "themes/theme";
import {SDiv} from "components/library/styledhtml/SDiv";
import React from "react";


export function SArrow(props) {

    const size = props.size ? props.size : 6
    var d = props.direction ? props.direction : 'down';
    var color = props.color ? props.color : getColor('g900')

    if (color.length < 7) {
        color = getColor(color)
    }

    const transparent = `${Hp(size)}px solid transparent`
    const side = `${Hp(size)}px solid ${color}`
    var bLeft = null
    var bRight = null
    var bTop = null
    var bBottom = null
    if (d === 'up' || d === 'down') {
        bLeft = transparent
        bRight = transparent
        if (d === 'up') {
            bBottom = side
        } else {
            bTop = side
        }
    } else {
        bTop = transparent
        bBottom = transparent
        if (d === 'right') {
            bLeft = side
        } else {
            bRight = side
        }
    }

    return (
        <SDiv
            // CSS triangle
            width={0}
            height={0}
            borderLeft={bLeft}
            borderRight={bRight}
            borderTop={bTop}
            borderBottom={bBottom}
        />
    )
}