import React, {useState, useEffect} from "react";

import {Wp, Hp} from "utils/util";
import {SDiv} from "components/library/styledhtml/SDiv";
import {SInput} from "components/library/styledhtml/SInput";
import {SImg} from "components/library/styledhtml/SImg";
import {SButton} from "components/library/styledhtml/SButton";

import {rgbToHex} from "utils/util";
import {Spinner} from "components/library/common/Spinner";
import {getUrlBytes, useApi} from "api/api";
import {rawToken} from "models/security/token";


function randLetter(index=null) {

    const alletterupper =  [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ]
    if (index || index === 0) {
        return alletterupper[index]
    }
    else {
        return alletterupper[Math.round(Math.random()*alletterupper.length)]
    }
}

const THEMECOLORS = {
    'base': [0, 0, 0],
    'sugarsweets': [232, 70, 134],
    'heatwave': [251, 76, 1],
    'daisygarden': [177, 192, 24],
    'seascape': [0, 114, 122],
    'summerwarmth': [247, 228, 64],
    'bythepool': [80, 135, 243],
    'duskfalling': [142, 74, 219],
    'frogideas': [93, 214, 75],
    'berrypie': [248, 0, 6]
}


function genUrl({hexa16=false, theme='sugarsweets', num=4, size=60, name='random'}) {
    if (!name) {name='random'}
    var hexa = 'hexa'
    if (!hexa16 || hexa16 === 'false') {
        hexa = 'hexa'
    }
    else {
        hexa = 'hexa16'
    }
    //var base = `http://tinygraphs.com/labs/isogrids/${hexa}/${name}`
    var base = `http://simpaavatars.herokuapp.com/labs/isogrids/${hexa}/${name}`
    var config = `?theme=${theme}&numcolors=${num}&size=${size}&fmt=svg`
    return base + config
}

function ThemeButton(props) {

    const theme = props.theme
    const active = props.active
    const setActive = props.setActive


    return(
        <SButton
            secondary={theme !== active} width={Wp(70)} height={Hp(20)}
            font={'b4'}
            onClick={() => setActive(theme)}
        >{theme}</SButton>
    )
}


function TButton(props) {

    const theme = props.theme
    const active = props.active
    const setActive = props.setActive

    return(
        <SDiv
            color={rgbToHex(...THEMECOLORS[theme])}
            borderColor={theme === active ? 'b500' : 'g0'}
            borderWidth={4}
            height={Hp(26)} width={Hp(26)}
            borderRadius={8} onClick={() => setActive(theme)}
        />
    )
}


function StyleButton(props) {

    const num = props.num
    const active = props.active
    const setActive = props.setActive

    return(
        <SDiv
            //color={rgbToHex(...THEMECOLORS[theme])}
            borderColor={num === active ? 'b500' : 'g100'}
            height={Hp(26)} width={Hp(26)}
            borderRadius={8} onClick={() => setActive(num)}
        >
            {num}
        </SDiv>
    )
}

function SubTitle(props) {

    return (
        <SDiv>
            <SDiv direction={'row'}>
                <SDiv width={Wp(10)}/>
                <SDiv>{props.name}</SDiv>
            </SDiv>
            <SDiv height={Hp(10)}/>
        </SDiv>
    )
}

function avatarId(theme, num, name, hexa16) {
    return [theme, num, name, `${hexa16}`].join(':')
}

function AvatarSelect(props) {

    const num = props.num
    const hexa16 = props.hexa16 ? props.hexa16 : false
    const name = props.name
    const theme = props.theme
    const selAvatar = props.selAvatar
    const setSelAvatar = props.setSelAvatar
    const avId = avatarId(theme, num, name, hexa16)


    return (
        <SImg
            src={genUrl({num: num, name: name, theme: theme, hexa16: hexa16})}
            width={Hp(60)} height={Hp(60)}
            borderColor={avId === selAvatar ? 'b500' : 'g0'}
            onClick={() => setSelAvatar(avatarId(theme, num, name, hexa16))}
        />

    )
}




export function AvatarGen(props) {

    const names = []
    for (let i=0; i<10; i++) {
        const subname = []
        for (let j=0; j<6; j++) {
            subname.push(randLetter())
        }
        names.push(subname.join(''))
    }

    const TINYTHEMES = Object.keys(THEMECOLORS)
    const [name, setName] = useState(names[0])
    const [initNames, setInitNames] = useState(names)
    const [theme, setTheme] = useState(TINYTHEMES[1])
    const [stylenum, setStylenum] = useState(0)
    const [active, setActive] = useState(false)
    const [selAvatar, setSelAvatar] = useState('')

    const [storeAvatar, callStoreAvatar] = useApi('SimpaService', 'store_avatar', true, true);
    const [svgUrl, setSvgUrl] = useState(null)
    const [saving, setSaving] = useState(false)

    function saveAvatar() {

        setSaving(true)
        const v = selAvatar.split(':')
        if (v.length !== 4) {return}
        let [th, num, nm, hex] = v
        const url = genUrl({num: num, name: nm, theme: th, hexa16: hex})
        const params = {token: rawToken(), url: url};
        callStoreAvatar(params)
    }

    useEffect(() => {
        if (storeAvatar.data) {
            let svg = storeAvatar.data.svg
            let blob = new Blob([svg], {type: 'image/svg+xml'});
            let url = URL.createObjectURL(blob);
            setSvgUrl(url)
            //setSvgBytes(storeAvatar.data.svg)
            console.log(storeAvatar.data.svg)
            setSaving(false)
        }
    }, [storeAvatar.counter]);


    useEffect(() => {
        setName(initNames[stylenum])
        setSelAvatar(null)
    }, [stylenum, theme])

    function done() {
        saveAvatar()
        // store avatar
    }

    const colorBoxes = []
    for (let i=0; i<10; i++) {
        colorBoxes.push(<SDiv key={i+100} width={Wp(5)}/>)
        colorBoxes.push(<TButton key={i} theme={TINYTHEMES[i]} active={theme} setActive={setTheme}/>)
    }

    const styleBoxes = []
    for (let i=0; i<10; i++) {
        styleBoxes.push(<SDiv key={i+100} width={Wp(5)}/>)
        styleBoxes.push(<StyleButton key={i} num={i} active={stylenum} setActive={setStylenum}/>)
    }

    return (
        <SDiv
            height={Hp(0)} width={Wp(0)}
            valign={'start'} halign={'start'}
        >
            <SDiv height={Hp(30)}/>
            <SDiv direction={'row'} width={Wp(0)}>
                <SDiv width={Wp(10)}/>
                <SDiv font={'h2'}>Select an avatar</SDiv>
            </SDiv>
            <SDiv height={Hp(20)}/>
            <SubTitle name={'Select color theme:'}/>
            <SDiv direction={'row'} width={Wp(0)}>
                <SDiv width={Wp(10)}/>
                {colorBoxes}
            </SDiv>
            <SDiv height={Hp(20)}/>
            <SubTitle name={'Select style:'}/>
            <SDiv direction={'row'} width={Wp(0)}>
                <SDiv width={Wp(10)}/>
                {styleBoxes}
            </SDiv>
            <SDiv height={Hp(20)}/>
            <SubTitle name={'Select avatar:'}/>
            <SDiv direction={'row'} height={Hp(60)} width={Wp(0)} halign={'around'}>
                <AvatarSelect hexa16={false} num={2} theme={theme} name={name} selAvatar={selAvatar} setSelAvatar={setSelAvatar}/>
                <AvatarSelect hexa16={false} num={3} theme={theme} name={name} selAvatar={selAvatar} setSelAvatar={setSelAvatar}/>
                <AvatarSelect hexa16={false} num={4} theme={theme} name={name} selAvatar={selAvatar} setSelAvatar={setSelAvatar}/>
            </SDiv>
            <SDiv height={Hp(20)}/>

            <SDiv direction={'row'} height={Hp(60)} width={Wp(0)} halign={'around'}>
                <AvatarSelect hexa16={true} num={2} theme={theme} name={name} selAvatar={selAvatar} setSelAvatar={setSelAvatar}/>
                <AvatarSelect hexa16={true} num={3} theme={theme} name={name} selAvatar={selAvatar} setSelAvatar={setSelAvatar}/>
                <AvatarSelect hexa16={true} num={4} theme={theme} name={name} selAvatar={selAvatar} setSelAvatar={setSelAvatar}/>
            </SDiv>
            <SDiv direction={'row'} width={Wp(0)} height={Hp(20)} valign={'end'}>
                <SDiv font={'b5'}>By TinyGraphs</SDiv>
            </SDiv>
            <SDiv height={Hp(40)}/>
            <SDiv width={Wp(0)}>
                {saving && <Spinner/>}
                {!saving &&
                <SButton
                    width={Wp(327)} height={Hp(48)}
                    onClick={() => done()}
                    disabled={!selAvatar}
                >
                    Save
                </SButton>
                }
            </SDiv>

        </SDiv>
    )
}