import React from "react";

import {SDiv} from "components/library/styledhtml/SDiv";

export function SuggestionsHome(props) {

    return (
        <SDiv>
            Suggestions page
        </SDiv>
    )
}