import React, {useState} from "react";
import {navigate} from "@reach/router";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SButton} from "components/library/styledhtml/SButton";
import {deleteToken} from "models/security/token";

import {Hp, Wp} from "utils/util";
import {SetPassword} from "components/pages/profile/SetPassword";
import {DeleteAccountConfirm} from "components/pages/profile/DeleteAccountConfirm";

import {MainHeader} from "components/library/nav/MainHeader";
import {APP, PROFILE} from "components/paths";


export function ProfileHome(props) {

    const [delOpen, setDelOpen] = useState(false)
    const [passwordOpen, setPasswordOpen] = useState(false)

    function logout() {
        deleteToken()
        navigate('/')
    }

    return (
        <SDiv valign={'start'}>
            <MainHeader position={'relative'} showProfile={false}/>
            <SDiv height={Hp(20)}/>
            <SButton
                height={Hp(30)} width={Wp(200)}
                onClick={() => navigate(`/${APP}/${PROFILE}/edit`)}
            >
                Personal Info
            </SButton>
            <SDiv height={Hp(30)}/>
            <SButton
                height={Hp(30)} width={Wp(200)}
                onClick={() => navigate(`/${APP}/${PROFILE}/personalize`)}
            >
                Personalize Hand
            </SButton>
            <SDiv height={Hp(30)}/>
            <SButton
                height={Hp(30)} width={Wp(200)}
                onClick={() => navigate(`/${APP}/${PROFILE}/restrictions`)}
            >
                Dietary Restrictions
            </SButton>
            <SDiv height={Hp(30)}/>
            <SButton
                height={Hp(30)} width={Wp(200)}
                onClick={() => navigate(`/${APP}/${PROFILE}/nutrientorg`)}
            >
                Nutrient Organization
            </SButton>
            <SDiv height={Hp(30)}/>
            <SButton height={Hp(30)} width={Wp(200)}
                     onClick={() => logout()}
            >
                Log out
            </SButton>
            <SDiv height={Hp(30)}/>
            <SButton height={Hp(30)} width={Wp(200)}
                     onClick={() => setPasswordOpen(true)}
            >
                Set new password
            </SButton>
            <SetPassword isOpen={passwordOpen} setOpen={setPasswordOpen}/>
            <SDiv height={Hp(30)}/>
            <SDiv height={Hp(30)}/>
            <SButton height={Hp(30)} width={Wp(200)} color={'danger'}
                     onClick={() => setDelOpen(true)}
            >
                Delete account
            </SButton>
            <DeleteAccountConfirm isOpen={delOpen} setOpen={setDelOpen}/>
        </SDiv>
    )
}