import React, {useState, useContext} from "react"
import "konva/lib/shapes/Image"
import "konva/lib/shapes/Circle"
import "konva/lib/shapes/Rect"

import {SDiv, SButton} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";
import {ModalAmountInput} from "components/library/inputs/ModalAmountInput";
import {InputPosSlider} from "components/library/inputs/InputPosSlider";
import {CancelSaveButton} from "components/library/common/buttons/CancelSaveButton";

import {VolumeDisplaySelect} from "components/library/volume/VolumeDisplaySelect";
import {HandSphere, optimizePosition} from "components/library/volume/HandSphere";
import {computeMl, computeVolumeUnit, roundVolume} from "components/library/volume/units";
import {GlobalStoreContext} from "models/rootStore";

import {Checkmark} from "grommet-icons";


export function VolumeEstimator(props) {

    const gstore = useContext(GlobalStoreContext)
    const userHandLenCm = gstore.profile.handLen

    const height = props.height
    const width = props.width
    const volume = props.volume
    const setVolume = props.setVolume

    const [modalDisplay, setModalDisplay] = useState(false)

    const initPos = optimizePosition(volume, userHandLenCm)
    const [curPos, setCurPos] = useState(initPos)

    function setNewVolume(value) {
        const mlValue = computeMl(unit, value)
        setCurPos(optimizePosition(mlValue, userHandLenCm))
    }

    var initUnit = null
    if (volume < 100) {initUnit = 'Tbl'}
    else if (volume < 1000) {initUnit = 'cup'}
    else{initUnit = 'quart'}
    const [unit, setUnit] = useState(initUnit)

    function closeModal() {
        setModalDisplay(false)
    }

    const sliderH = 0.22 * height
    const volumeDispH = 0.5 * sliderH

    const sphereHeight = height - sliderH - volumeDispH

    return (
        <SDiv height={height} width={width} valign={'end'}>
            <HandSphere
                height={sphereHeight} width={width}
                //volume={volume} setPos={setCurPos}
                pos={curPos} setVolume={setVolume}
                userFingLenCm={userHandLenCm} unit={unit}
            />
            <VolumeDisplaySelect
                height={volumeDispH} width={width}
                volume={volume} unit={unit} setUnit={setUnit}
                setModalDisplay={setModalDisplay}
            />
            {!modalDisplay &&
                <InputPosSlider
                    height={sliderH} width={width}
                    initPos={curPos} setPos={setCurPos}
                />
            }
            {modalDisplay &&
            <ModalAmountInput
                onClose={closeModal} onSave={setNewVolume}
                unit={unit} title={'Edit amount'}
                subText={`Current amount: ${roundVolume(computeVolumeUnit(unit, volume))} ${unit}`}
            />}
        </SDiv>
    )

}
