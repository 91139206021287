
import jwtDecode from 'jwt-decode'
import {flow, onSnapshot, types} from "mobx-state-tree"
import {navigate} from "@reach/router";
import {callAuthentication} from "api/api";

const tokenName = 'simpatoken'


const Token = types
    .model({
        jwt: types.optional(types.string, ""),
        state: types.optional(types.enumeration("State", ["init", "pending", "done", "error"]), 'init'),
        lastCheck: types.optional(types.number,  new Date().getTime())
    })
    .actions(self => ({
        setJwt(jwt) {
            self.jwt = getJwtStr(jwt)
        },
        deleteJwt(jwt) {
            self.jwt = ''
        },
        apiValidate: flow(function* apiValidate() {
            self.state = "pending";
            const curutc = new Date().getTime()  // milliseconds

            if (!self.jwt) {
                console.log("Nav home 1")
                navigate(`/`)
            }
            // rate limit checks to 1 per 5 seconds
            if (curutc - self.lastCheck < 5000) {
                return
            }
            try {
                self.lastCheck = curutc
                // ... yield can be used in async/await style
                const ret = yield callAuthentication(
                    'check_token',
                    {token: self.jwt, service_name: ''}, true)
                self.state = "done"
                if (ret.success !== true) {
                    self.deleteJwt()
                    console.log("Nav home 2")
                    navigate(`/`)
                }
            } catch (error) {
                // ... including try/catch error handling
                self.state = "error"
            }
        }),
    }))
    .views( self => ({
        isJwtValid(validAccess=[], basic=false) {

            var valid = false
            var decodeJwt
            try {
                try {
                    decodeJwt = jwtDecode(self.jwt);
                }
                catch {
                    console.log("Token decode error")
                    return false
                }
                const msJwtTime = 1000 * decodeJwt.exp
                // Date.now() is in milliseconds but jwt is in seconds
                if (msJwtTime < Date.now()) {
                    //console.log("Expired JWT token")
                    throw "Expired JWT token"
                }
                const tokenAccess = decodeJwt.access
                if (basic) {valid = true}
                for (let i=0; i<validAccess.length; i++) {
                    if (tokenAccess.match(validAccess[i])) {
                        valid = true
                        break
                    }
                }
                if (!valid) {
                    //console.log("Invalid access specified:", tokenAccess)
                }
            }
            catch {
                return false
            }
            return valid
        },

    }));


// Set up initial state and storing to local storage
let initialState = {
    jwt: '',
};


if (localStorage.getItem(tokenName)) {
    // Load temporary copy to check if shape has changed
    const json = JSON.parse(localStorage.getItem(tokenName))
    if (Token.is(json)) {
        initialState = json
    }
    else {
        //console.log('profile not accepted')
    }
}

export let jwtState = Token.create(initialState);

onSnapshot(jwtState, snapshot => {
    localStorage.setItem(tokenName, JSON.stringify(snapshot))
});


function getJwtStr(jwt) {

    var token_str = jwt
    try {
        jwtDecode(jwt);
    }
    catch (e) {
        try {
            token_str = String.fromCharCode.apply(null, jwt)
            jwtDecode(token_str);
        }
        catch {
            token_str = ''
            //console.log('could not decode jwt', jwt)
        }
    }
    return token_str
}


export function deleteToken() {
    jwtState.deleteJwt()
}


export function rawToken() {
    return jwtState.jwt
}

export function isTokenValid(validAccess=[], basic=false) {
    return jwtState.isJwtValid(validAccess, basic)
}

export function apiValidateToken() {
    return jwtState.apiValidate()
}

export function getToken(validAccess=[]) {

    if (!jwtState.isJwtValid(validAccess)) {
        console.log("Nav home 3")
        navigate(`/`)
    }
    return jwtState.jwt
}

export function setNewToken(jwt_token) {
    jwtState.setJwt(jwt_token)
}
