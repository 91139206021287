/*
Interesting idea if we need to distinguish between physical and virtual keyboards
https://lyosha.me/blog/2016/02/12/detecting-virtual-keyboards/
Also, nice little hook for key press
https://usehooks.com/useKeyPress/
 */
import React, {useEffect, useRef, useState} from "react";
import {Group, Layer, Image, Rect, Stage, Text} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Image"
import "konva/lib/shapes/Text"
import "konva/lib/shapes/Rect"

import {animated, Spring} from 'react-spring/renderprops-konva';
import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {stheme} from "themes/theme";
import {icons} from "utils/images";

import useImage from "use-image";
import back_arrow from "images/tmpimages/back_dark_grey.png"
import shift_arrow from "images/tmpimages/shift_button.png";

const cornerRadius = Hp(4)
const keyBackground = stheme.color.greys.g50
const altKeyColor =  stheme.color.greys.g100
const shadowColor = stheme.color.greys.g200


function Key(props) {

    const twidth = props.width
    const theight = props.height
    const origX = props.x
    const origY = props.y
    const letter = props.letter
    const keySelect = props.keySelect
    const row = props.row

    var clickOffset = 0.2*theight
    if (row > 0) {
        clickOffset = 0.8*theight
    }

    var w = twidth
    var h= theight
    var x = origX
    var y = origY
    var offsetX = 0
    var offsetY = 0
    var vAlign = 'middle'
    var fillcolor = 'white'
    var fontSize = 24
    var textColor = stheme.color.greys.g800

    var displayLetter = letter
    if (displayLetter.toLowerCase() === 'space') {
        displayLetter = letter.toLowerCase()
        fontSize = 18
    }
    if (displayLetter.toLowerCase() === 'return') {
        displayLetter = letter.toLowerCase()
        fillcolor = stheme.color.brands.b500
        textColor = stheme.color.greys.g0
        fontSize = 18
    }

    if (keySelect === letter) {
        // fillcolor = stheme.color.brands.b50
        if (displayLetter.toLowerCase() === 'space') {fillcolor = 'lightgrey'}
        else if (displayLetter.toLowerCase() === 'return') {
            fillcolor = 'white'
            textColor = stheme.color.greys.g800
        }
        else {
            const newWidth = twidth * 1.2
            w = newWidth
            h = theight + clickOffset
            offsetY = clickOffset
            x = origX+0.5*(twidth-newWidth)
            vAlign = 'top'
        }
    }

    return(
        <Group>
            <Rect
                x={x}
                y={y}
                offsetX={offsetX}
                offsetY={offsetY}
                width={w}
                height={h}
                fill={fillcolor}
                shadowBlur={4}
                shadowColor={shadowColor}
                shadowOffsetX={1}
                shadowOffsetY={1}
                cornerRadius={cornerRadius}
            />
            <Text
                fontSize={fontSize}
                fill={textColor}
                x={x}
                y={y}
                offsetX={offsetX ? offsetX - 0.3*clickOffset : 0}
                offsetY={offsetY ? offsetY - 0.2*clickOffset : 0}
                width={w}
                height={h}
                align={'center'}
                verticalAlign={vAlign}
                text={displayLetter}
            />
        </Group>
    )
}


export function Keyboard(props) {

    const theight = props.height
    const twidth = props.width

    const updateValue = props.updateValue

    const [upper, setUpper] = useState(false)
    const [delPress, setDelPress] = useState(false)
    const [enterPress, setEnterPress] = useState(false)
    const [selectKey, setSelectKey] = useState(null)
    const [delCount, setDelCount] = useState(0)

    const [imgBack] = useImage(back_arrow)
    const [imgShift] = useImage(shift_arrow)

    function letterPress(letter) {
        if (updateValue) {
            if (letter.toLowerCase() === 'space') {updateValue(' ')}
            else {updateValue(letter)}
        }
    }

    const wSpace = twidth * 0.018
    // height space is 50% larger than side space
    const hSpace = 0.03 * theight

    // 10 letters at top determines key spacing
    const sideGap = 0.5 * wSpace
    const topGap = 0.04 * theight
    const bottomGap = 0.5*topGap

    const totalWspace = 2 * sideGap + 9 * wSpace
    const totalHSapce = bottomGap + topGap + 3 * hSpace
    // divide key width evenly
    const kw = (twidth - totalWspace) / 10
    const kh = (theight - totalHSapce) / 4

    const spaceWidth = 4*kw + 3*hSpace

    // variables to detect which key
    const endRow1 = topGap + kh + 0.5 * hSpace
    const endRow2 = endRow1 + kh + hSpace
    const endRow3 = endRow2 + kh + hSpace
    const center = twidth / 2

    useEffect(() => {
        if (delCount > 0 && delPress) {
            letterPress('del')
            var delay = 600
            if (delCount > 0) {
                delay = Math.max(50, delay - delCount * 100)
            }
            setTimeout(function(){
                if (delPress) {
                    setDelCount(delCount + 1)
                }
            }, delay);
        }
    }, [delCount]);


    function xStartPos(row) {
        var extraPad = 0
        if (row > 0) {extraPad = sideGap + 0.5 * kw}
        if (row === 2) {extraPad = sideGap + 1.5 * kw + hSpace}
        //if (row === 3) {extraPad = sideGap + 1.5 * kw + hSpace}// - 0.5 * kw}
        return sideGap + extraPad
    }

    function getKey(letter, row, col) {

        const xStart = xStartPos(row)

        var keywidth = kw
        if (letter.toLowerCase() === 'space') {
            keywidth = spaceWidth
        }
        if (letter.toLowerCase() === 'return') {
            keywidth = 2*kw + hSpace
        }

        const xpos = xStart + col * (kw + wSpace)
        const ypos = topGap + row * (kh + hSpace)
        return (
            <Key letter={upper ? letter.toUpperCase() : letter}
                 width={keywidth} height={kh} x={xpos} y={ypos}
                 row={row} keySelect={selectKey}
            />
        )
    }

    function getShift() {

        const xpos = sideGap
        const ypos = topGap + 2 * kh + 2 * hSpace
        const w = 1.33 * kw
        return (
            <Group>
                <Rect
                    x={xpos}
                    y={ypos}
                    width={w}
                    height={kh}
                    fill={upper ? 'white' : altKeyColor}
                    shadowBlur={4}
                    shadowColor={shadowColor}
                    shadowOffsetX={1}
                    shadowOffsetY={1}
                    cornerRadius={cornerRadius}
                />
                <Image
                    image={imgShift}
                    rotation={0}
                    x={xpos + 0.25*w}
                    y={ypos + 0.25*kh}
                    width={0.5*w}
                    height={0.5*kh}
                />
            </Group>
        )
    }

    function getDel() {

        const xpos = sideGap + 9 * (kw + wSpace) - 1.5 * wSpace
        const ypos = topGap + 2 * kh + 2 * hSpace
        const w = 1.33 * kw
        return (
            <Group>
                <Rect
                    x={xpos}
                    y={ypos}
                    width={w}
                    height={kh}
                    fill={delPress ? 'white' : altKeyColor}
                    shadowBlur={4}
                    shadowColor={shadowColor}
                    shadowOffsetX={1}
                    shadowOffsetY={1}
                    cornerRadius={cornerRadius}
                />
                <Image
                    image={imgBack}
                    x={xpos + 0.25*w}
                    y={ypos + 0.25*kh}
                    width={0.5*w}
                    height={0.5*kh}
                />
            </Group>
        )
    }

    function whichKey(pos) {

        var row = 3
        if (pos.y < endRow1) {row=0}
        else if (pos.y < endRow2) {row=1}
        else if (pos.y < endRow3) {row=2}

        const xStart = xStartPos(row)


        if (row === 0) {
            const spos = xStart + (wSpace/2 + kw)
            if (pos.x < spos) {return 'q'}
            else if (pos.x < spos + (wSpace + kw)) {return 'w'}
            else if (pos.x < spos + 2*(wSpace + kw)) {return 'e'}
            else if (pos.x < spos + 3*(wSpace + kw)) {return 'r'}
            else if (pos.x < spos + 4*(wSpace + kw)) {return 't'}
            else if (pos.x < spos + 5*(wSpace + kw)) {return 'y'}
            else if (pos.x < spos + 6*(wSpace + kw)) {return 'u'}
            else if (pos.x < spos + 7*(wSpace + kw)) {return 'i'}
            else if (pos.x < spos + 8*(wSpace + kw)) {return 'o'}
            else {return 'p'}
        }
        if (row === 1) {
            const spos = xStart + (wSpace/2 + kw)
            if (pos.x < spos) {return 'a'}
            else if (pos.x < spos + (wSpace + kw)) {return 's'}
            else if (pos.x < spos + 2*(wSpace + kw)) {return 'd'}
            else if (pos.x < spos + 3*(wSpace + kw)) {return 'f'}
            else if (pos.x < spos + 4*(wSpace + kw)) {return 'g'}
            else if (pos.x < spos + 5*(wSpace + kw)) {return 'h'}
            else if (pos.x < spos + 6*(wSpace + kw)) {return 'j'}
            else if (pos.x < spos + 7*(wSpace + kw)) {return 'k'}
            else {return 'l'}
        }
        if (row === 2) {

            const spos = xStart - wSpace/2
            if (pos.x < spos) {return 'shift'}
            else if (pos.x < spos + (wSpace + kw)) {return 'z'}
            else if (pos.x < spos + 2*(wSpace + kw)) {return 'x'}
            else if (pos.x < spos + 3*(wSpace + kw)) {return 'c'}
            else if (pos.x < spos + 4*(wSpace + kw)) {return 'v'}
            else if (pos.x < spos + 5*(wSpace + kw)) {return 'b'}
            else if (pos.x < spos + 6*(wSpace + kw)) {return 'n'}
            else if (pos.x < spos + 7*(wSpace + kw)) {return 'm'}
            else {return 'del'}
        }
        if (row === 3) {
            const spos = xStart + (wSpace/2 + kw)
            if (pos.x < spos) {return ':'}
            else if (pos.x < spos + (wSpace + kw)) {return '.'}
            //else if (pos.x < spos + 2*(wSpace + kw)) {return 'space'}
            //else if (pos.x < spos + 3*(wSpace + kw)) {return 'space'}
            //else if (pos.x < spos + 4*(wSpace + kw)) {return 'space'}
            else if (pos.x < spos + 5*(wSpace + kw)) {return 'space'}
            else if (pos.x < spos + 6*(wSpace + kw)) {return ','}
            //else if (pos.x < spos + 7*(wSpace + kw)) {return 'return'}
            else {return 'return'}
        }
        return null
    }

    function touchStart(e) {

        var stage = e.target.getStage();
        const pos = stage.getPointerPosition();
        var sel_letter = whichKey(pos)
        if (!sel_letter) {return}

        if (sel_letter.toLowerCase() === 'del') {sel_letter = 'del'}
        else if (sel_letter.toLowerCase() === 'return') {sel_letter = 'return'}
        else if (sel_letter.toLowerCase() === 'shift') {sel_letter = 'shift'}
        else {
            sel_letter = upper ? sel_letter.toUpperCase() : sel_letter
        }

        if (sel_letter === 'shift') {
            setUpper(!upper)
        }
        else if (sel_letter === 'return') {
            setEnterPress(true)
            setTimeout(function(){
                setEnterPress(false)
            }, 200);
        }
        else if (sel_letter.toLowerCase() === 'del') {
            setDelPress(true)
            setDelCount(1)
        }
        else {
            setSelectKey(sel_letter)
        }
    }

    function touchEnd(e) {

        setDelCount(0)
        var stage = e.target.getStage();
        const pos = stage.getPointerPosition();
        var sel_letter = whichKey(pos)
        if (!sel_letter) {return}
        sel_letter = upper ? sel_letter.toUpperCase() : sel_letter
        if (sel_letter.toLowerCase() === 'return' && enterPress) {
            letterPress('enter')
        }
        else if (sel_letter === selectKey) {
            letterPress(sel_letter)
        }
        if (sel_letter.toLowerCase() !== 'shift') {
            setUpper(false)
        }
        setEnterPress(false)
        setDelPress(false)
        setSelectKey(null)
    }

    // prevents click events on keyboard from bubbling to allow proper de-focus
    function blockClick(e) {
        e.stopPropagation()
    }

    return (
        <SDiv
            width={twidth} height={theight}
            position={'fixed'} top={window.innerHeight-theight}
            onClick={(e) => blockClick(e)}
            zIndex={'0'}
        >
            <Stage width={twidth} height={theight}
            >
                <Layer
                    y={0}
                    onTouchStart={touchStart}
                    onTouchEnd={touchEnd}
                    onTouchCancel={() => setSelectKey(null)}
                    onMouseDown={touchStart}
                    onMouseUp={touchEnd}
                    //onMouseOut={touchEnd}
                >
                    <Rect width={twidth} height={theight} fill={keyBackground}/>
                    {getShift()}
                    {getDel()}
                    {getKey('q', 0, 0)}
                    {getKey('w', 0, 1)}
                    {getKey('e', 0, 2)}
                    {getKey('r', 0, 3)}
                    {getKey('t', 0, 4)}
                    {getKey('y', 0, 5)}
                    {getKey('u', 0, 6)}
                    {getKey('i', 0, 7)}
                    {getKey('o', 0, 8)}
                    {getKey('p', 0, 9)}

                    {getKey('a', 1, 0)}
                    {getKey('s', 1, 1)}
                    {getKey('d', 1, 2)}
                    {getKey('f', 1, 3)}
                    {getKey('g', 1, 4)}
                    {getKey('h', 1, 5)}
                    {getKey('j', 1, 6)}
                    {getKey('k', 1, 7)}
                    {getKey('l', 1, 8)}

                    {getKey('z', 2, 0)}
                    {getKey('x', 2, 1)}
                    {getKey('c', 2, 2)}
                    {getKey('v', 2, 3)}
                    {getKey('b', 2, 4)}
                    {getKey('n', 2, 5)}
                    {getKey('m', 2, 6)}

                    {getKey(':', 3, 0)}
                    {getKey('.', 3, 1)}
                    {getKey('space', 3, 2)}
                    {getKey(',', 3, 6)}
                    {getKey('return', 3, 7)}

                </Layer>
            </Stage>
        </SDiv>
    )
}


