import React, {useState} from "react";

import {SDiv, SInput} from "components/library/styledhtml";
import {SModal} from "components/library/basic/SModal";
import {Hp, Wp} from "utils/util";
import {VolumeEstimator} from "components/library/volume/VolumeEstimator";
import {ConsumableSearch} from "components/library/intake/viewIntake/ConsumableSearch";
import {CancelSaveButton} from "components/library/common/buttons/CancelSaveButton";
import {observer} from "mobx-react-lite";


export const EditDesc = observer((props) => {

    const width = props.width
    const height = props.height
    const concomp = props.concomp

    const sidePad = Wp(40)
    const subwidth = width-2*sidePad
    return (
        <SDiv
            height={height} width={subwidth}
            halign={'start'} valign={'start'}
            paddingLeft={sidePad} paddingRight={sidePad}
        >
            <SDiv height={0.5*height} font={'b1'} textColor={'g500'}>
                Description
            </SDiv>
            <SInput
                width={subwidth} height={0.5*height}
                value={concomp.desc} onChange={(e) => concomp.setDesc(e.target.value)}
                font={'b3'}
            />
        </SDiv>

    )
})


function EditName(props) {

    const width = props.width
    const height = props.height
    const name = props.name
    const setFocus = props.setFocus
    const title = props.title
    const nameOff = props.nameOff

    function clickEdit(e) {
        e.stopPropagation()
        setFocus(true)
    }

    const sidePad = Wp(40)
    const subwidth = width-2*sidePad
    return (
        <SDiv
            height={height} width={subwidth}
            halign={'start'} valign={'start'}
            paddingLeft={sidePad} paddingRight={sidePad}
            onClick={(e) => !nameOff ? clickEdit(e) : null}
        >
            <SDiv
                height={0.5*height} font={'b1'} textColor={'g500'}
            >
                {title}
            </SDiv>
            <SDiv
                width={subwidth} height={0.5*height}
                font={'b3'} textColor={'b800'}
                halign={'start'}
                borderColor={'g200'} borderStyle={'none none solid none'}
            >
                {nameOff ? 'to be determined' : name}
            </SDiv>
            <SDiv
                //height={0.1*height} width={subwidth}
                //borderColor={'g200'} borderStyle={'none none solid none'}
            />
        </SDiv>

    )
}


export function EditConComp1(props) {

    var origconcomp = props.concomp

    const onClose = props.onClose
    const nameOff = props.nameOff

    // using state here makes the volume slider much faster, as opposed to updating concomp directly
    // not sure what issue might be
    const [volume, setVolume] = useState(origconcomp.volume)
    const [concomp, setTempConcomp] = useState(origconcomp)
    const [focusDb, setFocusDb] = useState(false)

    function save() {
        concomp.setVolume(volume)
        setTempConcomp(concomp)
        if (onClose) onClose()
    }

    function select(newconcomp) {
        if (!concomp.iscomp) {
            concomp.setNameId(newconcomp.name, newconcomp.id)
            concomp.setDens(newconcomp.dens)
            concomp.setVolume(volume)
            setTempConcomp(concomp)
            //setFocusDb(false)
        }
    }

    // todo need to regulate difference between save and cancel
    function onDbSave() {
        setFocusDb(false)
    }
    function onDbCancel() {
        setFocusDb(false)
    }

    const width = Wp(0.96)
    const modalFrac = 0.97
    const availH = Hp(modalFrac) - Hp(40)
    return (
        <SModal
            topGap={Hp(1-modalFrac)}
            modalWidth={Wp(0.96)}
            valign={'start'}
            //onClose={onClose}
            title={!focusDb ? 'Edit' : 'Replace'}
            //onClick={() => setFocusDb(false)}
        >
            {!focusDb &&
            <SDiv>
                <EditDesc
                    width={width} height={0.1*availH}
                    concomp={concomp}
                />
                {concomp.iscomp && <SDiv height={0.15*availH}/>}
                {!concomp.iscomp &&
                <EditName
                    width={width} height={0.15*availH}
                    name={concomp.name} nameOff={nameOff}
                    focus={focusDb} setFocus={setFocusDb}
                    title={'Database item'}
                />
                }
                <VolumeEstimator
                    height={0.64*availH} width={width}
                    volume={volume} setVolume={setVolume}
                    onSave={save}
                />
            </SDiv>
            }
            {focusDb &&
            <SDiv>
                <SDiv
                    width={width} height={0.05*availH}
                    halign={'start'} textAlign={'start'}
                    textColor={'g600'} font={'b2'}
                    direction={'row'}
                >
                    <SDiv width={Wp(40)}/>
                    {concomp.desc}
                </SDiv>
                <EditName
                    width={width} height={0.10*availH}
                    name={concomp.name}
                    focus={focusDb} setFocus={setFocusDb}
                    title={'Current database item'}
                />
                <ConsumableSearch
                    width={width}
                    currentConcomp={concomp}
                    onSelect={select}
                />
                <CancelSaveButton
                    height={Hp(30)} width={width}
                    onCancel={onDbCancel} onSave={onDbSave}
                />
            </SDiv>
            }
        </SModal>
    )
}


export function EditConComp(props) {

    const concomp = props.concomp

    const onClose = props.onClose
    const nameOff = props.nameOff

    // using state here makes the volume slider much faster, as opposed to updating concomp directly
    // not sure what issue might be
    const [volume, setVolume] = useState( concomp.volume)
    const [currentDesc, setCurrentDesc] = useState(concomp.desc)
    const [currentName, setCurrentName] = useState(concomp.name)
    const [currentId, setCurrentId] = useState(concomp.id)
    const [currentDens, setCurrentDens] = useState(!concomp.iscomp ?  concomp.dens : 0)
    const [lastName, setLastName] = useState(currentName)
    const [lastId, setLastId] = useState(currentId)
    const [lastDens, setLastDens] = useState(currentDens)
    const [focusDb, setFocusDb] = useState(false)

    const isComposition = concomp.iscomp

    function save() {
        concomp.setDesc(currentDesc)
        concomp.setNameId(currentName, currentId)
        if (!concomp.iscomp) {
            concomp.setDens(currentDens)
        }
        concomp.setVolume(volume)
        if (onClose) onClose()
    }

    function select(newconcomp) {
        setCurrentId(newconcomp.id)
        setCurrentName(newconcomp.name)
        if (!concomp.iscomp) {
            setCurrentDens(newconcomp.dens)
        }
    }

    // todo need to regulate difference between save and cancel
    function onDbSave() {
        setFocusDb(false)
    }
    function onDbCancel() {
        setCurrentId(lastId)
        setCurrentName(lastName)
        if (!concomp.iscomp) {
            setCurrentDens(lastDens)
        }
        setFocusDb(false)
    }

    const width = Wp(0.96)
    const modalFrac = 0.98
    const availH = Hp(modalFrac) - Hp(40)
    const buttonH = Hp(40)
    const bottomPad = Hp(20)
    const descHeight = 0.08*availH
    const nameHeight = 0.1*availH
    const volDbHeight = availH - descHeight - nameHeight - buttonH - bottomPad

    const sidePad = Wp(40)
    const descSubwidth = width-2*sidePad
    return (
        <SModal
            topGap={Hp(1-modalFrac)}
            modalWidth={Wp(0.96)}
            valign={'start'}
            title={'Edit'}
            //onClose={onClose} //onClick={() => setFocusDb(false)}
        >
            <SDiv>
                <SDiv
                    height={descHeight} width={descSubwidth}
                    halign={'start'} valign={'start'}
                    paddingLeft={sidePad} paddingRight={sidePad}
                >
                    <SDiv height={0.5*descHeight} font={'b1'} textColor={'g500'}>
                        Description
                    </SDiv>
                    <SInput
                        width={descSubwidth} height={0.5*descHeight}
                        value={currentDesc} onChange={(e) => setCurrentDesc(e.target.value)}
                        font={'b2'}
                    />
                </SDiv>
                {isComposition && <SDiv height={descHeight}/>}
                {!isComposition &&
                <EditName
                    width={width} height={nameHeight}
                    name={currentName} nameOff={nameOff}
                    focus={focusDb} setFocus={setFocusDb}
                    title={'Database item'}
                />
                }
                {!focusDb &&
                <SDiv>
                    <VolumeEstimator
                        height={volDbHeight} width={width}
                        volume={volume} setVolume={setVolume}
                    />
                    <CancelSaveButton
                        height={buttonH} width={width}
                        saveName={'Update'}
                        onCancel={onClose} onSave={save}
                    />
                </SDiv>
                }
                {focusDb &&
                <SDiv>
                    <ConsumableSearch
                        height={volDbHeight} width={width}
                        currentId={currentId}
                        initValue={currentDesc ? currentDesc : currentName}
                        onSelect={select}
                    />
                    <CancelSaveButton
                        height={buttonH} width={width}
                        onCancel={onDbCancel} onSave={onDbSave}
                        saveName={'Select new'} disabled={currentId === lastId}
                    />
                </SDiv>
                }
            </SDiv>
        </SModal>
    )
}