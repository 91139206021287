import ReactGA from 'react-ga';

export const GAevent = (categoryName, eventName) => {
    ReactGA.event({
        category: categoryName,  // Required
        action: eventName,       // Required
    });
}


export const GAtiming = (categoryName, variableName, valueNum) => {
    ReactGA.timing({
        category: categoryName,
        variable: variableName,
        value: valueNum
    });
};