import React from "react";
import {Router} from "@reach/router";

import {QuestsHome} from "components/pages/quests/QuestsHome";

export function Quests(props) {

    return (
        <Router>
            <QuestsHome path={'/'}/>
        </Router>
    )
}