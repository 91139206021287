import React, {useState} from "react";

import {computeVolumeUnit, roundVolume} from "components/library/volume/units";
import {Hp, Wp} from "utils/util";
import {SDiv} from "components/library/styledhtml/SDiv";
import {SMenu} from "components/library/basic/SMenu";

const UNITS = ['ml', 'tsp', 'Tbl', 'cup', 'quart', 'gallon']


export function VolumeDisplaySelect(props) {

    const height = props.height
    const width = props.width
    const halign = props.halign
    const curVolume = props.volume
    const unit = props.unit
    const setUnit = props.setUnit
    const setModalDisplay = props.setModalDisplay

    function setModal() {
        if (setModalDisplay) {setModalDisplay(true)}
    }

    const center = Wp(80)
    const side = width - 2 * center

    return (
        <SDiv direction='row' height={height} width={Wp(0)} halign={halign}>
            <SDiv height={height} width={side}/>
            <SDiv height={height} width={center} valign={'start'}>
                <SDiv
                    height={0.8 * height} width={center}
                    borderColor={'a1'} borderRadius={0.2 * height}
                    color={'g0'}
                    onClick={() => setModal()}
                >
                    {roundVolume(computeVolumeUnit(unit, curVolume))}
                </SDiv>
            </SDiv>
            <SDiv height={height} width={side} halign={'start'}>
                <SMenu items={UNITS} direction={'up'} label={unit} onSelect={setUnit}/>
            </SDiv>
        </SDiv>

    )
}