import {flow, types} from "mobx-state-tree";
import {Message} from "models/chat/message";
import {callApi} from "api/api";
import {getToken} from "models/security/token";
import {utc} from "utils/util";


export const Conversation = types
    .model({
        messages: types.map(Message),
        state: types.optional(types.enumeration("State", ["init", "pending", "done", "error"]), 'init'),
        loadTime: types.optional(types.number, 0),
    })
    .actions(self => ({
        loadMessages: flow(function* loadMessages() {

            // only load initially
            if (self.messages.size > 0) {return}
            const max_time = utc() / 1000.;  // current time
            self.state = "pending";
            // todo once sorted in time below, do not need to clear
            //self.messages.clear()
            try {
                const retConvo = yield callApi(
                    'SimpaService', 'get_conversation_history',
                    {'token': getToken(['user']), 'max_time': max_time, 'num_messages': 60}
                );
                for (const message of retConvo.messages){
                    const m = Message.create(message)
                    if (!self.messages.has(m.id)) {
                        self.messages.set(m.id, m)
                    }
                }
                self.state = "done";
                self.loadTime = utc()
            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch composition date", error);
                self.state = "error"
            }
        }),
        sortMessages() {

        },
        addMessage(message) {
            const m = Message.create(message)
            if (!self.messages.has(m.id)) {
                self.messages.set(m.id, m)
            }
            //self.messages.clear()
            //self.loadMessages()
        },
        clear() {
            self.messages.clear()
        }
    }))
    .views( self => ({

        get numMessages() {
            return self.messages.length
        },
        get getMessages() {
            const messageArray = Array.from(self.messages.values()).reverse()
            messageArray.sort(function (a, b) {
                if (a.time < b.time) {
                    return -1
                }
                if (a.time > b.time) {
                    return 1
                }
                return 0
            })
            return messageArray
        },
        isExpired(minOffset=30) {
            return self.loadTime < utc(minOffset);
        }

    }));