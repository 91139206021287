

// Consumable
import {flow, types} from "mobx-state-tree";
import {navigate} from "@reach/router";
import {callApi} from "api/api";


export const Consumable = types
    .model({
        desc: types.optional(types.string, ""),
        name: types.optional(types.string, ""),
        id: types.optional(types.string, ""),
        g: types.optional(types.number, 1),
        dens: types.optional(types.number, 1),
        time: types.optional(types.number, 0),
        loc: types.optional(types.array(types.number), [0, 0]),
        meta: types.optional(types.map(types.string), {}),
        verified: types.optional(types.boolean, false),
        ver: types.optional(types.string, ""),
        type: types.literal('con'),
        open: types.optional(types.boolean, false),
        editOn: types.optional(types.boolean, true),
        state: types.optional(types.enumeration("State", ["init", "pending", "done", "error"]), 'init'),
    })
    .actions(self => ({
        //afterCreate(){
        //    console.log('CON', self.desc)
        //},
        setDesc(desc) {
            self.desc = desc
        },
        setDens(dens) {
            self.dens = dens
        },
        setNameId(name, id) {
            self.name = name
            self.id = id
        },
        setGrams(grams) {
            self.g = grams
        },
        setVolume(volume) {
            self.setGrams(volume * self.dens)
        },
        setIsOpen(bool) {
            self.open = bool
        },
        updateName: flow(function* updateName(restrictions=[]) {
            if (self.name) {return}
            self.state = "pending";
            const curutc = new Date().getTime()  // milliseconds
            try {
                // ... yield can be used in async/await style
                var dbs  = ['SR28']
                const params = {'srch_term': self.desc.trim(), 'max_return': 1, 'cutoff': 1, 'restrictions': restrictions, 'databases': dbs}
                const ret = yield callApi(
                    'SimpaNutrition', 'search',
                    params, true)
                self.state = "done"

                if (ret.length > 0 && ret[0].concomp) {
                    const obj = ret[0].concomp
                    self.id = obj.id
                    self.name = obj.name
                    self.dens = obj.dens
                    self.g = obj.g
                }

            } catch (error) {
                // ... including try/catch error handling
                self.state = "error"
            }
        }),
    }))
    .views( self => ({
        get grams() {
            return self.g
        },
        get volume() {
            return self.g / self.dens
        },
        get density() {
            return self.dens
        },
        get hasDesc() {
            return self.desc.length > 0
        },
        get iscomp() {
            return false
        },
        get numSubs() {
            return 0
        },
        get totalSubs() {
            return 0
        },
        get json() {
            const obj = {
                id: self.id,
                name: self.name,
                desc: self.desc,
                g: self.g,
                dens: self.dens,
                time: self.time,
                loc: self.loc.toJSON(),
                meta: self.meta.toJSON(),
                verified: self.verified,
                ver: self.ver,
                type: self.type,
            }
            return obj
        }
    }));
