
export const UNIT_CM = 'UNIT_CM';
export const UNIT_INCH = 'UNIT_INCH';
export const INCHES_PER_METER = 39.3701;
export const INCHES_PER_FEET = 12;
export const CENTIMETERS_PER_METER = 100;
export const CENTIMETERS_PER_INCH = CENTIMETERS_PER_METER / INCHES_PER_METER;
export const UNIT_LB = 'UNIT_LB';
export const UNIT_KG = 'UNIT_KG';
export const LB_PER_KG = 2.20462;
export const ML_PER_TSP = 4.928922;
export const TSP_PER_DASH = 0.125;
export const TSP_PER_TBL = 3;
export const TSP_PER_FLOZ = 6;
export const TSP_PER_CUP = 48;
export const TSP_PER_PINT = 96;
export const TSP_PER_QUART = 192;
export const TSP_PER_GALLON = 768;


export const Distance = {
	centimeter: {
		toInches: value => value / CENTIMETERS_PER_INCH,
		toFeet: value => Distance.centimeter.toInches(value) / INCHES_PER_FEET,
	},
	
	inch: {
		toCentimeters: value => value * CENTIMETERS_PER_INCH,
		toFeet: value => value / INCHES_PER_FEET,
		toFeetInchesArray: value => {
			const feet = value / INCHES_PER_FEET;
			const feetInteger = Math.floor(feet);
			const remainingInches = value % INCHES_PER_FEET;
			return [feetInteger, remainingInches];
		}
	},

	foot: {
		toInches: value => value * INCHES_PER_FEET,
		parseArrayToInches: arrayFeetInches => arrayFeetInches[0] * INCHES_PER_FEET + arrayFeetInches[1],
	}
}



export const Mass = {
	kilogram: {
		toPounds: value => value * LB_PER_KG,
	},

	pound: {
		toKiloGrams: value => value / LB_PER_KG,
	},
}

export const Volume = {
	ml: {
		toTsp: value => value / ML_PER_TSP,
	},

	tsp: {
		toMl: value => value * ML_PER_TSP,
	},

	tbl: {
		toTsb: value => value * TSP_PER_TBL,
		toMl: value => Volume.tsp.toMl(Volume.tbl.toTsb(value)),
	},

	floz: {
		toTsb: value => value * TSP_PER_FLOZ,
		toMl: value => Volume.tsp.toMl(Volume.floz.toTsb(value)),
	},

	cup: {
		toTsb: value => value * TSP_PER_CUP,
		toMl: value => Volume.tsp.toMl(Volume.cup.toTsb(value)),
	},

	pint: {
		toTsb: value => value * TSP_PER_PINT,
		toMl: value => Volume.tsp.toMl(Volume.pint.toTsb(value)),
	},

	quart: {
		toTsb: value => value * TSP_PER_QUART,
		toMl: value => Volume.tsp.toMl(Volume.quart.toTsb(value)),
	},

	gallon: {
		toTsb: value => value * TSP_PER_GALLON,
		toMl: value => Volume.tsp.toMl(Volume.gallon.toTsb(value)),
	}
}

export function computeMl(unit, curVolume) {
	switch (unit) {
		case 'ml':
			return curVolume
		case 'tsp':
			return Volume.tsp.toMl(curVolume)
		case 'Tbl':
			return Volume.tbl.toMl(curVolume)
		case 'cup':
			return Volume.cup.toMl(curVolume)
		case 'pint':
			return Volume.pint.toMl(curVolume)
		case 'quart':
			return Volume.quart.toMl(curVolume)
		case 'gallon':
			return Volume.gallon.toMl(curVolume)
		default:
	}

}


export function computeVolumeUnit(unit, curVolume) {
	switch (unit) {
		case 'ml':
			return curVolume
		case 'tsp':
			return curVolume / Volume.tsp.toMl(1)
		case 'Tbl':
			return curVolume / Volume.tbl.toMl(1)
		case 'cup':
			return curVolume / Volume.cup.toMl(1)
		case 'pint':
			return curVolume / Volume.pint.toMl(1)
		case 'quart':
			return curVolume / Volume.quart.toMl(1)
		case 'gallon':
			return curVolume / Volume.gallon.toMl(1)
		default:
	}
}


export function roundVolume(vol) {

	var newVol = vol

	if (newVol <= 0.1) {
		newVol = Math.round(newVol * 1000) / 1000
	}
	else if (newVol <= 1) {
		newVol = Math.round(newVol * 100) / 100
	}
	else if (newVol <= 10) {
		newVol = Math.round(newVol * 10) / 10
	}
	else if (newVol <= 100) {
		newVol = Math.round(newVol)
	}
	else if (newVol <= 1000) {
		newVol = Math.round(newVol * 0.1) / 0.1
	}
	else {
		newVol = Math.round(newVol * 0.01) / 0.01
	}

	const fixedInt = newVol < 100 ? newVol < 10 ? newVol < 1 ? newVol < 0.1 ? newVol < 0.01 ? 4 : 3 : 2 : 1 : 0 : 0
	return newVol.toFixed(fixedInt)

}


export function selectDisplayUnit(curVolume) {
	const quarts = (curVolume / Volume.quart.toMl(1)).toFixed(1)
	if (quarts >= 1) return 'quart'
	const cups = (curVolume / Volume.cup.toMl(1)).toFixed(1)
	if (cups >= 0.25) return 'cup'
	const tbls = (curVolume / Volume.tbl.toMl(1)).toFixed(1)
	if (tbls >= 1) return 'Tbl'
	return 'tsp'
}

export const SM = 'SMALL'
export const MD = 'MEDIUM'
export const LG = 'LARGE'