import React from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {ReactComponent as ChevronUp} from "Assets/Icons/chevron_up.svg";
import {stheme} from "themes/theme";
import {ReactComponent as ChevronDown} from "Assets/Icons/chevron_down.svg";
import {SSpan} from "components/library/styledhtml/SSpan";
import {SButton} from "components/library/styledhtml/SButton";


function SpanBlock(props) {

    const txt = props.text
    const highlightChar = props.highlightChar
    const font = props.font ? props.font : 'caption'

    const spans = []
    for (let i=0; i<txt.length; i++) {
        var c = txt.charAt(i)
        if (c === highlightChar) {
            spans.push(
                <SSpan
                    width={Wp(15)}
                    display={'inline-block'} textAlign={'center'} borderRadius={Hp(4)}
                    key={i} font={font} textColor={'g900'} color={'g0'}>{c}
                </SSpan>)
        }
        else spans.push(<SSpan key={i} font={font} textColor={'b500'}>{c}</SSpan>)
    }
    return (
        <SDiv display={'block'} height={Hp(21)}>
            {spans}
        </SDiv>
    )
}

function InstructionElement(props) {

    const width = props.width ? props.width : Wp(0)
    const val = props.val
    const mainTxt = props.mainTxt
    const exampleTxt = props.exampleTxt

    const subWidth = width - Wp(72)
    return (
        <SDiv height={Hp(42)} width={width} halign={'left'} direction={'row'}>
            <SDiv width={Wp(6)}/>
            <SDiv
                height={Hp(42)} width={Hp(42)}
                color={'g0'} borderRadius={Hp(4)} boxShadow={'0px 0px 16px rgba(0, 0, 0, 0.08)'}
                textAlign={'center'} font={val.length > 1 ? 'caption' : 'b1'}
                manualWrap
            >
                {val}
            </SDiv>
            <SDiv width={Wp(8)}/>
            <SDiv height={Hp(42)} width={subWidth}>
                <SDiv
                    height={Hp(21)} width={subWidth}
                    font={'caption'} textColor={'g600'} halign={'start'}
                >
                    {mainTxt}
                </SDiv>
                <SDiv
                    height={Hp(21)} width={subWidth} halign={'start'}>
                    <SpanBlock font={'b5'} text={exampleTxt} highlightChar={val.charAt(0)}/>
                </SDiv>
            </SDiv>
        </SDiv>
    )
}

export function InstructionsBox(props) {

    const width = props.width

    const example1 = 'apple, orange juice, oatmeal'
    const example2 = 'My Breakfast: oatmeal, orange juice.'
    const example3 = 'My Meal: My Oats: oatmeal, banana . coffee.'
    return (
        <SDiv
            width={width} halign={'start'}
            boderColor={'b800'} borderStyle={'solid'} borderRadius={Hp(10)} borderWidth={'1px'}
        >
            <SDiv height={Hp(8)}/>
            <InstructionElement width={width} val={','} mainTxt={'List and separate food names'} exampleTxt={example1}/>
            <SDiv height={Hp(8)}/>
            <InstructionElement width={width} val={':'} mainTxt={'Create and name groups'} exampleTxt={example2}/>
            <SDiv height={Hp(8)}/>
            <InstructionElement width={width} val={'.\nreturn'} mainTxt={'End groups to create sub-groups'} exampleTxt={example3}/>
            <SDiv height={Hp(8)}/>
        </SDiv>
    )
}

export function InstructionsButton(props) {

    const showInstructions = props.showInstructions
    const setShowInstructions = props.setShowInstructions
    return (
        <SButton
            height={Hp(26)} width={Wp(120)} borderRadius={Hp(10)}
            secondary={!showInstructions}
            font={'b4'}
            onClick={() => setShowInstructions(!showInstructions)}
        >
            {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
        </SButton>
    )

}