/*
BasicInput is a mock of an input using the Simpa keyboard for input
 */
import React, {useState, useEffect} from "react";
import {Hp, Wp} from "utils/util";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Keyboard} from "components/library/keyboards/Keyboard";
import {Keypad} from "components/library/keyboards/Keypad";
import {SpanBlock} from "components/library/common/text/SpanBlock";
import {ResponsiveLayout} from "components/ResponsiveLayout/responsiveLayout";
import {SImg} from "components/library/styledhtml/SImg";
import {icons} from "utils/images";
import {SArrow} from "components/library/basic/SArrow";



function textBoxHeight(numlines) {
    return Hp(25) * numlines
}


function getPhoneDisplay(initnumber) {
    var number = initnumber ? initnumber.replace(/\W/g, '') : ''
    number = number.replace(/\s/g, '')
    number = number.replace(/\D/g, '')
    if (number.length < 10) {
        number = number.padEnd(10)
    }
    return '(' + number.substring(0, 3) + ') ' +  number.substring(3, 6) + ' - ' + number.substring(6,10)
}


function PhoneDisplay(props) {

    const height = props.height
    const width = props.width
    const number = props.number

    return (
        <SDiv direction='row' height={height} width={width}
              halign={'start'} paddingLeft={10}
        >
            <SImg paddingLeft={20} height={Hp(17)} width={Wp(24)} src={icons['flag_US.svg']}/>
            <SArrow direction={'down'} size={5}/>
            <SDiv
                height={height} width={40}
                font={'b2'} textColor={'g900'}
                halign={'center'}
            >
                +1
            </SDiv>
            <SDiv
                height={height} width={0.5*width}
                font={'b2'} textColor={'g900'}
                halign={'start'}
            >
                {getPhoneDisplay(number)}
            </SDiv>
        </SDiv>
    )
}

function CoreBasicInput(props) {

    var height = props.height ? props.height : Hp(30)
    const width = props.width ? props.width : Wp(200)
    const position = props.position ? props.position : null
    const top = props.top ? props.top : Hp(2)
    const textArea = props.textArea ? props.textArea : false
    var maxLines = props.maxLines ? props.maxLines : 5
    const value = props.value
    const setValue = props.setValue
    const focus = props.focus
    const setFocus = props.setFocus
    const phone = props.phone
    const numPadWidth = props.numPadWidth
    const leftPad = props.leftPad === undefined

    const color = props.color ? props.color : 'g0'
    const focusColor = props.color ? props.color : 'g0'
    const font = props.font ? props.font : 'b2'
    var textColor = props.textColor ? props.textColor : 'g800'

    var tmpborderColor = props.borderColor ? props.borderColor : 'b500'
    const [borderColor, setBorderColor] = useState(tmpborderColor)
    const [hover, setHover] = useState(false)

    useEffect(() => {
        if (!focus) {
            if (!hover) setBorderColor('g200')
            else setBorderColor('g400')
        }
        else {setBorderColor(tmpborderColor)}
    }, [focus, hover])

    const borderStyle = props.borderStyle ? props.borderStyle : 'none none solid none'
    const defaultValue = props.defaultValue

    var keyType = props.keyType
    if (phone) {keyType = 'keypad'}
    else if (keyType !== 'keypad') {keyType = 'keyboard'}
    const [showKeyboard, setShowKeyboard] = useState(focus)

    const textSpanRef = React.createRef()
    const cursorRef = React.createRef()
    const [txtPos, setTxtPos] = useState(focus ? value.length : -1)

    function clickIn(e, idx) {
        e.stopPropagation();
        if (setFocus) setFocus(true)
        setShowKeyboard(true)
        if (setTxtPos) setTxtPos(idx)
    }

    const scrollToEnd = () => {
        // todo: in case of textArea scroll vertically to cursor
        if (!textSpanRef.current || !cursorRef.current) {return}
        const scrollAmount = width - (cursorRef.current.offsetLeft-textSpanRef.current.offsetLeft) - 5
        if (scrollAmount < 0) {
            textSpanRef.current.scrollTo(-scrollAmount,0)
        }
    }
    useEffect(scrollToEnd, [value, txtPos]);


    // If pressed key is our target key then set to true
    function downHandler(event) {
        // used to prevent backspace from causing back navigation
        event.preventDefault()
        //event.stopPropagation()
        setShowKeyboard(false)
        var key = event.key
        if (key === 'Backspace') {
            updateValue('del')
        }
        else if (key === 'Escape') {
            if (setFocus) {setFocus(false)}
            setShowKeyboard(false)
        }
        else if (key === 'Enter') {
            updateValue('\n')
        }
        else if (key === 'ArrowRight') {
            if (txtPos < value.length) {setTxtPos(txtPos+1)}
        }
        else if (key === 'ArrowLeft') {
            if (txtPos > 0) {setTxtPos(txtPos-1)}
        }
        else if (key === 'ArrowDown') {
           // todo: in case of TextArea need to push cursor down
        }
        else if (key === 'ArrowUp') {
            // todo: in case of TextArea need to push cursor up
        }
        else {
            var validchars = /^[A-Za-z ,.:0-9@]$/;
            if (keyType === 'keypad') {
                validchars = /^[.0-9]$/;
            }
            if(key.match(validchars)) {
                updateValue(key)
            }
        }
    }

    function updateValue(letter) {
        var key = letter
        if (key.toLowerCase() === 'delete') key='del'

        if (txtPos < 0) {return}
        if (txtPos === 0 && key === 'del') {return}
        if (key === 'enter') {key = '\n'}

        if (value.length === 0) {
            setValue(key)
            setTxtPos(1)
            return
        }
        if (phone && value.length === 10 && key !== 'del') {
            return
        }

        const newString = []
        if (txtPos === 0) {
            newString.push(key)
            setTxtPos(txtPos+1)
        }
        for (let i=0; i<value.length; i++) {
            if (key === 'del' && i === txtPos-1) {
                setTxtPos(txtPos-1)
            }
            else if (i === txtPos-1) {
                newString.push(value.charAt(i))
                newString.push(key)
                setTxtPos(txtPos+1)
            }
            else {
                newString.push(value.charAt(i))
            }
        }
        setValue(newString.join(''))
    }

    function getSpans(txtval) {
        if (!value && !focus) {
            // default value
            return <SDiv font={'b2'} textColor={'g400'}>{defaultValue}</SDiv>
        }
        else {
            return (
                <SpanBlock
                    ref={cursorRef} text={txtval} onClick={clickIn} fillPos={focus ? txtPos : -1}
                    font={font} textColor={textColor}
                    cursorColorOff={focusColor}
                    leftPad={leftPad}
                />
            )
        }
    }

    // setup differences between single line and multiline textArea
    var valign = 'end'
    var overflowX = 'scroll'
    var overflowY = 'none'
    var manualWrap = false
    var whiteSpace = 'nowrap'
    if (textArea) {
        valign = 'start'
        overflowX = 'none'
        overflowY = 'scroll'
        manualWrap = true
        whiteSpace = null
        // dynamic height
        const tmpHeight = textBoxHeight(value.split('\n').length)
        const maxHeight = textBoxHeight(maxLines - 1) + Hp(16)
        height = tmpHeight <= maxHeight ? tmpHeight : maxHeight
    }


    return (

        <SDiv
            position={position} top={top}
        >
            <SDiv
                ref={textSpanRef}
                height={height} width={width} direction={'row'}
                borderColor={borderColor} borderStyle={borderStyle}
                //borderRadius={Hp(2)}
                color={focus ? focusColor : color}
                font={font} textColor={textColor}
                halign={'start'} textAlign={'start'} textValign={'center'}
                valign={valign}
                overflowX={overflowX} overflowY={overflowY}
                whiteSpace={whiteSpace} manualWrap={manualWrap}
                onClick={(e) => clickIn(e, value.length)}
                onKeyDown={(e) => downHandler(e)} tabIndex={-1}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {leftPad && <SDiv width={Wp(10)} height={height}/>}
                {!phone &&
                    <SDiv>
                        {getSpans(value)}
                    </SDiv>
                }
                {phone &&
                    <PhoneDisplay height={height} width={width} number={value}/>
                }
            </SDiv>
            {(showKeyboard && focus && keyType === 'keyboard' && !phone) &&
            <Keyboard
                height={Hp(0.33)} width={Wp(0)}
                updateValue={updateValue}
            />}
            {(showKeyboard && focus && (keyType === 'keypad' || phone)) &&
            <Keypad
                height={Hp(0.33)} width={numPadWidth ? numPadWidth : Wp(0)}
                onPress={updateValue}
                fixed={true}
            />}
        </SDiv>

    )
}


export function BasicInput(props) {
    return (
        <ResponsiveLayout
            path={'/'}
            renderDesktop={() => (<CoreBasicInput {...props}/>)}
            renderMobile={() => (<CoreBasicInput {...props}/>)}
        />
    )
}
