import React from "react";
import {Router} from "@reach/router";

import {HomeMain} from "components/pages/home/HomeMain";

export function Home(props) {

    return (
        <Router>
            <HomeMain path={'/'}/>
        </Router>
    )
}