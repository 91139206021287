import React, {useRef, useState} from "react";

import {Circle, Layer, Rect, Stage} from "react-konva";
import {stheme} from "themes/theme";


export function InputPosSlider(props) {

    const height = props.height
    const width = props.width
    const vertical = props.vertical
    const passive = props.passive ? props.passive : false

    var pos = props.initPos
    if (passive && !(pos >= 0)) {pos = 0.5}
    const [sliderPos, setSliderPos] = useState(pos)
    const setPos = passive ? null : props.setPos

    const circleRef = useRef(null)

    // create variables that are meaningful in either orientation
    var length = width
    var thickness = height
    if (vertical) {
        length = height
        thickness = width
    }

    var slideLength = 0.84 * length
    var slideSt = 0.5 * (length - slideLength)
    const slideThinkness = 0.05 * height
    var circleDim
    if (width > height) {circleDim = height}
    else (circleDim = width)
    var circleSize = 0.55 * circleDim

    var usePos = sliderPos
    if (passive) {
        usePos = pos
    }
    usePos = Math.max(Math.min(1, usePos), 0)

    const circlePos = usePos * slideLength
    const thicknessPosition = 0.5 * thickness - 0.5 * slideThinkness

    function touchPos(e) {
        if (passive || !circleRef.current) {return}
        var curSlidePos = circleRef.current.getAbsolutePosition().x
        if (vertical) {curSlidePos = circleRef.current.getAbsolutePosition().y}
        var offset = (curSlidePos - slideSt) / slideLength
        setSliderPos(offset)
        if (setPos) {
            if (vertical) {setPos(1-offset)}
            else {setPos(offset)}
        }
    }

    const fillColor = passive ? stheme.color.greys.g200 : stheme.color.brand
    const greyColor = stheme.color.greys.g100

    return (
        <Stage width={width} height={height}>
            <Layer>
                <Rect
                    // lower side of slider
                    height={vertical ? circlePos : slideThinkness}
                    width={vertical ? slideThinkness : circlePos}
                    cornerRadius={0.1 * thickness}
                    x={vertical ? thicknessPosition : slideSt}
                    y={vertical ? slideSt : thicknessPosition}
                    fill={vertical ? greyColor : fillColor}
                />
                <Rect
                    // higher side of slider
                    height={vertical ? slideLength - circlePos : slideThinkness}
                    width={vertical ? slideThinkness : slideLength - circlePos}
                    cornerRadius={0.1 * thickness}
                    x={vertical ? thicknessPosition : slideSt + circlePos}
                    y={vertical ? slideSt + circlePos: thicknessPosition}
                    fill={vertical ? fillColor : greyColor}
                />
                <Circle
                    ref={node => circleRef.current = node}
                    height={circleSize} width={circleSize}
                    y={vertical ? slideSt + circlePos : 0.5 * thickness}
                    x={vertical ? 0.5 * thickness : slideSt + circlePos}
                    fill={stheme.color.greys.g0}
                    shadowColor={'#000000'}
                    shadowOffsetX={0}
                    shadowOffsetY={4}
                    shadowBlur={16}
                    shadowOpacity={0.16}
                    draggable={!passive}
                    dragBoundFunc={(pos) => {
                        return {
                            x: vertical ? 0.5 * thickness : Math.min(slideSt + slideLength, Math.max(slideSt, pos.x)),
                            y: vertical ? Math.min(slideSt + slideLength, Math.max(slideSt, pos.y)) : 0.5 * thickness
                        }
                    }}
                    onTouchMove={e => touchPos(e)}
                    onDragMove={e => touchPos(e)}
                    onTouchEnd={e => touchPos(e)}
                    onDragEnd={e => touchPos(e)}
                />
            </Layer>
        </Stage>
    )
}