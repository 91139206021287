
import React from 'react';
import {types} from "mobx-state-tree";

// Mobx global store for minimal screen aspect ratio to ensure constant mobile experience

// Create context for accessing store through a provider
export const DimensionsContext = React.createContext(null)

// Root global store for access to all data
export const DimensionsStore = types
    .model({
        height: types.number,
        width: types.number,
    })
    .actions(self => ({
        updateScreenSize() {
            const currentAspect = window.innerWidth / window.innerHeight
            if (!self.width || dimensionsStore.aspect > currentAspect) {
                self.width = window.innerWidth
                self.height = window.innerHeight
            }
        }
    }))
    .views(self => ({
        getNothing(nothing) {},
        get aspect() {
            return self.width / self.height
        },
    }));


export let dimensionsStore = DimensionsStore.create({height: 0, width: 0});

