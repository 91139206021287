
import React, {useRef, useState, useEffect} from "react";
import DateTime from "luxon/src/datetime";


import {Hp, Wp} from "utils/util";
import {SModal} from "components/library/basic/SModal";
import {SButton, SDiv} from "components/library/styledhtml";
import {NhfScore} from "components/library/intake/NhfScore";
import {SVerticalScroll} from "components/library/basic/SVerticalScroll";
import {SCalendar} from "components/library/calendar/SCalendar";
import {Spinner} from "components/library/common/Spinner";

import {Composition} from "models/intake/composition";
import {ConCompView} from "components/library/intake/viewIntake/ConCompView";
import {CancelSaveButton} from "components/library/common/buttons/CancelSaveButton";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {EditConComp} from "components/library/intake/createIntake/EditConComp";
import {AddIntake} from "components/library/intake/createIntake/AddIntake";

import {TimeCard, TimeCardRow} from "components/library/intake/viewIntake/TimeCard";
import {observer} from "mobx-react-lite";
import {Profile} from "models/profile/userprofile";



function NutritionScoringRow(props) {

    const width = props.width
    const height = props.height
    const compRef = props.compRef
    const [ncScore, setNcScore] = useState(null)
    const [profile, setProfile] = useState(null)

    const [scoreNutrition, callScoreNutrition] = useApi('SimpaNutrition', 'score_nhf', true, true);
    const [loadProfile, callLoadProfile] = useApi('SimpaService', 'load_user_profile', true, true);
    const [recommend, callRecommend] = useApi('FoodIntakeService', 'recommend_user_date', true, true);


    useEffect(() => {
        if (compRef && compRef.grams > 0) {
            if (compRef.volume && compRef.volume !== 1 && profile) {
                setNcScore(null)
                callScoreNutrition({concomp: compRef.json})
            }
        }
    }, [compRef.grams, compRef.volume, compRef.totalSubs, profile])

    useEffect(() => {
        callLoadProfile({token: rawToken()})
    }, [])

    useEffect(() => {
        const profile = loadProfile.data
        if (!profile) {return}
        setProfile(Profile.create(profile))
    }, [loadProfile.counter])

    useEffect(() => {
        if (scoreNutrition.data) {
            setNcScore(scoreNutrition.data)
        }
    }, [scoreNutrition.counter])

    function clickOptimize() {
        callRecommend({'token': rawToken(), foodintake: compRef.json, num_ret: 20})
    }

    useEffect(() => {
        if (recommend.data) {
            console.log(recommend.data)
        }
    }, [recommend.counter])

    function selectScoreColor() {
        if (ncScore.nc < 20) {return 'red'}
        if (ncScore.nc < 40) {return 'darkorange'}
        if (ncScore.nc < 60) {return 'yellow'}
        if (ncScore.nc < 80) {return '#bfff00'}
        else {return 'lime'}
    }
    function selectScoreTextColor() {
        if (ncScore.nc >= 80) {return 'white'}
        else {return 'black'}

    }

    return (
        <SDiv
            width={width} height={height}
        >
            <SDiv direction={'row'} width={width} halign={'between'}>
                <SDiv width={0.2*width}/>
                {ncScore == null && <Spinner/>}
                {ncScore !== null &&
                <SDiv
                    height={0.7*height} width={0.7*height}
                    borderRadius={8}
                    color={selectScoreColor()} textColor={selectScoreTextColor()}
                >
                    {ncScore.nc.toFixed(1)}
                </SDiv>
                }
                <SButton
                    height={0.5*height} width={0.2*width} color={'a500'}
                    onClick={() => clickOptimize()}
                >
                    Optimize
                </SButton>
            </SDiv>
        </SDiv>
    )
}


export function BuildIntake(props) {

    const [createOpen, setCreateOpen] = useState(false)

    return(
        <SDiv>
            <SButton
                width={Wp(0.7)}
                onClick={() => setCreateOpen(true)}
            >
                Intake
            </SButton>
            {createOpen && <BuildIntakeModal onClose={() => setCreateOpen(false)}/>}
        </SDiv>
    )
}

export const DayIntakeView = observer((props) => {


    const width = props.width
    const height = props.height
    const compRef = props.compRef
    const setEditConComp = props.setEditConComp
    const setAddConComp = props.setAddConComp

    useEffect(() => {
        compRef.selectSub('11am - 5pm')
    }, [])


    return (
        <SDiv>
            <NutritionScoringRow
                width={width} height={Hp(50)} compRef={compRef}
                // need some variables to trigger scoring on change
                subs={compRef.totalSubs} volume={compRef.volume} grams={compRef.grams}
            />
            {/*
            <TimeCardRow width={width} height={Hp(50)} compRef={compRef}/>
            <SDiv width={width} height={Hp(50)} direction={'row'} halign={'around'}>
                <TimeCard timedisp={'5am - 11am'} active={compRef.isSubOpen('5am - 11am')} setActive={compRef.toggleSubOpen}/>
                <TimeCard timedisp={'11am - 5pm'} active={compRef.isSubOpen('11am - 5pm')} setActive={compRef.toggleSubOpen}/>
                <TimeCard timedisp={'5pm - 11pm'} active={compRef.isSubOpen('5pm - 11pm')} setActive={compRef.toggleSubOpen}/>
                <TimeCard timedisp={'11pm - 5am'} active={compRef.isSubOpen('11pm - 5am')} setActive={compRef.toggleSubOpen}/>
            </SDiv>
            */}
            <SDiv height={Hp(10)}/>
            <SVerticalScroll
                width={width} height={height}
                borderColor={'b200'} borderStyle={'solid none solid none'}
            >
                <ConCompView
                    concomp={compRef} width={width}
                    openEdit={setEditConComp} openAdd={setAddConComp}
                />
            </SVerticalScroll>
        </SDiv>
    )
});

export function BuildIntakeModal(props) {

    const onClose = props.onClose
    const [calOpen, setCalOpen] = useState(false)
    const [date, setDate] = useState(DateTime.local())

    const [compRef, setCompref] = useState(null)
    const [editConComp, setEditConComp] = useState(null)
    const [addConComp, setAddConComp] = useState(null)

    const [saveFoodIntake, callSaveFoodIntake] = useApi('FoodIntakeService', 'save_user_foodintake', true, true);
    const [loadFoodIntake, callLoadFoodIntake] = useApi('FoodIntakeService', 'load_user_foodintake', true, true);
    const [fillIn, callFillIn] = useApi('SimpaNutrition', 'fill_in_concomp', true, true);

    useEffect(() => {
        loadIntake()
    }, [date])

    function loadIntake() {
        setCompref(null)
        callLoadFoodIntake({'token': rawToken(), date: date.toISO().slice(0,10)})
    }

    useEffect(() => {
        if (loadFoodIntake.data) {
            const tmpComp = Composition.create(loadFoodIntake.data)
            tmpComp.setIsOpen(true)
            tmpComp.setEditSubs(false)
            setCompref(tmpComp)
        }
    }, [loadFoodIntake.counter])

    function onSave() {
        callSaveFoodIntake({'token': rawToken(), date: date.toISO().slice(0,10), foodintake: compRef.json})
    }

    useEffect(() => {
        //console.log("saved?", saveFoodIntake.data)
    }, [saveFoodIntake.counter])

    useEffect(() => {
        if (fillIn.data) {
            const tmpComp = Composition.create(fillIn.data)
            setCompref(tmpComp)
            console.log("SETTING new", tmpComp)
        }
    }, [fillIn.counter])

    const width = Wp(0.88)

    function selectDate(dt) {
        setDate(dt)
        setCalOpen(false)
    }
    function closeAdd() {
        callFillIn({concomp: addConComp.json})
        setAddConComp(null)
    }

    const conCompH = Hp(364)

    function getTitle() {
        const titleH = Hp(24)
        return (
            <SDiv height={1.4*titleH} direction={'row'}>
                <SDiv height={titleH}>Intake</SDiv>
                <SDiv width={Wp(20)}/>
                {!calOpen &&
                    <SButton
                        width={Wp(100)} height={titleH}
                        onClick={() => setCalOpen(true)}
                    >
                        {date.toLocaleString()}
                    </SButton>
                }
                {calOpen &&
                <SButton
                    width={Wp(100)} height={titleH}
                    secondary={true}
                    onClick={() => setCalOpen(false)}
                >
                    {date.toLocaleString()}
                </SButton>
                }
            </SDiv>
        )
    }

    return(
        <SModal
            topGap={Hp(0.02)} sideFrac={0.02}
            onClose={onClose}
            title={getTitle()}
        >
            {calOpen && <SCalendar datetime={date} onClick={selectDate} close={() => setCalOpen(false)}/>}
            {!compRef && <Spinner/>}
            {(compRef && !calOpen) &&
            <SDiv>
                <DayIntakeView
                    width={width} height={conCompH}
                    compRef={compRef}
                    setEditConComp={setEditConComp} setAddConComp={setAddConComp}
                />
                <CancelSaveButton
                    width={width} //height={cancelSaveH}
                    saveName={'Save Intake'} cancelName={'Reset'}
                    onSave={onSave} onCancel={loadIntake}
                    position={'fixed'} bottom={Hp(0.02)}
                    //disabled={compRef.subs.length === 0}
                />
                {editConComp &&
                <EditConComp
                    concomp={editConComp} onClose={() => setEditConComp(null)}
                    nameOff={false}
                    setConComp={setEditConComp}
                />}
                {addConComp &&
                <AddIntake
                    width={width}
                    composition={addConComp} name={addConComp.desc}
                    onClose={() => closeAdd()}
                />
                }
            </SDiv>
            }
        </SModal>
    )
}
