/*
message = {
    'id': (str),
    'text': (str),
    'time': (float),  # utc timestamp
    'direction': (str),  # 'in' or 'out'
    'source': (str),  # 'chat' or 'sms'
    'context': (dict)  # keys will have different functions/meanings
    'media': (str)  # will potentially point to media
  }
  d = {
            'id': self._id,
            'time': self._time,
            'text': self._text,
            'source': self._source,
            'state': self._state,
            'direction': self._direction,
            'context': self._context,
            'media': self._media,
        }
 */

import {types} from "mobx-state-tree";


export const Message = types
    .model({
        id: types.string,
        time: types.number,
        text: types.string,
        source: types.string,
        state: types.string,
        direction: types.string,
        //context: types.map,//(types.union(types.string, types.map(types.string), types.array)),
        context: types.map(types.union(types.map(types.string), types.string, types.array(types.string))),
        media: types.optional(types.union(types.string, types.number), 0),
    })
    .actions(self => ({
        setNothing(noth) {
        },
    }))
    .views( self => ({

        get dateTime() {
            return self.time
        },

    }));
