
// const title = 'The Future of\nPersonalized Health'
export const sectionInfo = {
    //title: 'Your life.\nYour decisions.',
    //title_sub: 'Simpa helps you in your health journey\nwith the power of your data.',
    //title: 'Detect patterns in your health\nwith your own data.',
    title: 'my SIMulated Personal Advocate',
    //title_sub: 'Blood work, nutrition, symptoms',
    //title_sub: 'Detect patterns -> personalized recommendations\nfrom your health data\n(blood work, nutrition, activity, symptoms)',
    //title_sub: 'Detect patterns -> personalized recommendations\nfrom your blood work, nutrition, activity, symptoms',
    title_sub: 'Monitor your health with\nPatterns in your blood work',
    h1: 'Simpa is your intelligent\npersonal health advocate',
    sub1: 'Collect, analyze, detect patterns, and get\nsuggestions based on your nutrition,\nsymptoms and health records.',
    src1: 'landing_phone@2x.png',
    h2: 'Simulated intelligence\ndedicated to you',
    sub2: 'Simpa supports you in making informed\ndecisions throughout your unique\nhealth journey.',
    h3: 'Proprietary simulated\nintelligence technology',
    sub3: 'Simpa gives real-time nutrition suggestions\nbased on your health information and\nyour defined goals.',
    h4: 'Quick, simplified\nhealth tracking',
    sub4: 'Simpa notifies you when it’s time to log\ninformation at the frequency you desire.\nLog via text message, chatbot,\nAlexa voice commands, and photos.',
    src4: 'landing_tracking@2x.png',
    sz4: 786,
    szm4: 522,
    h5: 'Personalized\nanalysis',
    sub5: 'No more guessing about what is best for you.\nDiscover the links between what you put\nin your body, what you do with your body,\nand the overall impact they have\non your health.',
    src5: 'landing_analysis@2x.png',
    sz5: 506,
    szm5: 336,
    h6: 'Unlimited health insight\nand suggestions',
    sub6: 'Real-time pattern detection, correlations,\nand nutrition recommendations are revealed\nwith every bit of information you\nshare with Simpa.',
    src6: 'landing_insights@2x.png',
    sz6: 448,
    szm6: 298,
    h7: 'Support when\nyou need it',
    sub7: 'Simpa is not a live health coach,\nso there is no need to wait on another human\nbeing to get the information you need.\n\nSimpa is available to support you 24/7 via\ntext message or chatbot.',
    src7: 'landing_support@2x.png',
    sz7: 640,
    szm7: 450,
    h8: 'Your health data is\nexclusively Yours for life',
    sub8: 'With Simpa, your health records are centralized\nand shareable with anyone at any time.\n\nYour data is safe with data encryption that\nsurpasses HIPAA compliance standards.',
    src8: 'landing_data@2x.png',
    sz8: 544,
    szm8: 360,
}