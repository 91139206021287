import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {SDiv} from "components/library/styledhtml";

export const NhfScore = observer((props) => {

    const concomplist = props.concompList
    const [score, setScore] = useState(null)

    setTimeout(() => {
        //concomplist.computeScore()
    }, 1000)
    setTimeout(() => {
        setScore(concomplist.score)
    }, 1500)

    return (
        <SDiv>
            {score}
        </SDiv>
    )

})