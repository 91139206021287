import React, {useState, useEffect} from "react";
import {useIsMountedRef} from "utils/custom_hooks";

import {SSpan} from "components/library/styledhtml/SSpan";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Wp} from "utils/util";


export const SpanBlock = React.forwardRef((props, ref) => {

    const isMountedRef = useIsMountedRef();
    const font = props.font ? props.font : 'b2'
    const textColor = props.textColor ? props.textColor : 'g900'
    const leftPad = props.leftPad !== false
    const txt = props.text
    const fillPos = props.fillPos
    const onClick = props.onClick
    const cursorColorOff = props.cursorColorOff ? props.cursorColorOff : 'g0'
    const cursorColorOn = props.cursorColorOn ? props.cursorColorOn : 'b900'

    const [cursorOn, setCursorOn] = useState(false)
    const [dummyCounter, setDummyCounter] = useState(0)

    useEffect(() => {
        //let mounted = true
        setCursorOn(!cursorOn)
        setTimeout(function(){
            // with timeout need to be careful that component can unmount before this is called
            // two ways to do it, locally or using custom hook here, keeping both for future reference
            if (isMountedRef.current) setDummyCounter(dummyCounter + 1)
            //if (mounted) setDummyCounter(dummyCounter+1)
        }, 600);
        //return () => mounted = false
    }, [dummyCounter])


    function getCursor() {
        if (cursorOn) {
            return <SSpan ref={ref} key={10000} font={font} textColor={cursorColorOn}>{"|"}</SSpan>
        }
        else {
            return <SSpan ref={ref} key={10000} font={font} textColor={cursorColorOff}>{"|"}</SSpan>
        }

    }

    const spans = []
    for (let i = 0; i < txt.length; i++) {
        if (i === fillPos) {spans.push(getCursor())}
        var c = txt.charAt(i)

        if (c === '\n') {
            c = " " + c
        }
        spans.push(
            <SSpan
                key={i} font={font} textColor={textColor}
                onClick={onClick ? (e) => onClick(e, i) : null}
            >
                {c}
            </SSpan>
        )
    }
    if (txt.length === fillPos) {spans.push(getCursor())}
    return (
        <SDiv display={'block'}
              paddingLeft={leftPad ? Wp(5) : null}
              paddingRight={Wp(10)}
        >
            {spans}
        </SDiv>
    )
})