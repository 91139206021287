
import React, {useState} from "react";
import {navigate} from "@reach/router";

import {Hp, Wp} from "utils/util";

import {SModal} from "components/library/basic/SModal";
import {SDiv} from "components/library/styledhtml/SDiv";
import {Menu} from "grommet-icons";
import {headerHeight} from "components/library/nav/dimensions";
import {apiValidateToken, isTokenValid} from "models/security/token";
import {APP, HOME, PERSONALIZE, PROFILE} from "components/paths";
import {LandingMenu} from "components/library/nav/LandingMenu";
import {LoginSignup} from "components/library/common/auth/LoginSignup";
import {icons, imgs} from "utils/images";
import {User} from "grommet-icons";
import {SImg} from "components/library/styledhtml";


const headerFrac = 0.07

export function HeaderBuffer(props) {
    return (
        <SDiv height={Hp(headerFrac)}/>
    )
}


export function MainHeader(props) {

    const color = props.color ? props.color : 'g0'
    const textColor = props.textColor
    const height = Hp(headerFrac)
    const position = props.position ?? 'fixed'
    const [showLogin, setShowLogin] = useState(false)
    const backnav = props.backnav

    const size = 0.8* height
    var sideSpace = Wp(0.02)
    const arrowWidth = Hp(16)
    const profileSize = Hp(52)

    const showBack = props.showBack ?? true
    const showProfile = props.showProfile ?? true

    function clickBack() {
        if (backnav) {navigate(`/${APP}/${PERSONALIZE}/${backnav}`)}
        else {navigate(-1)}
    }

    return (
        <SDiv
            direction={'row'} halign={'around'} valign={'center'}
            height={height} width={Wp(0)}
            color={color} position={position}
            boxShadow={'normal'}
        >

            {showBack && <SImg
                src={icons['arrow_back.svg']}
                height={arrowWidth} width={arrowWidth} color={'g400'}
                onClick={() => clickBack()}
            />}
            {!showBack && <SDiv height={arrowWidth} width={arrowWidth}/>}
            {showProfile && <User
                size={`${0.7*size}px`}
                onClick={() => navigate(`/${APP}/${PROFILE}`)}
            />}
            {!showProfile && <SDiv width={0.7*size}/>}
            <SImg
                height={size} width={size}
                src={imgs['simpa_logo_blue@2x.png']}
                onClick={() => navigate(`/${APP}/${HOME}/`)}
            />
            <SDiv width={0.7*size}/>
            <SDiv height={arrowWidth} width={arrowWidth}/>
        </SDiv>
    )


}