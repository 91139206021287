
// export const defaultBackgroundColor = '#F5F6FA'  // create intake one time spec


const brand = '#10BFED'
const a1 = brand
const a2 = '#0EA4AF'
const brands = {
    b50: '#ECFAFE',
    b100: '#CFF2FB',
    b200: '#A9E8F9',
    b500: '#10BFED',
    b800: '#0499DF',
    b900: '#0479af',
    a50: '#ECF8F9',
    a200: '#A8DEE2',
    a500: '#0EA4AF',
    a800: '#047583',
}

/*
// inverting brand colors
const brand = '#0EA4AF'
const a2 = '#10BFED'
const a1 = brand
const brands = {
    b50: '#ECF8F9',
    b100: '#A8DEE2',
    b200: '#A8DEE2',
    b500: '#0EA4AF',
    b800: '#047583',
    b900: '#086268',

    a50: '#ECFAFE',
    a100: '#CFF2FB',
    a200: '#A9E8F9',
    a500: '#10BFED',
    a800: '#0499DF',
    a900: '#0479af',
}
 */

const greys = {
    l1: '#F8F8F8',
    l2: '#F4F4F4',
    l3: '#EAEAEA',
    l4: '#D6D6D6',
    d1: '#474747',
    d2: '#5B5B5B',
    d3: '#848484',
    d4: '#ADADAD',
    g0: '#FFFFFF',
    g50: '#F3F3F3',
    g75: '#E5E5E5',
    g100: '#E0E0E0',
    g150: '#D0D0D0',
    g200: '#C8C8C8',
    g300: '#A9A9A9',
    g400: '#7E7E7E',
    g500: '#666666',
    g600: '#575757',
    g700: '#444444',
    g800: '#353535',
    g900: '#232323',
}

const ogrey = '#333333'
const op = {
    l1: 0.03,
    l2: 0.05,
    l3: 0.1,
    l4: 0.2,
    d1: 0.9,
    d2: 0.8,
    d3: 0.6,
    d4: 0.4,
}

const stat = {
    danger: '#E74C3C',
    danger800: '#D52217',
    danger500: '#E74C3C',
    danger200: '#F6BFB9',
    dang: '#E74C3C',
    warn500: '#F39C12',
    warning: '#F39C12',
    ok: '#2EAD6E',
    success: '#2EAD6E',
    info: '#2A78E5',
}

const mainFont = 'Roboto'
const w300 = '300'
const w400 = '400'
const w500 = '500'
const w600 = '600'
const w700 = '700'

const fonts = {
    h1: {
        family: mainFont,
        weight: w600,
        size: `32px`,
        sp: `40px`,
    },
    h2: {
        family: mainFont,
        weight: w300,
        size: `32px`,
        sp: `44px`,
    },
    h3: {
        family: mainFont,
        weight: w500,
        size: `24px`,
        sp: `32px`,
    },
    h3r: {
        family: mainFont,
        weight: w400,
        size: `24px`,
        sp: `32px`,
    },
    h3l: {
        family: mainFont,
        weight: w300,
        size: `24px`,
        sp: `32px`,
    },
    h4: {
        family: mainFont,
        weight: w700,
        size: `20px`,
        sp: `28px`,
    },
    h4l: {
        family: mainFont,
        weight: w300,
        size: `20px`,
        sp: `28px`,
    },
    h5: {
        family: mainFont,
        weight: w400,
        size: `20px`,
        sp: `24px`,
    },
    h5l: {
        family: mainFont,
        weight: w300,
        size: `20px`,
        sp: `24px`,
    },
    h5r: {
        family: mainFont,
        weight: w400,
        size: `20px`,
        sp: `24px`,
    },
    h6: {
        family: mainFont,
        weight: w700,
        size: `14px`,
        sp: `20px`,
    },
    b1: {
        family: mainFont,
        weight: w500,
        size: `16px`,
        sp: `24px`,
    },
    b2: {
        family: mainFont,
        weight: w400,
        size: `16px`,
        sp: `24px`,
    },
    b2s: {
        family: mainFont,
        weight: w400,
        size: `16px`,
        sp: `20px`,
    },
    b3: {
        family: mainFont,
        weight: w400,
        size: `14px`,
        sp: `20px`,
    },
    b3s: {
        family: mainFont,
        weight: w400,
        size: `14px`,
        sp: `16px`,
    },
    b4: {
        family: mainFont,
        weight: w500,
        size: `12px`,
        sp: `20px`,
    },
    b5: {
        family: mainFont,
        weight: w400,
        size: `12px`,
        sp: `20px`,
    },
    b6: {
        family: mainFont,
        weight: w300,
        size: `14px`,
        sp: `16px`,
    },
    button: {
        family: mainFont,
        weight: w700,
        size: `16px`,
        sp: `20px`,
    },
    button2: {
        family: mainFont,
        weight: w700,
        size: `12px`,
        sp: `16px`,
    },
    subtitle: {
        family: mainFont,
        weight: w500,
        size: `14px`,
        sp: `20px`,
    },
    caption: {
        family: mainFont,
        weight: w400,
        size: `14px`,
        sp: `16px`,
    },
    o1: {
        family: mainFont,
        weight: w500,
        size: `11px`,
        sp: `16px`,
    },
    o2: {
        family: mainFont,
        weight: w400,
        size: `11px`,
        sp: `16px`,
    },
}

const pallete = {
    p100: {
        turquoise: '#D1F2EB',
        emerald: '#D5F5E3',
        peterRiver: '#D6EAF8',
        amethyst: '#EBDEF0',
        greenSea: '#D0ECE7',
        nephritis: '#D4EFDF',
        belizeHole: '#D4E6F1',
        wisteria: '#E8DAEF',
        sunFlower: '#FCF3CF',
        carrot: '#FAE5D3',
        alizarin: '#FADBD8',
        orange: '#FDEBD0',
        pumpkin: '#F6DDCC',
        pomegranate: '#F2D7D5',
    },
}


export const stheme = {
    color: {
        brand: brand,
        a1: a1,
        a2: a2,
        brands: brands,
        greys: greys,
        ogrey: ogrey,
        stat: stat,
        op: op,
        pallete: pallete,
    },
    font: fonts,
}


export function getColor(value, setDefault=true, colordefault=null) {

    if (!value && colordefault) {
        return colordefault
    }
    var color = value
    if (stheme.color.hasOwnProperty(value)) {return stheme.color[value]}
    if (stheme.color.brands.hasOwnProperty(value)) {return stheme.color.brands[value]}
    if (stheme.color.greys.hasOwnProperty(value)) {return stheme.color.greys[value]}
    if (stheme.color.stat.hasOwnProperty(value)) {return stheme.color.stat[value]}
    if (stheme.color.pallete.p100.hasOwnProperty(value)) {return stheme.color.pallete.p100[value]}
    if (!color && setDefault) {color = brand}

    return color
}
