import {types} from "mobx-state-tree";
import {utc2Format} from "utils/dates";

export const LabPoint = types
    .model({
        id: types.string,
        data: types.union(types.string, types.number),
        lhr: types.number,
        uhr: types.number,
        time: types.number,
        name: types.string,
        units: types.string,
        loadTime: types.optional(types.number, 0),
    })
    .actions(self => ({
        doNothing(text) {
        },
    }))
    .views(self => ({
        get nothing() {
            return
        },
        get outOfRange() {
            return self.data < self.lhr | self.data > self.uhr
        },
        get showDate() {
            return utc2Format(self.time)
        }
    }));