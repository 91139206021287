// Create profile from scratch first time

import React, {useEffect, useState, useContext} from "react";
import {SButton, SDiv} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {Spinner} from "components/library/common/Spinner";
import {Profile} from "models/profile/userprofile";
//import {observer} from "mobx-react-lite";
import {MainHeader} from "components/library/nav/MainHeader";
import {NutrientOrg} from "models/intake/nutrientOrg";
import {NutrientOrgView} from "components/library/intake/viewOrg/nutrientOrgView";
import {GlobalStoreContext} from "models/rootStore";


export function NutritionOrg(props) {

    const gstore = useContext(GlobalStoreContext)
    const nutOrg = gstore.getNutrientOrg()

    return(
        <SDiv>
            <MainHeader position={'relative'} showProfile={false}/>
            <SDiv height={Hp(10)}/>
            <SDiv>
                Nutrient Organization
            </SDiv>
            <NutrientOrgView nutOrg={nutOrg}/>

        </SDiv>
    )
}
