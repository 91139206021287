import React, {useEffect, useState} from "react";

import {useIsMountedRef} from "utils/custom_hooks";
import {Hp, Wp} from "utils/util";
import {useAuthentication} from "api/api";
import {deleteToken, rawToken} from "models/security/token";
import {navigate} from "@reach/router";
import {SModal} from "components/library/basic/SModal";
import {SButton, SDiv, SInput} from "components/library/styledhtml";


export function DeleteAccountConfirm(props) {

    const isMountedRef = useIsMountedRef()
    const isOpen = props.isOpen
    const setOpen = props.setOpen

    const [baseH, setBaseH] = useState(Hp(10))
    const [baseHf, setBaseHf] = useState(Hp(0.1))
    const [baseW, setBaseW] = useState(Wp(10))
    const [baseWf, setBaseWf] = useState(Hp(0.05))
    const [errorMsg, setErrorMsg] = useState('')

    const [pass1, setPass1] = useState([])
    const [show1, setShow1] = useState('')
    const [dispPass, setDispPass] = useState(false)

    const [deleteAccount, callDeleteAccount] = useAuthentication('delete_account', true);

    const topGap = baseHf
    const bottomGap = 3 * baseHf
    const leftGap = baseWf
    const rightGap = leftGap
    const modalWidth = window.innerWidth - leftGap - rightGap
    const modalHeight = window.innerHeight - topGap - bottomGap

    useEffect(() => {
        const data = deleteAccount.data
        if (data) {
            if (data['success'] === true) {
                deleteToken()
                navigate('/')
            } else {
                setErrorMsg(data.message)
                setTimeout(function () {
                    if (isMountedRef) {
                        setErrorMsg('')
                    }
                }, 4000);
            }
        }

    }, [deleteAccount.counter])

    function updatePass1(val) {
        const lastChar = val[val.length - 1]
        if (val.length > pass1.length) {
            pass1.push(lastChar)
        } else {
            pass1.pop()
        }
        const s = new Array(pass1.length + 1).join('.')
        setShow1(s)
    }


    function deleteit() {
        const params = {'token': rawToken(), 'password': pass1.join('')}
        callDeleteAccount(params)
    }

    function close() {
        setOpen(false)
    }

    if (!isOpen) {
        return null
    }
    return (
        <SModal
            topGap={topGap} bottomGap={bottomGap} leftGap={leftGap} rightGap={rightGap}
            modalWidth={modalWidth} modalHeight={modalHeight}
            onClose={close} title={'Account Deletion'} titleColor={'danger'}
        >
            <SDiv height={baseH}/>
            <SDiv font={'b2'} textAlign={'center'} manualWrap>
                {'Your account and all data\nwill be permanently deleted'}
            </SDiv>
            <SDiv height={3 * baseH}/>
            <SDiv width={0.8 * modalWidth} manualWrap={true} textAlign={'center'}>
                {'Please confirm by typing in\nyour password below'}
            </SDiv>
            <SDiv height={baseH}/>
            <SDiv direction={'row'} halign={'center'}>
                <SInput
                    font={dispPass ? 'b2' : 'b1'}
                    width={0.65 * modalWidth} boxShadow={'normal'} borderStyle={'solid'} fillOff={true}
                    value={dispPass ? pass1.join('') : show1} onChange={(e) => updatePass1(e.target.value)}
                />
                <SDiv width={8}/>
                <SDiv font={'b3'} textColor={'b900'}
                      onClick={() => setDispPass(!dispPass)}>{dispPass ? 'hide' : 'show'}</SDiv>
            </SDiv>
            <SDiv height={3 * baseH}/>
            <SDiv direction={'row'}>
                <SButton secondary={true} onClick={() => close()}>Cancel</SButton>
                <SDiv width={4 * baseW}/>
                <SButton
                    onClick={() => deleteit()}
                    color={'danger'} disabled={(pass1.length < 8 || errorMsg)}
                >
                    Delete
                </SButton>
            </SDiv>
            <SDiv height={baseH}/>
            {errorMsg && <SDiv font={'b2'} textColor={'danger'}>
                {errorMsg}
            </SDiv>}
        </SModal>
    )
}