import React, {useContext, useEffect, useRef} from "react";

import {rawToken, deleteToken} from "models/security/token";
import {useIsMountedRef} from "utils/custom_hooks";
import {WSURL} from "api/api";
import {decode} from "@msgpack/msgpack";
import lz4 from 'lz4js'

import {globalStore} from "models/rootStore";


const checkClosedTime = 1000 * 4 // check closed every x seconds

async function handleWebsocketMessage(blob) {
    const data = decode(await new Response(blob).arrayBuffer())
    var newData = ''
    if (!data.compressed) {
        newData = data.data
    }
    else if (data.compressed === 'lz4') {
        newData = decode(lz4.decompress(data.data));
    }
    else {
    }
    // todo: handle websocket data
    try {
        if (newData.target === 'Auth') {
            if (newData.method === 'Delete') {
                deleteToken()
            }
        }
        if (newData.target === 'Chatbot') {
            setTimeout(()=>{
                globalStore.conversation.addMessage(newData['args'][0])
            }, 200)
       }
    }
    finally {
    }
    console.log("NEW WS data: ", newData)
}


/**
 * @return {null}
 */
export function SWebsocket(props) {

    const isMountedRef = useIsMountedRef();
    const aliveRef = useRef(false)
    const webSocket = useRef(null);
    const checkToken = props.checkToken

    useEffect(() => {
        checkSocket()
        return () => closeSocket();
    }, []);

    function reValidate() {
        if (checkToken) checkToken()
    }

    function closeSocket() {
        if (webSocket.current) webSocket.current.close()
    }

    function checkSocket() {
        if (isMountedRef.current) {
            if (!webSocket.current || webSocket.current && getWSState() === 'closed') {
                initWs()
            }
        }
        setTimeout(() => {
            checkSocket()
        }, checkClosedTime)
    }

    function initWs() {

        webSocket.current = new WebSocket(WSURL);
        webSocket.current.onmessage = (message) => {
            if (message.data === 'token request') {
                sendWs(rawToken())
                sendWs('alive?')
            }
            else if (message.data === 'alive!') {
                //console.log("Ws connected")
                aliveRef.current = true
            }
            else if (aliveRef.current) {
                handleWebsocketMessage(message.data)
            }
            else {
                // recheck token
                reValidate()
            }
        };
        webSocket.current.onError = (e) => {
            // handle errors?
        };
        webSocket.current.onClose = () => {
            aliveRef.current = false
        };
    }

    function getWSState() {
        if (webSocket.current) {
            switch (webSocket.current.readyState) {
                case webSocket.current.OPEN:
                    return 'open'
                case webSocket.current.CLOSED:
                    return 'closed'
                case webSocket.current.CLOSING:
                    return 'closing'
                case webSocket.current.CONNECTING:
                    return 'connecting'
                default:
                    return 'none'
            }
        }
        else {
            return 'none'
        }
    }

    function sendWs(message) {
        if (webSocket.current && getWSState() === 'open') {
            webSocket.current.send(message)
        }
    }

    return (null)
}