import React from "react";

import {SDiv} from "components/library/styledhtml";
import {CompositionView} from "components/library/intake/viewIntake/CompositionView";
import {ConsumableView} from "components/library/intake/viewIntake/ConsumableView";


export function ConCompView(props) {

    const width = props.width
    const height = props.height
    const concomp = props.concomp

    const level = props.level ? props.level : props.level !== 0 ? -1 : 0
    const openEdit = props.openEdit
    const editOff = props.editOff
    const openAdd = props.openAdd
    const volumeOff = props.volumeOff ? props.volumeOff : false
    const nameOff = props.nameOff ? props.nameOff : false
    const highlight = props.highlight ? props.highlight : false
    const addOff = props.addOff ? props.addOff : false
    const onDelete = props.onDelete
    const onShiftUp = props.onShiftUp
    const onShiftDown = props.onShiftDown

    if (concomp.iscomp) {
        return (
            <CompositionView
                width={width} height={height}
                composition={concomp} openEdit={openEdit} level={level + 1}
                volumeOff={volumeOff} nameOff={nameOff} highlight={highlight}
                onDelete={onDelete} onShiftUp={onShiftUp} onShiftDown={onShiftDown}
                addOff={addOff} openAdd={openAdd}
            />
        )
    } else {
        return (
            <ConsumableView
                width={width} height={height}
                consumable={concomp} openEdit={openEdit} level={level + 1}
                volumeOff={volumeOff} nameOff={nameOff} highlight={highlight}
                onDelete={onDelete} onShiftUp={onShiftUp} onShiftDown={onShiftDown}
            />
        )
    }
}


export function Description(props) {

    const desc = props.desc
    const width = props.width
    const height = props.height
    const valign = props.valign ?? 'start'

    var txtColor = desc ? 'g800' : 'g100'
    var txt = desc ? desc : ''  //'<other description>'
    var font = desc ? 'b3s' : 'b4'

    return (
        <SDiv
            width={width} height={height} textColor={txtColor}
            font={font} halign={'start'} valign={valign}
        >
            {txt}
        </SDiv>
    )
}