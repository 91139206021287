import React from "react";
import {Router} from "@reach/router";

import {ProfileHome} from "components/pages/profile/ProfileHome";
import {BuildProfile} from "components/pages/profile/CreateProfile";
import {EditPersonalization} from "components/pages/personalization";
import {SetRestrictions} from "components/pages/profile/nutrition/SetRestrictions";
import {NutritionOrg} from "components/pages/profile/nutrition/NutritionOrg";

export function Profile(props) {

    return (
        <Router>
            <ProfileHome path={'/'}/>
            <BuildProfile path={'/edit'}/>
            <SetRestrictions path={'/restrictions'}/>
            <EditPersonalization path={'/personalize'}/>
            <NutritionOrg path={'/nutrientorg'}/>
        </Router>
    )
}