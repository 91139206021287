import React from "react";

import useImage from "use-image";
import {Circle, Image, Layer, Stage} from "react-konva";

import {SDiv, SImg} from "components/library/styledhtml";
import {imgs} from "utils/images";
import {stheme} from "themes/theme";


export function VolumeImageKonva(props) {

    const height = props.height
    const volume = props.volume
    const onClick = props.onClick

    const [sphereimg] = useImage(imgs[`sphere_blue.png`]);

    const sz = Math.log(volume / 4 + 1) / Math.log(10000) * height
    const circleSz = Math.min(height, sz)
    const rad = 0.5 * height
    return (
        <Stage width={height} height={height}>
            <Layer
                onMouseDown={() => onClick()}
                onTouchStart={() => onClick()}
            >
                <Circle
                    radius={0.5 * height}
                    y={0.5 * height} x={0.5 * height}
                    stroke={stheme.color.greys.g100}
                    strokeWidth={1}
                />
                <Circle
                    radius={0.8 * rad} y={0.5 * height} x={0.5 * height}
                    stroke={stheme.color.greys.g100}
                    strokeWidth={1}
                />
                <Circle
                    radius={0.6 * rad} y={0.5 * height} x={0.5 * height}
                    stroke={stheme.color.greys.g100}
                    strokeWidth={1}
                />
                <Circle
                    radius={0.4 * rad} y={0.5 * height} x={0.5 * height}
                    stroke={stheme.color.greys.g100}
                    strokeWidth={1}
                />
                <Circle
                    radius={0.2 * rad} y={0.5 * height} x={0.5 * height}
                    stroke={stheme.color.greys.g100}
                    strokeWidth={1}
                />
                <Image
                    image={sphereimg}
                    height={circleSz} width={circleSz}
                    y={0.5 * (height - circleSz)} x={0.5 * (height - circleSz)}
                />
            </Layer>
        </Stage>
    )
}

function VolCircle(props) {
    return (
        <SDiv
            width={props.diameter} height={props.diameter}
            borderRadius={100} boderStyle={'solid solid solid solid'}
            borderColor={'g100'} borderWidth={1}
            position={'absolute'}
        />
    )
}

export function VolumeIndicator(props) {

    const height = props.height
    const volume = props.volume

    const sz = Math.log(volume / 4 + 1) / Math.log(10000) * height
    const circleSz = Math.min(height, sz)
    const rad = 0.5 * height
    if (!volume) {
        return null
    }
    return (
        <SDiv
            width={height} height={height}
            position={'relative'}
        >
            <VolCircle diameter={height}/>
            <VolCircle diameter={0.8 * height}/>
            <VolCircle diameter={0.6 * height}/>
            <VolCircle diameter={0.4 * height}/>
            <VolCircle diameter={0.2 * height}/>
            <SImg
                height={circleSz} width={circleSz} position={'absolute'}
                src={imgs['sphere_blue.png']}
            />
        </SDiv>

    )
}