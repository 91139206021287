import React from "react";
import {Router} from "@reach/router";

import {OnboardingTransition} from "components/pages/onboarding/OnboardingTransition";
import {HelloPage} from "components/pages/onboarding/OnboardingTransition";

export function Onboarding(props) {

    return (
        <Router>
            <OnboardingTransition path={'/'}/>
            <HelloPage path={'/hello'}/>
        </Router>
    )
}