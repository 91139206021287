import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {Hpd, Wpd, Wp, Hp, isPhone} from "utils/util";
import {imgs} from "utils/images";
import {sectionInfo} from "components/pages/landing/info";
import {LandingHeader} from "components/pages/landing/LandingHeader";
import {navigate} from "@reach/router";
import {LOGIN, SIGNUP} from "components/paths";
import {NUMBER} from "components/pages/signup/paths";
import {SButton} from "components/library/styledhtml/SButton";
import {LoginSignup} from "components/library/common/auth/LoginSignup";

import {GAevent} from "components/library/network/googleAnalytics";
import {headerHeight} from "components/library/nav/dimensions";


const leftpad = 80
const leftbuff = 672

function FutureSection(props) {

    return (
        <SDiv>
            <SDiv height={Hpd(30)}/>
            <SDiv
                width={Wpd(0)} height={Hp(50)}
                textColor={'g600'} textAlign={'center'}
                font={'h3'}
                manualWrap
            >
                {sectionInfo.title}
            </SDiv>
            <SDiv
                height={Hp(40)} width={Wp(0)}
                font={'b1'} textAlign={'center'} textColor={'b800'} manualWrap
            >
                {sectionInfo.title_sub}
            </SDiv>
            <SDiv height={Hpd(20)}/>
        </SDiv>
    )
}

export function TextSection(props) {

    const innerWidth = window.innerWidth
    const type = props.type
    var height = props.height ? props.height : Hpd(112)
    const left = props.left
    const top = props.top
    const mobile = props.mobile
    const text = props.text
    const position = props.position ? props.position : 'relative'

    var valign = 'start'
    var fsz
    var width = Wpd(leftbuff)

    var textColor = 'g600'
    var textAlign = 'start'
    fsz = innerWidth < 1180 ? 'h2' : 'h1'
    if (type === 2) {
        fsz = innerWidth < 1180 ? 'h5l' : 'h3l'
    }
    if (mobile) {
        fsz = type === 2 ? 'b1' : 'h3'
        width = Wp(0)
        textAlign = 'center'
        textColor = 'g800'
    }

    return (
        <SDiv
            width={width} height={height} color={props.color}
            valign={valign} halign={textAlign} textAlign={textAlign}
            manualWrap font={fsz} textColor={textColor}
            position={position} left={left} top={top}
        >
            {text}
        </SDiv>

    )
}

function Section1(props) {

    const num = 1

    const height = Hpd(668)

    return (
        <SDiv height={height} width={Wpd(0)}
              halign={'start'} valign='start' direction={'row'}
        >
            <SDiv width={Wpd(670)} height={height} valign={'start'}>
                <TextSection type={1} text={sectionInfo[`h${num}`]} left={Wpd(leftpad)} top={Hpd(214)}/>
                <TextSection type={2} text={sectionInfo[`sub${num}`]} left={Wpd(leftpad)} top={Hpd(214+32)}/>
            </SDiv>
            <SImg
                src={imgs[sectionInfo[`src${num}`]]} height={height}
                left={Wpd(leftbuff)}
            />
        </SDiv>

    )
}


function Section2(props) {

    const cardW = Wpd(456)
    const cardH = 252/456 * cardW

    const imgH = Hpd(1760)
    const imgW = imgH * 680/1760//Wpd(680)
    //const imgH = imgW * 1760/680

    return (
        <SDiv
            width={Wpd(0)} height={Hpd(1900)}
            valign={'start'} halign={'start'}
            backgroundImage={imgs['landing_gradient001@2x.png']}
            backgroundSize={`${Wpd(0)}px`}
            backgroundPosition={`top ${Hpd(232)}px left`}
        >
            <SDiv
                width={Wpd(0)} height={Hpd(1760)}
                valign={'start'} halign={'start'}
                backgroundImage={imgs['landing_process@2x.png']}
                backgroundSize={`${imgW}px ${imgH}px`}
                backgroundPosition={`top 0px left ${Wpd(leftpad)}px`}
            >
                <TextSection type={1} text={sectionInfo.h2} top={Hpd(520)} left={Wpd(550)}/>
                <SDiv height={Hpd(32)}/>
                <TextSection type={2} text={sectionInfo.sub2} top={Hpd(520)} left={Wpd(550)}/>
                <TextSection type={1} text={sectionInfo.h3} top={Hpd(520+320)} left={Wpd(550)}/>
                <SDiv height={Hpd(32)}/>
                <TextSection type={2} text={sectionInfo.sub3} top={Hpd(520+320)} left={Wpd(550)}/>
                <SDiv
                    direction={'row'} width={Wpd(0)}
                    position={'relative'} top={Hpd(1140)}
                >
                    <SImg src={imgs['landing_card001@2x.png']} width={cardW} height={cardH}/>
                    <SImg src={imgs['landing_card002@2x.png']} width={cardW} height={cardH}/>
                    <SImg src={imgs['landing_card003@2x.png']} width={cardW} height={cardH}/>
                </SDiv>
            </SDiv>
        </SDiv>

    )
}

function SubSection(props) {

    const num = props.num
    const textSide = props.textSide

    const h = sectionInfo[`sz${num}`]
    const height = Hpd(h)
    const width = 512/h * height

    var textoffset = leftpad
    var imgoffset = 800
    if (textSide === 'right') {
        textoffset = 800
        imgoffset = leftpad
    }

    return(
        <SDiv
            width={Wpd(0)} height={height}
            valign={'start'} halign={'start'}
            backgroundImage={imgs[sectionInfo[`src${num}`]]}
            backgroundSize={`${width}px ${height}px`}
            backgroundPosition={`top 0px left ${Wpd(imgoffset)}px`}
        >
            <TextSection type={1} text={sectionInfo[`h${num}`]} top={0.33*height} left={Wpd(textoffset)}/>
            <SDiv height={Hpd(32)}/>
            <TextSection type={2} text={sectionInfo[`sub${num}`]} top={0.33*height} left={Wpd(textoffset)}/>
        </SDiv>
    )
}


function Section3(props) {

    return (
        <SDiv
            width={Wpd(0)}
            valign={'start'} halign={'start'}
            backgroundImage={imgs['landing_gradient001@2x.png']}
            backgroundSize={`${Wpd(0)}px`}
            backgroundPosition={`top ${Hpd(360)}px left`}
        >
            <SDiv height={Hpd(64)}/>
            <SubSection num={4} textSide={'left'}/>
            <SDiv height={Hpd(64)}/>
            <SubSection num={5} textSide={'right'}/>
        </SDiv>

    )
}


function Section4(props) {

    return (
        <SDiv
            width={Wpd(0)}
            valign={'start'} halign={'start'}
            backgroundImage={imgs['landing_gradient002@2x.png']}
            backgroundSize={`${Wpd(0)}px`}
            backgroundPosition={`top ${Hpd(952)}px left`}
        >
            <SDiv height={Hpd(64)}/>
            <SubSection num={6} textSide={'left'}/>
            <SDiv height={Hpd(64)}/>
            <SubSection num={7} textSide={'right'}/>
            <SDiv height={Hpd(64)}/>
            <SubSection num={8} textSide={'left'}/>
        </SDiv>

    )
}


export function DesktopLanding(props) {

    const [showSignup, setShowSignup] = useState(false)

    function closeSignup() {
        setShowSignup(false)
    }

    function signup() {
        GAevent('User','Clicked Signup')
        setShowSignup(true)
    }

    const background = 'g0'


    return (
        <SDiv width={Wpd(0)} valign={'start'} color={background}>
            <SDiv height={headerHeight()}/>
            <FutureSection/>
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <SDiv height={Hpd(200)}/>
            <SButton
                height={Hpd(64)} width={Wpd(460)}
                position={'fixed'}
                bottom={Hpd(32)}
                onClick={() => signup()}
            >
                Sign up
            </SButton>
            <LandingHeader color={background}/>
            <LoginSignup isOpen={showSignup} close={closeSignup} smstype={'signup'}/>
        </SDiv>
    )
}