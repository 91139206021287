import React from "react";
import {Router} from "@reach/router";

import {AnalysisHome} from "components/pages/analysis/AnalysisHome";

export function Analysis(props) {

    return (
        <Router>
            <AnalysisHome path={'/'}/>
        </Router>
    )
}