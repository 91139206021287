import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Hp, Wp} from "utils/util";
import {SDiv} from "components/library/styledhtml";
import {EditConComp} from "components/library/intake/createIntake/EditConComp";
import {VolumeIndicator} from "components/library/intake/viewIntake/VolumeIndicator";
import {Description} from "components/library/intake/viewIntake/ConCompView";
import {Trash, InstallOption, Upgrade} from "grommet-icons";

import {stheme} from "themes/theme";

export const ConsumableView = observer((props) => {

    const height = props.height ? props.height : Hp(60)
    const parentWidth = props.width ? props.width : Wp(0)
    const con = props.consumable  // Consumable-Model
    const volumeOff = props.volumeOff
    const nameOff = props.nameOff
    const highlight = props.highlight
    const onDelete = props.onDelete
    const onShiftUp = props.onShiftUp
    const onShiftDown = props.onShiftDown

    const width = Math.max(parentWidth - 8, Wp(0.7))

    const [editConComp, setEditConComp] = useState(null)
    // pass the open modal through the chain to make sure it is on top
    const openEdit = props.openEdit ? props.openEdit : setEditConComp

    const txtWidth = 0.8 * width
    const showDesc = !volumeOff || con.desc
    const searchDisplay = (volumeOff && !con.desc)
    return (
        <SDiv
            width={width} height={height}
            direction={'row'} halign={'start'}
            borderRadius={4} borderWidth={1} borderColor={'g100'} borderStyle={'solid'}
            color={highlight ? 'b100' : 'g0'}
            boxShadow={searchDisplay ? 'normal' : null}
        >
            <SDiv width={Wp(20)} height={height}/>
            <SDiv
                width={txtWidth} height={height} valign={'start'}
                onClick={() => openEdit(con)}
            >
                {showDesc &&
                <Description desc={con.desc} width={txtWidth} height={0.45 * height} valign={'center'}/>
                }
                <SDiv
                    width={txtWidth} height={showDesc ? 0.55 * height : height}
                    halign={'start'} valign={showDesc ? 'start' : 'center'}
                    textColor={searchDisplay ? 'b900' : 'b800'} font={'b6'}
                >
                    {nameOff ? '' : con.name}
                </SDiv>
            </SDiv>
            <SDiv width={Wp(8)} height={height}/>
            {(!volumeOff && !onDelete && !(onShiftUp || onShiftDown)) &&
                <SDiv
                    onClick={() => openEdit(con)}
                >
                    <VolumeIndicator height={0.5 * height} volume={con.volume}/>
                </SDiv>
            }
            {onDelete && <SDiv
                height={height}
                onClick={() => onDelete()}
            >
                <Trash size={`30px`} color={stheme.color.stat.danger}/>
            </SDiv>
            }
            {(onShiftUp || onShiftDown) &&
            <SDiv
                height={height} valign={'around'}
            >
                <Upgrade
                    size={`30px`} color={onShiftUp ? stheme.color.stat.warn500 : stheme.color.greys.g100}
                    onClick={onShiftUp ? () => onShiftUp() : null}
                />
                <InstallOption
                    size={`30px`} color={onShiftDown ? stheme.color.stat.warn500 : stheme.color.greys.g100}
                    onClick={onShiftDown ? () => onShiftDown() : null}
                />
            </SDiv>
            }
            {editConComp &&
            <EditConComp
                concomp={con} onClose={() => setEditConComp(null)} nameOff={nameOff}
            />}
        </SDiv>
    )
})