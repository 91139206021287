
import React from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {SArrow} from "components/library/basic/SArrow";
import {Wp, Hp} from "utils/util";

import {icons} from "utils/images";
import {Keypad} from "components/library/keyboards/Keypad";


export function getPhoneDisplay(initnumber) {
    var number = initnumber ? initnumber.replace(/\W/g, '') : ''
    number = number.replace(/\s/g, '')
    number = number.replace(/\D/g, '')
    if (number.length < 10) {
        number = number.padEnd(10)
    }
    return '(' + number.substring(0, 3) + ') ' +  number.substring(3, 6) + ' - ' + number.substring(6,10)
}


export function PhoneInput(props) {

    const height = props.height ? props.height : Hp(36)
    const width = props.width ? props.width : Wp(256)
    const number = props.number ? props.number : ''
    const setNumber = props.setNumber
    const focus = props.focus
    const setFocus = props.setFocus

    function focusInput(e) {
        e.stopPropagation()
        if (setFocus) setFocus(true)
    }

    function updateValue(val) {
        if (setNumber) setNumber(val)
    }

    return (
        <SDiv direction='row' height={height} width={width}
              borderStyle={'none none solid none'}
              onClick={(e) => focusInput(e)}
        >
            <SDiv direction='row' height={height} width={width}
                  halign={'start'} paddingLeft={10}
            >
                <SImg paddingLeft={20} height={Hp(17)} width={Wp(24)} src={icons['flag_US.svg']}/>
                <SArrow direction={'down'} size={5}/>
                <SDiv
                    height={height} width={40}
                    font={'b2'} textColor={'g900'}
                    halign={'center'}
                >
                    +1
                </SDiv>
                <SDiv
                    height={height} width={0.5*width}
                    font={'b2'} textColor={'g900'}
                    halign={'start'}
                >
                    {getPhoneDisplay(number)}
                </SDiv>
            </SDiv>
            {focus &&
            <Keypad
                height={Hp(0.33)} width={Wp(0)}
                onPress={updateValue}
                fixed={true}
            />}
        </SDiv>

    )
}