
import React, {useState, useEffect, useContext, useRef} from "react";

import {SButton, SDiv, STextArea} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";
import {ChatBotModal, ChatMessages} from "components/library/common/chat/chatbot";
import {observer} from "mobx-react-lite";
import {useApi} from "api/api";
import {GlobalStoreContext} from "models/rootStore";
import {rawToken} from "models/security/token";
import {SModal} from "components/library/basic/SModal";
import {stheme} from "themes/theme";

import {Send} from "grommet-icons";
import {SVerticalScroll} from "components/library/basic/SVerticalScroll";
import {ConCompView} from "components/library/intake/viewIntake/ConCompView";
import {Consumable} from "models/intake/consumable";
import {Composition} from "models/intake/composition";


export const SearchModal = observer((props) => {

    const width = props.width ? props.width : Wp(0)
    const height = props.height ? props.height : Hp(0.7)

    const onClose = props.onClose
    const gstore = useContext(GlobalStoreContext)
    const [value, setValue] = useState('')
    const [counter, setCounter] = useState(0)
    const [items, setItems] = useState([])
    const subHeight = Hp(40)

    const currentId = ''

    const [search, callSearch] = useApi('SimpaNutrition', 'search', true, true);
    const [getDbs, callGetDbs] = useApi('SimpaNutrition', 'avail_dbs', true, true);
    const restrict = useContext(GlobalStoreContext).profile.restrictions.restrictions
    const [dbs, setDbs] = useState(['SR28'])

    const lastValTime = useRef(0);
    const callDelay = 500

    function onValue(value) {
        value = value.replace(/return/g, '\n')
        value = value.replace(/enter/g, '\n')
        setValue(value)
    }

    useEffect(() => {
        callGetDbs({})
    }, [])

    useEffect(() => {
        if (getDbs.hasData) {
            setDbs(Object.keys(getDbs.data))
        }
    }, [getDbs.counter])

    useEffect(() => {
        if (search.hasData) {
            console.log(search.data)
        }
    }, [search.counter])

    useEffect(() => {
        if (value.trim()) {
            if (Date.now() - lastValTime.current >= callDelay) {
                makeSearchCall()
            } else {
                setTimeout(function () {
                    setCounter(counter + 1)
                }, callDelay + 1)
            }
        } else {
            setItems([])
        }
        lastValTime.current = Date.now()
    }, [value, counter])


    function makeSearchCall() {
        const restrictions = []
        for (const [r, hasR] of restrict.entries()) {
            if (hasR) {
                restrictions.push(r)
            }
        }
        const params = {
            'srch_term': value.trim(),
            'max_return': 50,
            'cutoff': 2,
            'restrictions': restrictions,
            'databases': dbs
        }
        callSearch(params)
    }

    function select(cc) {
        //if (onSelect) {onSelect(cc)}
    }

    function getConComp(data) {

        var cc
        if (data.type === 'con') {
            cc = Consumable.create(data)
        } else {
            cc = Composition.create(data)
        }
        return cc

    }

    useEffect(() => {
        if (search.hasData) {
            const tmpItems = []

            for (let i = 0; i < search.data.length; i++) {
                const cc = getConComp(search.data[i].concomp)
                var highlight = false
                if (currentId && currentId === cc.id) {
                    highlight = true
                }
                tmpItems.push(
                    <SDiv
                        key={i}
                        onClick={() => select(cc)}
                    >
                        <ConCompView
                            concomp={cc} key={i} openEdit={() => {}}
                            height={Hp(40)} width={0.9*width}
                            volumeOff={true} highlight={highlight}
                        />
                        <SDiv height={4}/>
                    </SDiv>
                )
            }
            if (value) {
                setItems(tmpItems)
            } else {
                setItems([])
            }

        }
    }, [search.counter, currentId])

    return (
        <SModal
            topGap={Hp(0.02)} sideFrac={0}
            onClose={() => onClose()} title={'Check Search'}
            overflow={'hidden'}
        >
            <SDiv
                direction={'row'}
                halign={'start'}
                width={width}
            >
                <SDiv width={Hp(12)}/>
                <STextArea
                    width={0.8*width} height={Hp(40)}
                    maxHeight={Hp(80)}
                    font={'b2s'} padding={'12px 12px'}
                    borderColor={'g100'} borderRadius={10}
                    value={value} onChange={(e) => onValue(e.target.value)}

                />
                <Send
                    color={stheme.color.brands.b800}
                    onClick={() => makeSearchCall()}
                />
            </SDiv>
            <SVerticalScroll width={width} height={height - Hp(30) - subHeight}>
                {items}
            </SVerticalScroll>
        </SModal>
    )
})



export function SearchCheck(props) {

    const [open, setOpen] = useState(false)

    return(
        <SDiv>
            <SButton
                width={Wp(0.7)}
                onClick={() => setOpen(true)}
            >
                Search
            </SButton>
            {open && <SearchModal onClose={() => setOpen(false)}/>}
        </SDiv>
    )
}
