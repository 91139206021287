/*
Menu component for dropdowns
*/
import React, {useState} from "react";

import {SDiv} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {SArrow} from "components/library/basic/SArrow";
import {getColor} from "themes/theme";

import {useOutsideAlerter} from "utils/custom_hooks";


export function SMenu(props) {

    const outsideRef = useOutsideAlerter(outsideEvent)

    const height = props.height ? props.height : Hp(36)
    const width = props.width ? props.width : Wp(92)
    const onSelect = props.onSelect
    const direction = props.direction ? props.direction : 'down'
    const items = props.items ? props.items : ['item 1', 'item 2', 'pass items:)']

    const label = props.label ? props.label : items[0]
    const [open, setOpen] = useState(false)

    const initHover = {}
    for (let i=0; i<items.length; i++) {initHover[i] = false}
    const [hover, setHover] = useState(initHover)
    const dispItems = []

    function clicked(idx) {
        if (onSelect) {onSelect(items[idx])}
        close()
    }

    function close() {
        setOpen(false)
        const initHover = {}
        for (let i=0; i<items.length; i++) {initHover[i] = false}
        setHover(initHover)
    }

    function outsideEvent(event) {
        event.preventDefault()
        event.stopPropagation()
        close()
    }

    for (let i=0; i<items.length; i++) {
        dispItems.push(
            <SDiv
                key={i}
                height={height}
                onMouseOver={() => setHover({...hover, [`${i}`]: true})}
                onMouseOut={() => setHover({...hover, [`${i}`]: false})}
                onTouchStart={() => setHover({...hover, [`${i}`]: true})}
                onTouchEnd={() => setHover({...hover, [`${i}`]: false})}
                onClick={() => clicked(i)}
                color={hover[i] ? 'b50' : null}
                width={width} halign={'start'} padLeft={0.5*height}
            >
                {items[i]}
            </SDiv>

        )
    }

    return (
        <SDiv
            height={height} width={width} valign='start' position={'relative'}
        >
            <SDiv
                direction='row' halign={'around'} height={height} width={width}
                onClick={() => open ? close() : setOpen(true)}
            >
                <SDiv height={1} width={0.6*width} halign={'center'}
                      textColor={'g400'} font={'b2'}
                >
                    {label}
                </SDiv>
                {!open && <SArrow direction={'down'}/>}
                {open && <SArrow direction={'up'}/>}

            </SDiv>
            {open &&
            <SDiv
                position={'absolute'}
                top={direction === 'down' ? 1 : null}
                bottom={direction === 'up' ? 1 : null}
                halign={'start'}
                width={width} color={'g0'}
                height={'auto'} overflow={'auto'}
                boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'} borderRadius={Hp(8)} padTop={Hp(8)} padBottom={Hp(8)}
                ref={outsideRef}
            >
                {dispItems}
            </SDiv>
            }
        </SDiv>
    )
}

