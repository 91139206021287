
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

export const imgs = importAll(require.context('Assets/IMG', false, /\.(png|jpe?g|svg)$/));
export const icons = importAll(require.context('Assets/Icons', false, /\.(png|jpe?g|svg)$/));
export const imtemp = importAll(require.context('Assets/Temp', false, /\.(png|jpe?g|svg)$/));

