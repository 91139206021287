import React, {useContext} from "react";

import {Hp, Wp} from "utils/util";
import {SDiv} from "components/library/styledhtml";
import {CompositionView} from "components/library/intake/viewIntake/CompositionView";
import {ConsumableView} from "components/library/intake/viewIntake/ConsumableView";
import {observer} from "mobx-react-lite";
import {GlobalStoreContext} from "models/rootStore";
import {SArrow} from "components/library/basic/SArrow";



function NutOrgNameView(props) {

    const width = props.width
    const height = props.height
    const level = props.level
    const name = props.name
    const group = props.group

    return (
        <SDiv
            width={Wp(0.9)} height={Hp(50)} boxShadow={'normal'} borderRadius={10}
            halign={'start'}
            direction={'row'}
            color={'a100'}
        >
            <SDiv width={Wp(10)}/>
            {name}
        </SDiv>
    )
}


export const NutOrgGroupView = observer((props) => {

    const height = props.height
    const level = props.level
    const name = props.name
    const group = props.group
    const width = props.width - Wp(10) * group.level

    const txtWidth = 0.8*width
    console.log("HERE", name, group.level)
    //const subwidth = getSubWidth()
    //function getSubWidth(thiswidth) {
    //    return Math.max(thiswidth - 8, Wp(0.7))
    //}

    const indent = Hp(20) * group.level
    return (
        <SDiv halign={'end'}>
            <SDiv
                width={width} height={group.isOpen ? null : height}
                direction={'row'} halign={'start'}
                borderRadius={4} borderWidth={1} borderColor={'g100'} borderStyle={'solid'}
                color={'g0'}
                boxShadow={group.isOpen ? 'normal' : null}
            >
                <SDiv
                    width={Wp(40)} height={height} valign={'start'}
                    onClick={() => group.setIsOpen(!group.isOpen)}
                >
                    <SDiv height={0.3 * height}/>
                    <SDiv height={0.3 * height}>
                    <SArrow size={8} color={'g400'} direction={group.isOpen ? 'down' : 'right'}/>
                        </SDiv>
                        <SDiv
                            height={0.2 * height}
                            textColor={'g300'} font={'b5'}
                        >
                            {group.length}
                        </SDiv>
                    </SDiv>
                    <SDiv
                        width={txtWidth} height={height}
                        valign={'start'} halign={'start'}
                    >
                        <SDiv width={txtWidth} height={0.32 * height}/>
                        {group.name}
                    </SDiv>
                </SDiv>
                {group.isOpen && getGroupView(group)}
            </SDiv>
        )
})


function getGroupView(nutOrg) {

    if (!nutOrg) return

    const groups = []
    for (let i=0; i<nutOrg.subs.length; i++) {
        const sub = nutOrg.subs[i]
        if (sub.type === 'group') {
            groups.push(
                <NutOrgGroupView key={i} width={Wp(0.9)} name={sub.name} group={sub}/>
            )
        }
        else if (sub.type === 'nut') {
            groups.push(
                <NutOrgNameView key={i} name={sub.name}/>
            )
        }
    }
    return groups

}


export const NutrientOrgView = observer((props) => {

    const width = props.width
    const height = props.height
    const nutOrg = props.nutOrg
    nutOrg.setRelativeLevels(0)

    return (
        <SDiv>
            {getGroupView(nutOrg)}
        </SDiv>
    )

})
