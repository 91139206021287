
import React from "react";
import styled from "styled-components";
import {getColor, stheme} from "themes/theme";


const StyleImg = styled.img(props => ({
    width: props.width,
    height: props.height,
    fill: props.fill,
    alt: 'missing',
    'clip-path': props.clipPath,
    position: props.position,
    top: props.top,
    bottom: props.bottom,
    left: props.left,
    right: props.right,

    'border': props.border,
}));

export function SImg(props) {

    var borderColor = getColor(props.borderColor, false)

    const borderWidth = props.borderWidth ? props.borderWidth : 2
    var border
    if (borderColor) {
        border = `${borderWidth}px solid ${borderColor}`
    }

    return (
        <StyleImg
            {...props}
            border={border}
        />
    )
}