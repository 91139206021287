import React, {useState, useEffect, useContext} from "react";
import {navigate} from "@reach/router";

import useImage from "use-image";
import {Layer, Group, Image, Rect, Stage, Text} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Image"
import "konva/lib/shapes/Text"
import "konva/lib/shapes/Rect"


import {SDiv} from "components/library/styledhtml/SDiv";
import {SMenu} from "components/library/basic/SMenu";
import {SButton} from "components/library/styledhtml/SButton";
import {SImg} from "components/library/styledhtml/SImg";
import {Hp, Wp} from "utils/util";
import {stheme} from "themes/theme";
import {imgs, icons} from "utils/images";

import {PersonalizeHeader} from "components/pages/personalization/PersonalizeHeader";
import {InputPosSlider} from "components/library/inputs/InputPosSlider";
import {ModalAmountInput} from "components/library/inputs/ModalAmountInput";

import {PERSONALIZE, APP} from "components/paths";
import {SEXSEL} from "components/pages/personalization/paths";
import {GlobalStoreContext} from "models/rootStore";

const maxHandCm = 30
const minHandCm = 10
const fullHandImgH = 800
const fullHandImgW = 400
// tip to bottom of palm in pixels
const handLenPixFrac = 700/fullHandImgH
// tip offset from top left corner
const top2tipFrac = 12 / fullHandImgH
const left2tipFrac = 212 / fullHandImgW
// card image
const fullCardImgH = 400
const fullCardImgW = 256
const realCardCmH = 8.56  // 3 3/8 in
const realCardCmW = 5.398  // 2 1/8 in


function position2SizeCm(pos) {
    // position = 0, handsize = realCardCmH
    return (1-pos) * minHandCm + pos * maxHandCm
}

function sizeCm2position(size) {
    return Math.min(1, Math.max(0, (size - minHandCm) / (maxHandCm - minHandCm)))
}


function HandLength(props) {

    const height = props.height
    const width = props.width
    const frac = props.frac

    const [imgFinger] = useImage(imgs['hand_palm_fullsize.png'])
    const [imgCard] = useImage(imgs['card.png'])
    const imgH = height
    const imgW = imgH * fullHandImgW/fullHandImgH

    const handLenCm = position2SizeCm(frac)
    const handLenPix = imgH * handLenPixFrac
    const distCmPerPix = handLenCm / handLenPix
    const cardH = realCardCmH / distCmPerPix
    const cardW = cardH * fullCardImgW/fullCardImgH

    const handImgX = 0.5*(width-imgW)
    return (
        <Stage width={width} height={height}>
            <Layer>
                <Image image={imgFinger} height={imgH} width={imgW} x={handImgX}/>
                <Rect
                    width={3}
                    height={handLenPix}
                    fill={stheme.color.brands.b500}
                    x={handImgX + imgW * left2tipFrac} y={imgH * top2tipFrac}
                />
                <Image
                    image={imgCard}
                    height={cardH} width={cardW}
                    x={0.5 * (width-cardW)} y={0.5 * (height-cardH)}
                />
            </Layer>
        </Stage>

    )
}


export function HandSize(props) {

    const gstore = useContext(GlobalStoreContext)
    var initSize = gstore.profile.handLen // cm
    const setNext = props.setNext
    if (!initSize) {initSize = 17.78}
    const [unit, setUnit] = useState('in')
    const [sliderPos, setSliderPos] = useState(sizeCm2position(initSize))
    const [handLen, setHandLen] = useState(position2SizeCm(sliderPos))
    const [modalDisplay, setModalDisplay] = useState(false)

    function done() {
        gstore.profile.setHandLen(handLen)
        if (setNext) {setNext()}
    }

    useEffect(() => {
        setHandLen(position2SizeCm(sliderPos))
    }, [sliderPos])


    function dispLen() {
        var dispL = handLen
        if (unit === 'in') {dispL /= 2.54}
        return dispL
    }

    function closeModal() {
        setModalDisplay(false)
    }
    function setNewLen(len) {
        // set new length here based on unit
        var newLen = len
        if (unit === 'in') {newLen *= 2.54}
        setHandLen(newLen)
        setSliderPos(sizeCm2position(newLen))
    }

    const subText = ''
    const fingtxt = 'Estimate the size of your hand\nmatched to the size of a credit card'
    return (
        <SDiv width={Wp(0)} valign={'start'}>
            {/*<PersonalizeHeader num={2} title={title}/>*/}
            <SDiv height={Hp(48)} font={'b2'} textColor={'g400'} textAlign={'center'} manualWrap>
                {fingtxt}
            </SDiv>
            <SDiv width={Wp(0)} height={Hp(300)} direction={'row'}>
                <SDiv width={Wp(80)} height={Hp(300)}/>
                <SDiv width={Wp(0)-Wp(160)} height={Hp(300)}>
                    <HandLength width={Wp(0)-Wp(160)} height={Hp(240)} frac={sliderPos}/>
                    <SDiv
                        height={Hp(60)} width={Wp(0)-Wp(160)}
                        direction={'row'} halign={'start'}
                    >
                        <SDiv width={Wp(82)} textAlign={'center'}>Hand length</SDiv>
                        <SDiv width={Wp(8)}/>
                        <SDiv
                            height={Hp(32)} width={Wp(80)}
                            borderRadius={6} borderColor={'g200'}
                            onClick={() => setModalDisplay(true)}
                        >
                            {dispLen().toFixed(2)}
                        </SDiv>
                        <SMenu
                            width={Wp(75)}
                            items={['cm', 'in']} direction={'up'} label={unit} onSelect={setUnit}
                        />
                    </SDiv>
                </SDiv>
                <SDiv width={Wp(80)} height={Hp(300)}>
                    {!modalDisplay && <InputPosSlider height={Hp(300)} width={Wp(80)} vertical initPos={1-sliderPos} setPos={setSliderPos}/>}
                </SDiv>
            </SDiv>
            <SDiv height={Hp(6)} width={Wp(0)}/>
            <SButton height={Hp(40)}
                     position={'absolute'} bottom={Hp(20)}
                     disabled={initSize === handLen}
                     onClick={() => done()}
            >
                Save
            </SButton>
            {modalDisplay &&
            <ModalAmountInput onClose={closeModal} onSave={setNewLen} subText={subText} unit={unit} title={'Input hand length'}
            />}
        </SDiv>
    )
}