import React, {useLayoutEffect} from "react";
import {useSpring} from "react-spring";
import styled from "styled-components";

import {SDiv, SDivAnim} from "components/library/styledhtml/SDiv";
import {Hp, Wp} from "utils/util";
import {Close} from "grommet-icons/es6";

// Hook

function useLockBodyScroll() {

    useLayoutEffect(() => {

        // Get original body overflow

        const originalStyle = window.getComputedStyle(document.body).overflow;

        // Prevent scrolling on mount

        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when component unmounts

        return () => document.body.style.overflow = originalStyle;

    }, []); // Empty array ensures effect is only run on mount and unmount

}

const StyleModal = styled.div(props => ({

    // toggle display
    display: props.display ?? 'block',
    // sizing
    height: '100%',
    width: '100%',
    position: 'fixed',
    // on top
    'z-index': 2,
    left: 0,
    top: 0,
    overflow: props.overflow ?? 'auto',
    'background-color': props.backgroundColor,
}));


export function SModal(props) {

    useLockBodyScroll()

    const halign = props.halign ? props.halign : 'center'
    const valign = props.valign ? props.valign : 'center'
    const onClick = props.onClick
    const onClose = props.onClose
    const title = props.title ? props.title : ''
    const titleColor = props.titleColor
    const titleHeight = props.titleHeight ? props.titleHeight : Hp(40)

    const sideFrac = props.sideFrac ? props.sideFrac : 0
    const topFrac = props.topFrac ? props.topFrac : 0

    var topGap = props.topGap ? props.topGap : topFrac ? Hp(topFrac) : 0
    var bottomGap = props.bottomGap ? props.bottomGap : topFrac ? Hp(topFrac) : 0
    var leftGap = props.leftGap ? props.leftGap : sideFrac ? Wp(sideFrac) : 0
    var rightGap = props.rightGap ? props.rightGap : sideFrac ? Wp(sideFrac) : 0

    const modalWidth = props.modalWidth ? props.modalWidth : Wp(0) - leftGap - rightGap
    const modalHeight = props.modalHeight ? props.modalHeight : Hp(0) - topGap - bottomGap

    function clicked(e) {
        if (onClick) onClick(e)
    }

    var slideAnim
    var [slideRight, setSlideAnim, stopSlideAnim] = useSpring(() => (
        {
            from: {left: -modalWidth},
            to: {left: 0},
            config: {duration: 300}
        }
    ))
    if (props.transition === 'slideRight') {
        slideAnim = slideRight
    }

    function runClose(e) {
        if (slideAnim && props.transition === 'slideRight') {
            e.stopPropagation()
            setSlideAnim(
                {
                    from: {left: 0},
                    to: {left: -modalWidth},
                    config: {duration: 300}
                }
            )
            setTimeout(() =>{
                closeit()
            }, 360)
        }
        else {
            closeit()
        }
    }
    function closeit() {
        if (onClose) {onClose()}
    }

    const closeWidth = onClose ? Hp(50) : Hp(10)
    const backgroundColor = 'rgb(0,0,0,0.6)' /* Black w/ opacity */
    return (
        <StyleModal
            {...props}
            backgroundColor={backgroundColor}
            tabIndex={'0'}
            onClick={(e) => clicked(e)}
            // onTouchMove={(e) => e.stopPropagation()} // causing issues with volume slider in mobile
        >
            <SDiv
                halign={halign} valign={valign}
            >
                <SDiv height={topGap}/>
                <SDiv direction={'row'}>
                    <SDiv width={leftGap}/>
                    <SDivAnim
                        style={slideAnim}
                        height={modalHeight} width={modalWidth}
                        valign={'start'}
                        color={'g0'} borderRadius={Hp(12)}
                        position={slideAnim ? 'relative' : null}
                    >
                        {(onClose || title) &&
                        <SDiv
                            width={modalWidth}
                            height={titleHeight}
                            direction={'row'} halign={'end'}
                        >
                            <SDiv height={titleHeight} width={closeWidth}/>
                            <SDiv
                                height={titleHeight} width={modalWidth - 2*closeWidth}
                                font={'h4'} textAlign={'center'} valign={'end'} textColor={titleColor}
                            >
                                {title}
                            </SDiv>
                            <SDiv height={Hp(40)} width={closeWidth}>
                                {onClose &&
                                <Close size={`${Hp(16)}px`}
                                       onClick={(e) => runClose(e)}
                                />
                                }
                            </SDiv>
                        </SDiv>
                        }
                        {props.children}
                    </SDivAnim>
                    <SDiv width={rightGap}/>
                </SDiv>
                <SDiv height={bottomGap}/>
            </SDiv>
        </StyleModal>
    )
}

