import {flow, types} from "mobx-state-tree";
import {utc} from "utils/util";
import {callApi} from "api/api";
import {rawToken} from "models/security/token";
import {observable} from "mobx";


export const MeasureData = types
    .model({
        name: types.optional(types.string, ""),
        units: types.optional(types.string, ""),
        id: types.array(types.string),
        data: types.array(types.number),
        lhr: types.array(types.number),
        uhr: types.array(types.number),
        time: types.array(types.number),
    })
    .actions(self => ({
        doNothing(text) {
        },
    }))
    .views(self => ({
        get nothing() {
            return
        },
        get length() {
            return self.data.length
        }
    }))


export const ReadMeasure = types
    .model({
        name: types.string,
        units: types.string,
        measureData: types.optional(MeasureData, {}),
        loadTime: types.optional(types.number, 0),
        state: types.optional(types.enumeration("State", ["init", "pending", "done", "error"]), 'init'),
    })
    .actions(self => ({
        doNothing(text) {
        },
        load: flow(function* load() {

            // create condition to only load initially or after some time
            //if (self.name) {return}
            if (self.loadTime > 0) {return}

            const max_time = utc() / 1000.;  // current time
            self.state = "pending";
            try {
                const retData = yield callApi(
                    'LabsMeasuresService', 'read_measure',
                    {'token': rawToken(), 'name': self.name, 'units': self.units}
                );

                // todo: the backend could return more than one for both numerical and
                // categorical, so this needs to be fixed/cleaned up
                if (retData.length > 0) {
                    self.measureData = MeasureData.create(retData[0])
                }

                self.state = "done";
                self.loadTime = utc()
            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch read measures", error);
                self.state = "error"
            }
        }),
    }))
    .views(self => ({
        get nothing() {
            return
        },
        get length() {
            console.log("CHeck", self.name, self.measureData.length)
            return self.measureData.length
        },
        get hasData() {
            return self.state === "done"
        }
    }));