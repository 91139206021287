import React from "react";
import {Router} from "@reach/router";

import {Blm} from "components/pages/about/Blm";
import {AboutSimpa} from "components/pages/about/AboutSimpa";
import {YourData} from "components/pages/about/YourData";
import {getDMdimensions} from "utils/util";

export function About(props) {

    const [width, height] = getDMdimensions()

    return (
        <Router>
            <Blm path={`/blm`}/>
            <AboutSimpa path={`/simpa`}/>
            <YourData path={`/yourdata`}/>
        </Router>
    )
}