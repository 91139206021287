import React from "react";
import {Router} from "@reach/router";

import {GoalsHome} from "components/pages/goals/GoalsHome";

export function Goals(props) {

    return (
        <Router>
            <GoalsHome path={'/'}/>
        </Router>
    )
}