
import React from "react";

import {Consumable} from "models/intake/consumable";
import {Composition} from "models/intake/composition";


export function cleanText(text) {

    var newValue = text.trim()
    newValue = newValue.replace(/\n+/g, '.')
    newValue = newValue.replace(/:\s*\./g, ': ')
    newValue = newValue.replace(/\s+/g, ' ')
    newValue = newValue.replace(/\s*\.\s*/g, '.')
    newValue = newValue.replace(/\s*\:\s*/g, ':')
    newValue = newValue.replace(/\s*,\s*/g, ',')
    newValue = newValue.replace(/,\./g, '.')
    newValue = newValue.replace(/,:/g, ',')
    newValue = newValue.replace(/\.:/g, ',')
    newValue = newValue.replace(/:,/g, ',')
    newValue = newValue.replace(/:\./g, ',')
    newValue = newValue.replace(/\.,/g, ',')
    newValue = newValue.replace(/,+/g, ',')
    return newValue
}

function getCon(desc) {
    return Consumable.create({type: 'con', id: '', desc: desc, g: 1, dens: 1})
}

function getComp(compTitle, subsdesc, setOpen = false) {
    return Composition.create({
        type: 'comp',
        id: '',
        desc: compTitle,
        subs: parseText(subsdesc, setOpen),
        open: setOpen
    })
}

// return an array of Composition or Consumables
export function parseText(text, setOpen = false) {

    const subs = []
    var curTxt = []
    var compCount = 0
    var compTitle = ''

    for (let i = 0; i < text.length; i++) {
        let c = text.charAt(i)
        if (c === ',' && compCount === 0) {
            if (curTxt.length) subs.push(...parseText(curTxt.join(''), setOpen))
            curTxt = []
        } else if (c === '.') {
            if (compCount > 0) {
                if (curTxt.length) subs.push(getComp(compTitle, curTxt.join(''), setOpen))
            } else {
                if (curTxt.length) subs.push(...parseText(curTxt.join(''), setOpen))
            }
            compTitle = ''
            compCount -= 1
            compCount = Math.max(0, compCount)
            curTxt = []
        } else if (c === ':' && compCount === 0) {
            compCount += 1
            compTitle = curTxt.join('')
            curTxt = []
        } else {
            curTxt.push(c)
        }
    }
    if (compTitle || curTxt.length) {
        if (!compTitle) {
            subs.push(getCon(curTxt.join('')))
        } else {
            subs.push(getComp(compTitle, curTxt.join(''), setOpen))
        }
    }
    return subs

}