// Composition
import {types, clone, flow} from "mobx-state-tree";
import {utc} from "utils/util";
import {callApi} from "api/api";



export const NutrientName = types
    .model({
        name: types.optional(types.string, ""),
        dbname: types.optional(types.string, ""),
        level: types.optional(types.integer, 0),
        type: types.literal('nut'),
    })
    .actions(self => ({
        doNothing(text) {
        },
        setRelativeLevels(level) {
            self.level = level
        }
    }))
    .views( self => ({
        get hasSubs() {
            return true
        },
    }));



export const NutrientOrg = types
    .model({
        name: types.optional(types.string, ""),
        subs: types.array(types.union(types.late(() => NutrientOrg), NutrientName)),
        type: types.literal('group'),
        open: types.optional(types.boolean, false),
        level: types.optional(types.integer, 0),
        loadTime: types.optional(types.number, 0),
    })
    .actions(self => ({
        doNothing(text) {
        },
        setIsOpen(val) {
            self.open = val
        },
        setRelativeLevels(level) {
            self.level = level
            self.subs.forEach(function (sub) {
                sub.setRelativeLevels(level+1)
            })
        },
        load: flow(function* load() {

            // create condition to only load initially or after some time
            //if (self.name) {return}

            const max_time = utc() / 1000.;  // current time
            self.state = "pending";
            try {
                const retData = yield callApi(
                    'SimpaNutrition', 'get_nutrient_organization', {}
                );
                self.name = retData.name
                self.subs.clear()
                for (const ind in retData.subs) {
                    var sub = retData.subs[ind]
                    var newSub
                    if (sub.type === 'nut') {
                        newSub = NutrientName.create(sub)
                    }
                    else {
                        newSub = NutrientOrg.create(sub)
                    }
                    self.subs.push(newSub)
                }
                self.state = "done";
                self.loadTime = utc()
            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch nutrient organization", error);
                self.state = "error"
            }
        }),
    }))
    .views( self => ({
        get isOpen() {
            return self.open
        },
        get hasSubs() {
            return true
        },
        get length() {
            return self.subs.length
        }
    }));
