import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";
import {utc2Format} from "utils/dates";
import {SDiv} from "components/library/styledhtml";
import {Hp, Wp} from "utils/util";
import {LineChart as LineChartIcon} from "grommet-icons/es6";
import {ReadMeasure} from "models/labs/readMeasure";
import {Spinner} from "components/library/common/Spinner";
import {stheme} from "themes/theme";

import {Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Label} from "recharts";


export const MeasureGraph = observer((props) => {

    const [domain, setDomain] = useState(['dataMin', 'dataMax'])
    const [hasLhr, setHasLhr] = useState(false)

    const rM = props.readMeasure
    // reorganize data
    const m = rM.measureData.toJSON()
    //console.log(m)
    const data = []
    for (let i=0; i<m.data.length; i++) {
        const uhrval = m.uhr[i] === Infinity ? null : m.uhr[i]
        data.push(
            {'time': m.time[i], 'value': m.data[i], 'lhr': m.lhr[i],
                'uhr': m.uhr[i] ===  Infinity ? null : m.uhr[i],
            }
        )
    }

    // ensure sort by time
    data.sort((a, b) => (a.time > b.time) ? 1 : -1)
    return (
        <SDiv width={1}>
            {!props.readMeasure.hasData && <Spinner/>}
            {props.readMeasure.hasData &&
                <SDiv>
                    <LineChart
                        width={Wp(0)} height={Hp(0.2)} data={data}
                        margin={{ top: Hp(20), right: Hp(20), left: Hp(20), bottom: Hp(20) }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            dataKey="time"
                            tickFormatter={utc2Format}
                            style={{ fontSize: '70%'}}
                            angle={0}
                            domain={domain}
                            type="number"
                        >
                            {/*
                            <Label
                                value={"date"}
                                position="bottom"
                                style={{ textAnchor: "middle", fontSize: '80%'}}
                            />
                            */}
                        </XAxis>
                        <Tooltip
                            labelFormatter={utc2Format}
                        />
                        <YAxis
                            allowDataOverflow
                            //domain={['auto', 'auto']}
                            domain={[
                                dataMin => (Math.max(0, dataMin - 0.2*Math.abs(dataMin))),
                                dataMax => (dataMax + 0.1*Math.abs(dataMax))
                            ]}
                            type="number"
                            yAxisId="data"

                        >
                            <Label
                                value={m.units}
                                position="insideLeft"
                                offset={Hp(10)}
                                angle={-90}
                                style={{ textAnchor: "middle", fontSize: '80%'}}
                            />
                        </YAxis>

                        <Line
                            dataKey="uhr"
                            yAxisId="data"
                            //type="monotone"
                            connectNulls
                            isAnimationActive={false}
                            stroke={stheme.color.stat.danger500} />
                        />
                        <Line
                            dataKey="value"
                            yAxisId="data"
                            //type="monotone"
                            connectNulls
                            isAnimationActive={false}
                            stroke={stheme.color.brand.b800} />
                        />
                        <Line
                            dataKey="lhr"
                            yAxisId="data"
                            //type="monotone"
                            connectNulls
                            isAnimationActive={false}
                            stroke={stheme.color.stat.danger500} />
                        />
                    </LineChart>
                </SDiv>
            }
        </SDiv>
    )

})


export const LabBox = observer((props) => {

    // LabPoint model
    const lP = props.labPoint

    const [showGraph, setShowGraph] = useState(false)
    const [rM, setRm] = useState(
        ReadMeasure.create({'name': lP.name, 'units': lP.units})
    )

    function plotGraph() {
        rM.load()
        setShowGraph(true)
    }

    return (
        <SDiv
            width={Wp(0)}
            // height={Hp(50)}
            boxShadow={'normal'} borderRadius={Hp(6)}
            marginTop={Hp(6)} marginBottom={Hp(6)}
            paddingTop={Hp(8)} paddingBottom={Hp(6)}
        >
            <SDiv direction={'row'}>
                <SDiv width={Wp(0.7)}>
                    <SDiv font={'b4'} halign={'center'}>
                        {lP.name}
                    </SDiv>
                    <SDiv font={'b5'} textColor={lP.outOfRange === 1 ? 'red' : 'green'}>
                        {lP.data} {lP.units} ({lP.showDate})
                    </SDiv>
                    <SDiv font={'b5'}>
                        {lP.lhr} - {lP.uhr}
                    </SDiv>
                </SDiv>
                <SDiv width={Wp(0.2)} height={Hp(50)}>
                    <LineChartIcon onClick={() => plotGraph()}/>
                </SDiv>
            </SDiv>
            {showGraph &&
            <MeasureGraph readMeasure={rM}/>
            }
        </SDiv>
    )
})