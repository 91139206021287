import React from "react";
import {Router} from "@reach/router";

import {ResponsiveLayout} from "components/ResponsiveLayout/responsiveLayout";
import {DesktopLanding} from "components/pages/landing/DesktopLanding";
import {MobileLanding} from "components/pages/landing/MobileLanding";


function GetLanding(props) {
    if (window.innerWidth > window.innerHeight) return <DesktopLanding/>
    else return <MobileLanding/>
}

export function Landing(props) {

    return (
        <Router>
            {/*
            <GetLanding path={'/'}/>
            */}
            <ResponsiveLayout
                path={'/'}
                renderDesktop={() => (<DesktopLanding/>)}
                renderMobile={() => (<MobileLanding/>)}
            />
        </Router>
    )
}