

import {types} from "mobx-state-tree";
import {Composition} from "models/intake/composition";


export const Intakes = types.model({
    morning: types.map(Composition),
    afternoon: types.map(Composition),
    evening: types.map(Composition),
    night: types.map(Composition),
}).actions(self => ({

})).views(self => ({

}));


export const TwoWeekIntake = types.model({
    intakes: Intakes,
}).actions(self => ({
    addIntake(timeperiod, num, comp) {
        if (self.intakes.hasOwnProperty(timeperiod)) {
            if (num >= 0 && num < 14) {
                var currentComp = null
                if (self.intakes[timeperiod].hasOwnProperty(num)) {
                    currentComp = self.intakes[timeperiod][num]
                }
                if (currentComp && currentComp.id === comp.id) {
                    self.intakes[timeperiod][num] = null
                }
                else {
                    self.intakes[timeperiod][num] = comp
                }

            }
        }
    },
})).views( self => ({
    intake(timeperiod, num) {
        if (self.intakes.hasOwnProperty(timeperiod)) {
            if (self.intakes[timeperiod].hasOwnProperty(num)) {
                return self.intakes[timeperiod][num]
            }
        }
        return null
    },
}));
