
import {useState} from 'react';
import {decode, encode} from "@msgpack/msgpack";
import lz4 from 'lz4js';

import {navigate} from "@reach/router";
import {rawToken, apiValidateToken, isTokenValid, deleteToken} from "models/security/token";


export const WSURL = `wss://ws2.simpa.io`
const APIURL = 'https://api2.simpa.io'
const APIROUTE = 'api'
const AUTHROUTE = 'authentication'
const AUTHVER = 'Authentication'

// default values
const MSGPACK = true
const COMPRESSED = false


function getUrl(service, method) {
    return `${APIURL}/${APIROUTE}/${service}/${method}`;
}

function getAuthUrl(method) {
    return `${APIURL}/${AUTHROUTE}/${AUTHVER}/${method}`;
}


export async function callAlive() {

    const url = `${APIURL}/ping`;
    const response = await fetch(url, {
            method: 'GET',
        }
    )

    const data = await response.text()
    if (!response.ok) {
        throw new Error(data);
    }
    return data;
}



export async function getUrlBytes(url) {

    const response = await fetch(url, {
            method: 'GET',
            //mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'image/webp,*/*'
            }
        }
    )

    const data = await response.body()
    if (!response.ok) {
        throw new Error(data);
    }
    return data;
}

export async function callAvail() {

    const url = `${APIURL}/helper/available_services`;
    const response = await fetch(url, {
            method: 'GET',
        }
    )

    const data = await response.text()
    if (!response.ok) {
        throw new Error(data);
    }
    return data;
}


export function unpackData(data, compressed) {
    // unpacking compressed and messagepack data
    if (compressed) {
        data = decode(data);
        data = lz4.decompress(data);
    }
    return decode(data)
}


async function callGenericApi(caller, service, method, params, msgpack=MSGPACK, compressed=COMPRESSED) {

    var cont_type = 'application/json'
    var cont_encoding = ''

    var body = ''
    if (msgpack || compressed) {
        cont_type = 'application/msgpack'
        body = encode(params)
    }
    else {
        body = JSON.stringify(params)
    }
    if (compressed) {
        cont_encoding = 'lz4'
        body = lz4.compress(body)
    }

    const headers = {
        'Content-Type': cont_type,
        'Content-Encoding': cont_encoding,
        'Timeout': 10,
    }

    var url = null
    if (caller === 'api') {
        url = getUrl(service, method)
        if (!isTokenValid([], true)) {
            navigate('/')
            throw Error('invalid token')
        }
        headers.Authorization = `Bearer ${rawToken()}`
    }
    else if (caller === 'auth') {
        url = getAuthUrl(method)
    }
    else {throw Error('api or auth')}

    const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body,
        }
    )

    //const
    var data
    try {
        if (compressed || msgpack) {
            data = unpackData(await response.arrayBuffer(), compressed)
        }
        else {
            data = await response.json();
        }
    }
    catch {
        if ((response.status === 401) || (response.status === 403)) {
            apiValidateToken()
        }
        throw new Error(response);
    }

    if (!response.ok) {
        throw new Error(response);
    }
    return data;
}


const useGenericApi = (caller, service=null, method=null, msgpack=MSGPACK, compressed=COMPRESSED) => {

    if (caller !== 'api' && caller !== 'auth' && caller !== 'alive' && caller !== 'avail'
    ) {throw Error('api or auth')}

    const [loading, setLoading] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [counter, setCounter] = useState(0);

    // The incoming "action" argument to the hook is NOT performed.
    // It is only stored in the function scope; so that, we can use it when
    // performing the action using the following function
    // This function is returned as the second element in the returned array
    const makeCall = async (params) => {
        try {
            setLoading(true);
            setData(null);
            setError(null);
            setHasData(false)
            var data = null
            if (caller === 'api') {
                data = await callApi(service, method, params, msgpack, compressed);
            }
            else if (caller === 'auth') {
                data = await callAuthentication(method, params, msgpack);
            }
            else if (caller === 'alive') {
                data = await callAlive();
            }
            else if (caller === 'avail') {
                data = await callAvail();
            }
            else {throw Error('bad call')}
            setData(data);
            setHasData(true);
            setCounter(counter+1)
        } catch (e) {
            if (caller === 'api') {
                apiValidateToken()
            }
            setError(e);
        } finally {
            setLoading(false);
        }
    }
    return [{ loading, hasData, data, error, counter }, makeCall];
}

export const useAlive = () => {
    return useGenericApi('alive', '', '')
}

export const useAvail = () => {
    return useGenericApi('avail', '', '')
}

export async function callApi(service, method, params, msgpack=MSGPACK, compressed=COMPRESSED) {
    return await callGenericApi('api', service, method, params, msgpack, compressed)
}

export const useApi = (service, method, msgpack=MSGPACK, compressed=COMPRESSED) => {
    return useGenericApi('api', service, method, msgpack, compressed)
}

export async function callAuthentication(method, params, msgpack=MSGPACK) {
    return await callGenericApi('auth', '', method, params, msgpack)
}

export const useAuthentication = (method, msgpack=MSGPACK) => {
    return useGenericApi('auth', '', method, msgpack)
}
