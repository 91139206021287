import React, {useState, useEffect} from "react";
import {useSpring, useTransition, animated} from 'react-spring'
import {Spring} from 'react-spring/renderprops'

import {navigate} from "@reach/router";

import {Wp, Hp} from "utils/util";
import {SDiv, SDivAnim} from "components/library/styledhtml/SDiv";
import {SImg} from "components/library/styledhtml/SImg";
import {SButton} from "components/library/styledhtml/SButton";
import {SModal, SModalAnim} from "components/library/basic/SModal";

import {imgs} from "utils/images";
import {HOME, ONBOARD, APP, DEMO} from "components/paths";
import {useApi} from "api/api";
import {rawToken} from "models/security/token";

//const continueTo = `/${APP}/${HOME}`
const continueTo = `/${APP}/${DEMO}`
const smSz = 60
const FINALTOP = 292


function MessageBox(props) {

    return (
        <SDiv
            height={Hp(48)} width={Wp(108)}
            font={'b4'} textColor={'g500'} textAlign={'center'}
            boxShadow={'0px 4px 16px rgba(0, 0, 0, 0.16)'} borderRadius={'8px'}
            manualWrap
        >
            {props.children}
        </SDiv>
    )
}


export function HelloPage(props) {

    const txt = 'I collect data about nutrition, \n' +
        'observations and health records,\n' +
        'analyse it, and give suggestions'
    return (
        <SDiv height={Hp(0)} width={Wp(0)} valign={'start'}>
            <SDiv height={Hp(32)} width={Wp(0)}/>
            <SDiv font={'h3'} textColor='g900' height={Hp(28)} width={Wp(0)}>
                Hello, I'm Simpa!
            </SDiv>
            <SDiv height={Hp(16)} width={Wp(0)}/>
            <SDiv font={'b2'} textColor='g400' height={Hp(72)} width={Wp(0)}
                  textAlign={'center'} manualWrap
            >
                {txt}
            </SDiv>
            <SDiv height={Hp(32)} width={Wp(0)}/>
            <SImg height={Hp(192)} width={Wp(300)} src={imgs['image_algorithm_004@2x.png']}/>
            <SDiv
                top={Hp(FINALTOP)} height={Hp(smSz)} width={Hp(smSz)}
                backgroundImage={imgs['simpa_logo_blue@2x.png']} position={'absolute'}
                borderRadius={100} boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'}
            />

            <SDiv direction={'row'} halign={'around'} height={Hp(48)} width={Wp(0)}>
                <MessageBox>
                    {'Stomach pain vs\nsaturated fat'}
                </MessageBox>
                <MessageBox>
                    {'Your cholesterol\nhas decreased'}
                </MessageBox>
                <MessageBox>
                    {'Your diet seems\nlow in vitamin D'}
                </MessageBox>
            </SDiv>
            <SDiv height={Hp(44)} width={Wp(0)}/>
            <SButton
                height={Hp(48)} width={Wp(327)}
                onClick={() => navigate(continueTo)}
            >
                Continue
            </SButton>
            <SDiv height={Hp(24)} width={Wp(0)}/>
        </SDiv>
    )

}

function OnboardStartProfile(props) {

    const onClose = props.onClose

    const topGap = Hp(0.1)
    const bottomGap = Hp(0.3)
    const leftGap = Wp(0.05)
    const rightGap = Wp(0.05)
    const modalWidth = Wp(0) - leftGap - rightGap
    const modalHeight = Hp(0) - topGap - bottomGap

    return (
        <SModal
            topGap={topGap} bottomGap={bottomGap} leftGap={leftGap} rightGap={rightGap}
            modalWidth={modalWidth} modalHeight={modalHeight}
            onClose={onClose} title={'Start your journey!'}
        >
        </SModal>
    )
}



export function OnboardingTransition(props) {

    const [showStart, setShowStart] = useState(false)
    const [success, setSuccess] = useState(false)
    const [addQuest, callAddQuest] = useApi('SimpaService', 'add_behavior', true);

    const topGap = Hp(0.1)
    const bottomGap = Hp(0.3)
    const leftGap = Wp(0.05)
    const rightGap = Wp(0.05)
    const modalWidth = Wp(0) - leftGap - rightGap
    const modalHeight = Hp(0) - topGap - bottomGap


    useEffect(() => {
        if (addQuest.data) {
            setSuccess(true)
        }
    }, [addQuest.counter])

    function callStartQuest() {
        const params = {token: rawToken(), behavior: 'bonboardwelcome'}
        callAddQuest(params)
    }

    function onClose() {
        setShowStart(false)
        navigate(`${HOME}`)
    }

    const animTime = 1400
    const bigSz = 96
    const imgAnim = useSpring({
        to: [
            {opacity: 1, height: Hp(bigSz), width: Hp(bigSz), config: {duration: 0.5*animTime}},
            {opacity: 1, height: Hp(smSz), width: Hp(smSz), config: {duration: 0.5*animTime}},
            ],
        from: {opacity: 0, height: Hp(smSz), width: Hp(smSz)}
    })

    useEffect(() =>{
        setTimeout(function () {
            setShowStart(true)
        }, animTime);
    }, [])


    return(
        <SDiv height={Hp(0)} width={Wp(0)} valign={'center'}>
            <SDivAnim style={imgAnim} backgroundImage={imgs['simpa_logo_blue@2x.png']} position={'absolute'}
                      borderRadius={100} boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'}
            />
            {showStart &&
            <Spring
                from={{modalWidth: 0.8*modalWidth, modalHeight: 0.3*modalHeight}}
                to={{modalWidth: modalWidth, modalHeight: modalHeight}}
            >
                {props =>(
                    <SModal
                        {...props}
                        //modalWidth={modalWidth} modalHeight={modalHeight}
                        //onClose={onClose}
                        topGap={topGap} bottomGap={bottomGap} leftGap={leftGap} rightGap={rightGap}
                        title={'Start your journey!'}
                    >
                        <SDiv height={Hp(20)}/>
                        <SDiv>
                            Complete your first quest!
                        </SDiv>
                        <SDiv height={Hp(30)}/>
                        {!success &&
                        <SDiv font={'b1'} textColor={'b900'} manualWrap textAlign={'center'}>
                            {'Fill out your profile via text messaging\n' +
                            'I will text you directly on your phone\n' +
                            '(make sure to check your text messages)'}
                        </SDiv>
                            }
                        {success &&
                        <SDiv font={'b1'} textColor={'b800'} manualWrap textAlign={'center'}>
                            {'Great!  I am sending you a text now.\nCheck your text messages.'}
                        </SDiv>
                        }
                        <SDiv height={Hp(60)}/>
                        {!success &&
                        <SDiv direction={'row'}>
                            <SButton height={Hp(36)} secondary onClick={() => onClose()}>Skip</SButton>
                            <SDiv width={Wp(60)}/>
                            <SButton height={Hp(36)}
                                     onClick={() => callStartQuest()}
                            >
                                Start Quest
                            </SButton>
                        </SDiv>
                        }
                        {success &&
                            <SButton height={Hp(36)} secondary onClick={() => onClose()}>Done</SButton>
                        }
                    </SModal>
                )}
            </Spring>
            }
        </SDiv>
    )
}


export function OnboardingTransitionA(props) {

    const [showStart, setShowStart] = useState(false)

    function onClose() {
        setShowStart(false)
        navigate(`${HOME}`)
    }

    const bigSz = 96

    var [imgAnim, setimgA, stopimgA] = useSpring(() => (
        {opacity: 1, height: Hp(smSz), width: Hp(smSz), config: {duration: 400}}
    ))
    var [checkAnim, setcheckA, stopcheckA] = useSpring(() => (
        {opacity: 0, height: Hp(smSz), width: Hp(smSz), config: {duration: 100}}
    ))
    var [topAnim, settopA, stoptopA] = useSpring(() => (
        {top: 200, config: {duration: 100}}
    ))

    useEffect(() =>{
        setTimeout(function () {
            setimgA({height: Hp(bigSz), width: Hp(bigSz)})
        }, 600);
        setTimeout(function () {
            setimgA({height: Hp(smSz), width: Hp(smSz)})
        }, 1100);
        setTimeout(function () {
            setimgA({opacity: 0})
            setcheckA({opacity: 1})
        }, 1600);
        setTimeout(function () {
            settopA({top: Hp(FINALTOP), config: {duration: 500}})
            setimgA({opacity: 1})
            setcheckA({opacity: 0})
        }, 2200);
        setTimeout(function () {
            stopimgA()
            stopcheckA()
            stoptopA()
            setShowStart(true)
            //navigate(`${ONBOARD}/hello`)
            //navigate(`${HOME}`)
        }, 2900);
    }, [])

    return(
        <SDiv height={Hp(0)} width={Wp(0)} valign={'center'}>
            <SDivAnim style={topAnim} width={Hp(bigSz)} height={Hp(bigSz)} position={'absolute'}>
                <SDivAnim style={checkAnim} backgroundImage={imgs['check_blue@2x.png']} position={'absolute'}
                          borderRadius={100} boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'}
                />
                <SDivAnim style={imgAnim} backgroundImage={imgs['simpa_logo_blue@2x.png']} position={'absolute'}
                          borderRadius={100} boxShadow={'0px 8px 24px rgba(0, 0, 0, 0.12)'}
                />
            </SDivAnim>
            {showStart && <OnboardStartProfile onClose={onClose}/>}
        </SDiv>
    )
}


