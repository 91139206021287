import React from "react";
import {Router} from "@reach/router";

import {SuggestionsHome} from "components/pages/suggestions/SuggestionsHome";

export function Suggestions(props) {

    return (
        <Router>
            <SuggestionsHome path={'/'}/>
        </Router>
    )
}