import React from "react";
import {Router} from "@reach/router";

import {RecipesHome} from "components/pages/recipes/RecipesHome";

export function Recipes(props) {

    return (
        <Router>
            <RecipesHome path={'/'}/>
        </Router>
    )
}