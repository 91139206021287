import React, {useEffect, useState} from "react";

import {Hp, Wp} from "utils/util";
import {stheme} from "themes/theme";

import {SButton, SDiv, STextArea} from "components/library/styledhtml";
import {SModal} from "components/library/basic/SModal";
import {InstructionsBox} from "components/library/intake/createIntake/CreateInstructions";
import {SVerticalScroll} from "components/library/basic/SVerticalScroll";
import {CancelSaveButton} from "components/library/common/buttons/CancelSaveButton";

import {CircleQuestion} from "grommet-icons";
import {ConCompView} from "components/library/intake/viewIntake/ConCompView";
import {SelectButton} from "components/library/common/buttons/SelectButton";


export function AddIntake(props) {

    const width = props.width
    const editHeight = props.editHeight ? props.editHeight : Hp(40)

    var composition = props.composition

    const name = props.name ?? 'Build intake'

    const onClose = props.onClose
    const onSave = props.onSave

    const [showInstructions, setShowInstructions] = useState(true)
    const [rerender, setRerender] = useState(0)
    const [selected, setSelected] = useState('Text')
    const [value, setValue] = useState(composition.tmpText ? composition.tmpText : '')

    useEffect(() => {
        if (composition) {
            composition.parseText(value)
            setRerender(rerender + 1)
        }
    }, [value])


    function onValue(value) {
        value = value.replace(/return/g, '\n')
        value = value.replace(/enter/g, '\n')
        setValue(value)
    }

    function save() {
        composition.saveTemp()
        if (onClose) onClose()
    }

    const instructionH = showInstructions ? Hp(42*3 + 8*4) : 0
    const selectButtonH = Hp(30)
    const textAreaH = Hp(40)
    const cancelSaveH = Hp(36)
    const topGap = Hp(0.02)
    const bottomGap = Hp(0.01)
    const modalTop = Hp(40)
    const space = Hp(10)
    const pad = Hp(8)
    const vertScrollH = Hp(0) - topGap - modalTop - selectButtonH - instructionH - textAreaH - cancelSaveH - 4*space - bottomGap
    return (
        <SModal
            topGap={topGap} bottomGap={bottomGap} sideFrac={0.02}
            titleHeight={modalTop}
            title={
                <SDiv height={modalTop} font={'b1'} textColor={'g800'}>
                    {`Adding to: ${name}`}
                </SDiv>
            }
        >
            <SDiv
                height={Hp(24)} width={width}
                direction={'row'} halign={'around'}
            >
                <SelectButton name={'Text'} selected={selected} setSelected={setSelected} height={Hp(24)}/>
                <CircleQuestion
                    onClick={() => setShowInstructions(!showInstructions)}
                    color={!showInstructions ? stheme.color.greys.g500 : stheme.color.brands.b800}
                />
                <SelectButton name={'My meals'} selected={selected} setSelected={setSelected} height={Hp(24)}/>
            </SDiv>
            <SDiv height={pad}/>
            {selected === 'Text' &&
            <SDiv>
                {showInstructions && <InstructionsBox width={width}/>}
                <STextArea
                    width={width} height={textAreaH}
                    font={'b2s'} padding={'12px 12px'}
                    borderColor={'g100'} borderRadius={10}
                    value={value} onChange={(e) => onValue(e.target.value)}
                />
                <SDiv height={pad}/>
                <SVerticalScroll
                    // todo why is the 3*pad needed to align?
                    width={Wp(0.9)} height={vertScrollH-3*pad}
                    //boxShadow={'normal'} borderRadius={10}
                >
                    {
                        composition.tmpSubs.map((x, index) =>
                            <ConCompView
                                concomp={x} key={index}
                                width={width} height={editHeight}
                                openEdit={() => {}}
                                nameOff={true} volumeOff={true} addOff={true}
                            />
                        )
                    }
                </SVerticalScroll>
            </SDiv>
            }
            {selected === 'My meals' &&
            <SDiv height={textAreaH + pad + vertScrollH} width={width}>
                {'Search of my meals coming soon...'}

            </SDiv>
            }
            {/*<SDiv height={Hp(10)}/>*/}
            <CancelSaveButton
                width={width}
                height={cancelSaveH}
                saveName={'Add'}
                onCancel={onClose} onSave={save}
                position={'fixed'} bottom={Hp(0.02)}
                disabled={composition.tmpSubs.length === 0}
            />
        </SModal>
    )
}